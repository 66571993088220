// 3rd party
import * as React from "react";
import * as reactLeaflet from "react-leaflet";
import * as leaflet from "leaflet";

// Domain
import { Scissor } from "@src/domain/scissor";
import { Boat } from "@src/domain/boat";
import { PrincessYacht } from "@src/domain/princess-yacht";

// Assets
import * as scissorLiftMarkerIcon from "./assets/scissor-lift-marker.svg";
import * as boatMarkerIcon from "./assets/boat-marker.svg";
import * as princessYachtMarkerIcon from "./assets/princess-yacht-marker.svg";

export { Marker };

type Device = Scissor | Boat | PrincessYacht;

function getSvg(options: IconOptions): string {
	// getting the proper <g> svgGroup from the code of the SVG
	const parser = new DOMParser();
	const svgDocument = parser.parseFromString(options.svgString, "text/xml");
	const svgElement = svgDocument.getElementsByTagName("svg")[0];
	const type = options.isAlert ? "alarm" : "normal";
	const markerElement = svgElement.querySelectorAll(`#${type}`)[0];
	const serializer = new XMLSerializer();
	const svgGroup = serializer.serializeToString(markerElement);

	// enabling/disabling the marker
	const saturation = options.isDisabled ? 0.2 : 1;
	const saturationFilter = `<filter id="disable"><feColorMatrix type="saturate" values="${saturation}" /></filter>`;
	const opacity = options.isDisabled ? 0.9 : 1;
	const animation = options.isAlert
		? `<animate xlink:href="#positive" attributeName="visibility"
		values="hidden;visible" dur="1.5s" repeatCount="indefinite" />`
		: "";

	// getting the viewBox of the original svg to preserve it
	const viewBox = svgElement.getAttribute("viewBox");

	return `<?xml version="1.0" standalone="no"?>
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
			viewBox="${viewBox}"
			opacity="${opacity}"
			filter="url(#disable)"
			width="${options.size[0]}"
			height="${options.size[1]}">
			${animation}
			${saturationFilter}
			${svgGroup}
		</svg>`;
}

function getIcon(options: IconOptions): leaflet.Icon {
	const svg = getSvg(options);
	const url = `data:image/svg+xml;base64, ${btoa(svg)}`;
	const iconOptions: leaflet.IconOptions = {
		iconUrl: url,
		iconSize: options.size,
		iconAnchor: options.iconAnchor,
		popupAnchor: options.popupAnchor,
	};
	return new leaflet.Icon(iconOptions);
}

type IconOptions = {
	svgString: string;
	isAlert: boolean;
	isDisabled: boolean;
	size: [32, 32];
	iconAnchor: [16, 32];
	popupAnchor: [0, -4];
};

interface HandlerProps {
	onClicked: () => void;
}

interface StateProps {
	device: Device;
	isAlert: boolean;
	isDisabled: boolean;
	popupShouldOpen: boolean;
	children: React.ReactNode;
}

type Props = HandlerProps & StateProps;

type BaseViewProps = Props & { iconSvg: string };

function BaseView({
	device,
	iconSvg,
	isAlert,
	isDisabled,
	popupShouldOpen,
	children,
	onClicked,
}: BaseViewProps): JSX.Element {
	const iconOptions: IconOptions = {
		svgString: iconSvg,
		isAlert: isAlert,
		isDisabled: isDisabled,
		size: [32, 32],
		iconAnchor: [16, 32],
		popupAnchor: [0, -4],
	};

	// Changing the "key" property forces the marker to re-render (opened
	// or closed) when we select a device from the search box
	const key: string = popupShouldOpen ? `${device.id}-POPUP` : device.id;

	// We want to show the selected marker over the popup so we add
	// the marker to the popupPane when the popup should be opened
	const pane = popupShouldOpen ? "popupPane" : "markerPane";

	function isLeafletMouseEvent(
		event: leaflet.LeafletEvent
	): event is leaflet.LeafletMouseEvent {
		const leafletMouseEventCast = event as leaflet.LeafletMouseEvent;
		return (
			leafletMouseEventCast.latlng !== undefined &&
			leafletMouseEventCast.latlng instanceof leaflet.LatLng
		);
	}

	function isLeafletLayer(unknown: unknown): unknown is leaflet.Layer {
		return unknown instanceof leaflet.Layer;
	}

	const map = reactLeaflet.useMapEvents({});

	return (
		<reactLeaflet.Marker
			key={key}
			icon={getIcon(iconOptions)}
			zIndexOffset={isAlert ? 9999 : 1000}
			position={device.CurrentPosition.value}
			pane={pane}
			eventHandlers={{
				add: (e): void => {
					if (popupShouldOpen && isLeafletLayer(e.target)) {
						e.target.openPopup();
					}
				},
				click: (e): void => {
					if (isLeafletMouseEvent(e)) {
						map.flyTo(e.latlng, 10);
						onClicked();
					}
				},
			}}
		>
			{children}
		</reactLeaflet.Marker>
	);
}

type MarkerProps = Props & { device: Device };

function Marker(props: MarkerProps): JSX.Element {
	switch (props.device.kind) {
		case "Scissor": {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			return <BaseView {...props} iconSvg={scissorLiftMarkerIcon} />;
		}
		case "Boat": {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			return <BaseView {...props} iconSvg={boatMarkerIcon} />;
		}
		case "PrincessYacht": {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			return <BaseView {...props} iconSvg={princessYachtMarkerIcon} />;
		}
	}
}
