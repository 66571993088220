import { fontFamily } from "@src/presentation/styles/typography";
import { custom } from "@src/presentation/styles/palette";

export {
	navigationButton,
	timeFilterWrapper,
	calendarForm,
	calendarInput,
	calendarIcon,
	calendarsWrapper,
	filterPopoverInner,
	filterPopoverInnerMobile,
	calendarButton,
	popover,
};

const calendarIconWidth = 26;

const navigationButton: React.CSSProperties = {
	backgroundColor: "transparent",
	color: "white",
	fontSize: 13,
};

const timeFilterWrapper: React.CSSProperties = {
	margin: "auto",
};

const calendarForm: React.CSSProperties = {
	marginLeft: 10,
	marginBottom: 20,
	display: "inline-block",
	flexBasis: "45%",
	minWidth: "245px",
};

const calendarInput: React.CSSProperties = {
	width: `calc(100% - ${calendarIconWidth + 15})`,
	minWidth: 40,
};

const calendarIcon: React.CSSProperties = {
	width: calendarIconWidth,
};

const calendarsWrapper: React.CSSProperties = {
	display: "flex",
	flexWrap: "wrap",
};

// Time filter popover
const filterPopoverInner: React.CSSProperties = {
	maxWidth: 620,
	width: "50vw",
	padding: 10,
	flexFlow: "row wrap",
};

const filterPopoverInnerMobile: React.CSSProperties = {
	...filterPopoverInner,
	maxWidth: "80%",
	width: "80vw",
};

const calendarButton: React.CSSProperties = {
	minWidth: calendarIconWidth,
	marginLeft: 5,
	backgroundColor: "transparent",
	color: custom.darkBlue,
};

const popover: React.CSSProperties = {
	fontFamily,
};
