// 3rd party
import * as React from "react";
import * as reduxLoop from "redux-loop";
import { Maybe } from "tsmonad";
import * as moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import {
	IconButton,
	AppBar,
	List,
	ListItem,
	CircularProgress,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";
import {
	FullscreenExit as FullscreenExitIcon,
	Fullscreen as FullscreenIcon,
	Close as CloseIcon,
	Autorenew as AutorenewIcon,
	DirectionsBoat as DirectionsBoatIcon,
} from "@material-ui/icons";

// Domain
import * as configDomain from "@src/domain/config";
import * as alarmDomain from "@src/domain/alarm";
import { AlarmSensor as ScissorAlarmSensor } from "@src/domain/scissor";
import { AlarmSensor as BoatAlarmSensor } from "@src/domain/boat";
import { AlarmSensor as PrincessYachtAlarmSensor } from "@src/domain/princess-yacht";

// Persistence
import * as alarmPersistence from "@src/persistence/alarm";
import { SessionToken } from "@src/persistence/session";

// Toolkits
import * as architecture from "@src/presentation/toolkit/architecture";

// Components
import * as timeFilter from "./components/time-filter";

// Styles
import * as styles from "./styles";
import { custom } from "@src/presentation/styles/palette";

import * as scissorIcon from "./assets/scissor-icon.svg";
import * as princessYachtIcon from "./assets/princess-yacht-icon.svg";

export { Model, init, Action, reducer, View };

// MODEL

type Alarms = Array<alarmDomain.Alarm>;

interface Model {
	currentTimeFilter: timeFilter.TimeFilter;
	nextTimeFilter: Maybe<timeFilter.TimeFilter>;
	pagination: {
		pageNumber: number;
		perPage: number;
		totalPages: number;
		totalAlarms: number;
	};
	popoverTarget: Maybe<Element>;
	calendarFromOpened: boolean;
	calendarToOpened: boolean;
	alarms: LoadingState;
}

type LoadingState =
	| {
			loadingState: "uninitialized";
	  }
	| {
			loadingState: "pending";
	  }
	| {
			loadingState: "success";
			data: Alarms;
	  }
	| {
			loadingState: "failed";
			errorMsg: string;
	  };

const init: Model = {
	alarms: {
		loadingState: "uninitialized",
	},
	currentTimeFilter: {
		from: "now-7d",
		to: "now",
	},
	nextTimeFilter: Maybe.nothing<timeFilter.TimeFilter>(),
	pagination: {
		pageNumber: 1,
		perPage: 25,
		totalPages: 0,
		totalAlarms: 0,
	},
	popoverTarget: Maybe.nothing<Element>(),
	calendarFromOpened: false,
	calendarToOpened: false,
};

// UPDATE

type Action =
	| {
			type: "INITIALIZE";
			config: configDomain.Config;
			session: SessionToken;
	  }
	| {
			type: "LOAD ALARMS";
			status: "START";
			config: configDomain.Config;
			session: SessionToken;
	  }
	| {
			type: "LOAD ALARMS";
			status: "SUCCESS";
			alarms: alarmPersistence.AlarmsList;
	  }
	| {
			type: "LOAD ALARMS";
			status: "FAILURE";
			errorMsg: string;
	  }
	| {
			type: "FINISH";
			alarms: alarmPersistence.AlarmsList;
	  }
	| {
			type: "OPEN FILTERS";
			popoverTarget: Element;
	  }
	| {
			type: "CLOSE FILTERS";
	  }
	| {
			type: "UPDATE NEXT TIME FILTER FIELD";
			timeFilter: timeFilter.TimeFilter;
	  }
	| {
			type: "APPLY NEXT TIME FILTER IF VALID";
			config: configDomain.Config;
			session: SessionToken;
	  }
	| {
			type: "UPDATE CURRENT TIME FILTER AND APPLY IT";
			timeFilter: timeFilter.TimeFilter;
			config: configDomain.Config;
			session: SessionToken;
	  }
	| {
			type: "TOGGLE CALENDAR";
			field: "FROM" | "TO";
	  }
	| {
			type: "CHANGE PAGE";
			pageNumber: number;
			config: configDomain.Config;
			session: SessionToken;
	  };

function reducer(state: Model, action: Action): [Model, reduxLoop.CmdType] {
	switch (action.type) {
		case "INITIALIZE": {
			const newState: Model = {
				...state,
				alarms: {
					loadingState: "pending",
				},
			};
			return [
				newState,
				reduxLoop.Cmd.action<Action>({
					type: "LOAD ALARMS",
					status: "START",
					config: action.config,
					session: action.session,
				}),
			];
		}
		case "FINISH": {
			const newState: Model = {
				...state,
				alarms: {
					loadingState: "success",
					data: action.alarms.data,
				},
				pagination: {
					...state.pagination,
					pageNumber: action.alarms.meta.pageNumber,
					totalPages: action.alarms.meta.totalPages,
					totalAlarms: action.alarms.meta.totalAlarms,
				},
			};
			return [newState, reduxLoop.Cmd.none];
		}
		case "LOAD ALARMS": {
			switch (action.status) {
				case "START": {
					const actionIfCmdSucceeds = (
						alarms: alarmPersistence.AlarmsList
					): Action => ({
						type: "LOAD ALARMS",
						status: "SUCCESS",
						alarms,
					});

					const actionIfCmdFails = (err: string): Action => ({
						type: "LOAD ALARMS",
						status: "FAILURE",
						errorMsg: err,
					});
					const from = state.currentTimeFilter.from;
					const to = state.currentTimeFilter.to;
					const timeFilterToApply: alarmPersistence.TimeFilter = {
						from: dateStringToDate(from)
							.map<Date | string>((date: Date): Date | string => date)
							.valueOr(from),
						to: dateStringToDate(to)
							.map<Date | string>((date: Date): Date | string => date)
							.valueOr(to),
					};
					const persistenceConfig: alarmPersistence.Config = {
						appConfig: action.config,
						timeFilter: timeFilterToApply,
						pagination: {
							pageNumber: state.pagination.pageNumber,
							perPage: state.pagination.perPage,
						},
					};
					const startGetAlarmsCmd = reduxLoop.Cmd.run(
						alarmPersistence.getAlarms,
						{
							successActionCreator: actionIfCmdSucceeds,
							failActionCreator: actionIfCmdFails,
							args: [persistenceConfig, action.session],
						}
					);
					const newState: Model = {
						...state,
						alarms: {
							loadingState: "pending",
						},
					};
					return [newState, startGetAlarmsCmd];
				}
				case "SUCCESS": {
					const newAction = reduxLoop.Cmd.action<Action>({
						type: "FINISH",
						alarms: action.alarms,
					});
					return [state, newAction];
				}
				case "FAILURE": {
					const newState: Model = {
						...state,
						alarms: {
							loadingState: "failed",
							errorMsg: action.errorMsg,
						},
					};
					return [newState, reduxLoop.Cmd.none];
				}
				default: {
					return architecture.assertExhaustiveSwitch(action, [
						state,
						reduxLoop.Cmd.none,
					]);
				}
			}
		}
		case "OPEN FILTERS": {
			return [
				{
					...state,
					popoverTarget: Maybe.just<Element>(action.popoverTarget),
				},
				reduxLoop.Cmd.none,
			];
		}
		case "CLOSE FILTERS": {
			return [
				{
					...state,
					popoverTarget: Maybe.nothing<Element>(),
				},
				reduxLoop.Cmd.none,
			];
		}
		case "UPDATE NEXT TIME FILTER FIELD": {
			// receives a value for a filter that may be valid or not
			const newState = {
				...state,
				nextTimeFilter: Maybe.just(action.timeFilter),
			};
			return [newState, reduxLoop.Cmd.none];
		}
		case "APPLY NEXT TIME FILTER IF VALID": {
			// returns just a new action (returns same state)
			return state.nextTimeFilter.caseOf({
				just: (
					nextTimeFilter: timeFilter.TimeFilter
				): [Model, reduxLoop.CmdType] => {
					const isNextTimeFilterValid =
						isValidFilterValue(nextTimeFilter.from) &&
						isValidFilterValue(nextTimeFilter.to);

					const nextAction = isNextTimeFilterValid
						? reduxLoop.Cmd.action<Action>({
								type: "UPDATE CURRENT TIME FILTER AND APPLY IT",
								timeFilter: {
									from: nextTimeFilter.from,
									to: nextTimeFilter.to,
								},
								config: action.config,
								session: action.session,
						  })
						: reduxLoop.Cmd.none;

					return [state, nextAction];
				},
				nothing: (): [Model, reduxLoop.CmdType] => [state, reduxLoop.Cmd.none],
			});
		}
		case "UPDATE CURRENT TIME FILTER AND APPLY IT": {
			// always receives a valid TimeFilter
			const newState = {
				...state,
				currentTimeFilter: action.timeFilter,
				nextTimeFilter: Maybe.nothing<timeFilter.TimeFilter>(),
				popoverTarget: Maybe.nothing<Element>(), // close popover
			};
			const newAction = reduxLoop.Cmd.action<Action>({
				type: "LOAD ALARMS",
				status: "START",
				config: action.config,
				session: action.session,
			});
			return [newState, newAction];
		}
		case "TOGGLE CALENDAR": {
			const newState =
				action.field === "FROM"
					? {
							...state,
							calendarFromOpened: !state.calendarFromOpened,
					  }
					: {
							...state,
							calendarToOpened: !state.calendarToOpened,
					  };

			return [newState, reduxLoop.Cmd.none];
		}
		case "CHANGE PAGE": {
			const newState = {
				...state,
				pagination: {
					...state.pagination,
					pageNumber: action.pageNumber,
				},
			};
			const newAction = reduxLoop.Cmd.action<Action>({
				type: "LOAD ALARMS",
				status: "START",
				config: action.config,
				session: action.session,
			});
			return [newState, newAction];
		}
		default: {
			return architecture.assertExhaustiveSwitch(action, [
				state,
				reduxLoop.Cmd.none,
			]);
		}
	}
}

// HANDLERS

function onRefreshClicked(
	dispatch: architecture.Dispatch<Action>,
	config: configDomain.Config,
	session: SessionToken
): void {
	dispatch({
		type: "LOAD ALARMS",
		status: "START",
		config: config,
		session: session,
	});
}

function onFiltersOpen(
	dispatch: architecture.Dispatch<Action>,
	event: React.MouseEvent<HTMLElement>
): void {
	event.preventDefault();

	dispatch({
		type: "OPEN FILTERS",
		popoverTarget: event.currentTarget,
	});
}

function onFiltersClose(dispatch: architecture.Dispatch<Action>): void {
	dispatch({
		type: "CLOSE FILTERS",
	});
}

function onNewTimeFilter(
	dispatch: architecture.Dispatch<Action>,
	value: timeFilter.TimeFilter
): void {
	dispatch({
		type: "UPDATE NEXT TIME FILTER FIELD",
		timeFilter: value,
	});
}

function onToggleCalendarButtonClick(
	dispatch: architecture.Dispatch<Action>,
	field: "FROM" | "TO"
): void {
	dispatch({
		type: "TOGGLE CALENDAR",
		field: field,
	});
}

function onQuickRangeClick(
	dispatch: architecture.Dispatch<Action>,
	config: configDomain.Config,
	session: SessionToken,
	range: QuickRange
): void {
	dispatch({
		type: "UPDATE CURRENT TIME FILTER AND APPLY IT",
		timeFilter: {
			from: range.from,
			to: range.to,
		},
		config: config,
		session: session,
	});
}

function onApplyButtonClick(
	dispatch: architecture.Dispatch<Action>,
	config: configDomain.Config,
	session: SessionToken
): void {
	dispatch({
		type: "APPLY NEXT TIME FILTER IF VALID",
		config: config,
		session: session,
	});
}

function onChangePage(
	dispatch: architecture.Dispatch<Action>,
	config: configDomain.Config,
	session: SessionToken,
	newPage: number
): void {
	dispatch({
		type: "CHANGE PAGE",
		pageNumber: newPage,
		config: config,
		session: session,
	});
}

// HELPERS

function getRangeText(
	rangesList: Array<QuickRange>,
	currentTimeFilter: timeFilter.TimeFilter
): string {
	const filterResult: QuickRange | undefined = rangesList.find(
		(range: QuickRange) =>
			range.from === currentTimeFilter.from && range.to === currentTimeFilter.to
	);
	if (filterResult) {
		return filterResult.display;
	}

	const from: string = dateStringToDate(currentTimeFilter.from)
		.map(dateToHumanDateString)
		.valueOr(currentTimeFilter.from);
	const to: string = dateStringToDate(currentTimeFilter.to)
		.map(dateToHumanDateString)
		.valueOr(currentTimeFilter.to);
	return `From ${from} to ${to}`;
}

const humanDateFormat = "MMMM Do YYYY, HH:mm";
const rawDateFormat = "YYYY-MM-DD HH:mm";

function dateToRawDateString(date: Date): string {
	return moment(date).format(rawDateFormat);
}

function dateToHumanDateString(date: Date): string {
	return moment(date).format(humanDateFormat);
}

function isValidDateString(value: string): boolean {
	return moment(value, rawDateFormat, true).isValid();
}

function isValidFilterValue(value: string): boolean {
	return isValidDateString(value) || alarmPersistence.isValidDateMath(value);
}

function dateStringToDate(value: string): Maybe<Date> {
	const date = moment(value, rawDateFormat, true);
	return date.isValid() ? Maybe.just(date.toDate()) : Maybe.nothing();
}

function getDuration(startTime: Date, endTime: Date): string {
	/* Reference: https://stackoverflow.com/questions/18623783/get-the-time-difference-between-two-datetimes?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
	 */
	const milliseconds = moment(endTime).diff(startTime);
	const duration = moment.duration(milliseconds);
	const durationAsHours: number = Math.floor(duration.asHours());
	const restInMinutes: number = duration.minutes();
	const hours: string =
		durationAsHours > 1
			? `${durationAsHours} hours and `
			: durationAsHours > 0
			? `${durationAsHours} hour and `
			: "";
	const minutes: string =
		restInMinutes === 1
			? `${restInMinutes} minute`
			: `${restInMinutes} minutes`;
	// if less than 1 minute show the seconds
	const minutesOrSeconds =
		milliseconds >= 60000
			? minutes
			: `${moment.duration(milliseconds).seconds()} seconds`;
	return `${hours}${minutesOrSeconds}`;
}

interface QuickRange {
	from: string;
	to: string;
	display: string;
	section: number;
}

const quickRanges: Array<QuickRange> = [
	{ from: "now/d", to: "now/d", display: "Today", section: 0 },
	{ from: "now/w", to: "now/w", display: "This week", section: 0 },
	{ from: "now/M", to: "now/M", display: "This month", section: 0 },
	{ from: "now/y", to: "now/y", display: "This year", section: 0 },
	{ from: "now/d", to: "now", display: "Today so far", section: 0 },
	{ from: "now/w", to: "now", display: "Week to date", section: 0 },
	{ from: "now/M", to: "now", display: "Month to date", section: 0 },
	{ from: "now/y", to: "now", display: "Year to date", section: 0 },
	{ from: "now-15m", to: "now", display: "Last 15 minutes", section: 1 },
	{ from: "now-30m", to: "now", display: "Last 30 minutes", section: 1 },
	{ from: "now-1h", to: "now", display: "Last 1 hour", section: 1 },
	{ from: "now-4h", to: "now", display: "Last 4 hours", section: 1 },
	{ from: "now-12h", to: "now", display: "Last 12 hours", section: 1 },
	{ from: "now-24h", to: "now", display: "Last 24 hours", section: 1 },
	{ from: "now-7d", to: "now", display: "Last 7 days", section: 1 },
	{ from: "now-30d", to: "now", display: "Last 30 days", section: 2 },
	{ from: "now-60d", to: "now", display: "Last 60 days", section: 2 },
	{ from: "now-90d", to: "now", display: "Last 90 days", section: 2 },
	{ from: "now-6M", to: "now", display: "Last 6 months", section: 2 },
	{ from: "now-1y", to: "now", display: "Last 1 year", section: 2 },
	{ from: "now-2y", to: "now", display: "Last 2 years", section: 2 },
	{ from: "now-5y", to: "now", display: "Last 5 years", section: 2 },
];

// VIEW

interface StateProps {
	state: Model;
	maximized: boolean;
	appConfig: configDomain.Config;
	session: SessionToken;
	enableScreenModeSwitch: boolean;
}

interface HandlerProps {
	dispatch: architecture.Dispatch<Action>;
	onCloseClicked: () => void;
	onMaximizeClicked: () => void;
	onNormalScreenClicked: () => void;
}

type ViewProps = StateProps & HandlerProps;

function View({
	dispatch,
	state,
	maximized,
	appConfig,
	session,
	onCloseClicked,
	onMaximizeClicked,
	onNormalScreenClicked,
	enableScreenModeSwitch,
}: ViewProps): JSX.Element {
	const screenModeButton: JSX.Element = enableScreenModeSwitch ? (
		<div></div>
	) : maximized ? (
		<IconButton onClick={onNormalScreenClicked}>
			<FullscreenExitIcon style={{ fill: custom.lightGray }} />
		</IconButton>
	) : (
		<IconButton onClick={onMaximizeClicked}>
			<FullscreenIcon style={{ fill: custom.lightGray }} />
		</IconButton>
	);

	const timeFilterBeingEdited = state.nextTimeFilter.valueOr(
		state.currentTimeFilter
	);
	const timeFilterWidget = (
		<timeFilter.View
			timeFilter={timeFilterBeingEdited}
			fromErrorText={
				!isValidFilterValue(timeFilterBeingEdited.from)
					? "This is not a valid date or expression"
					: undefined
			}
			toErrorText={
				!isValidFilterValue(timeFilterBeingEdited.to)
					? "This is not a valid date or expression"
					: undefined
			}
			toggleButtonText={getRangeText(quickRanges, state.currentTimeFilter)}
			popoverTarget={state.popoverTarget}
			calendarFromOpened={state.calendarFromOpened}
			calendarToOpened={state.calendarToOpened}
			disableApplyButton={state.nextTimeFilter.caseOf({
				just: (nextTimeFilter: timeFilter.TimeFilter): boolean => {
					const areFieldsInvalid =
						!isValidFilterValue(timeFilterBeingEdited.from) ||
						!isValidFilterValue(timeFilterBeingEdited.to);
					const areFieldsUnchanged =
						nextTimeFilter.from === state.currentTimeFilter.from &&
						nextTimeFilter.to === state.currentTimeFilter.to;
					return areFieldsInvalid || areFieldsUnchanged;
				},
				nothing: (): boolean => true,
			})}
			rightContainerContent={
				<QuickRanges
					dispatch={dispatch}
					appConfig={appConfig}
					session={session}
					currTimeFilter={state.currentTimeFilter}
					ranges={quickRanges}
				/>
			}
			onOpen={(event: React.MouseEvent<HTMLElement>): void => {
				onFiltersOpen(dispatch, event);
			}}
			onClose={(): void => {
				onFiltersClose(dispatch);
			}}
			onNewTimeFilter={(newTimeFilter: timeFilter.TimeFilter): void => {
				onNewTimeFilter(dispatch, newTimeFilter);
			}}
			onFromCalendarToggleClick={(): void => {
				onToggleCalendarButtonClick(dispatch, "FROM");
			}}
			onToCalendarToggleClick={(): void => {
				onToggleCalendarButtonClick(dispatch, "TO");
			}}
			onApplyButtonClick={(): void => {
				onApplyButtonClick(dispatch, appConfig, session);
			}}
			stringToDate={dateStringToDate}
			dateToString={dateToRawDateString}
		/>
	);

	const navLeftButtons: JSX.Element = (
		<div style={styles.navigationButtonsWrapper}>
			<IconButton
				onClick={(): void => {
					onRefreshClicked(dispatch, appConfig, session);
				}}
			>
				<AutorenewIcon style={{ fill: custom.lightGray }} />
			</IconButton>
			{timeFilterWidget}
		</div>
	);

	const navRightButtons: JSX.Element = (
		<div>
			{screenModeButton}
			<IconButton onClick={onCloseClicked}>
				<CloseIcon style={{ fill: custom.lightGray }} />
			</IconButton>
		</div>
	);

	const navBar: JSX.Element = (
		<div id="alarm-panel-view__nav-bar">
			<AppBar style={styles.appBar}>
				{navLeftButtons}
				{navRightButtons}
			</AppBar>
		</div>
	);

	switch (state.alarms.loadingState) {
		case "uninitialized": {
			dispatch({
				type: "INITIALIZE",
				config: appConfig,
				session: session,
			});
			return <div />;
		}
		case "pending": {
			return (
				<div id="alarm-panel-view" style={styles.container}>
					{navBar}
					{
						<div id="loading-view" style={styles.loader}>
							<CircularProgress />
						</div>
					}
				</div>
			);
		}
		case "failed": {
			return (
				<div id="alarm-panel-view" style={styles.container}>
					{navBar}
					<div style={styles.emptyList}>
						<p>There was an unexpected error: {state.alarms.errorMsg}</p>
					</div>
				</div>
			);
		}
		case "success": {
			const totalPages = state.pagination.totalPages;
			const pageNumber = state.pagination.pageNumber;

			const pagination = (
				<Pagination
					count={totalPages}
					page={pageNumber}
					onChange={(_, newPageNumber: number): void => {
						onChangePage(dispatch, appConfig, session, newPageNumber);
					}}
				/>
			);
			const paginationBar = (
				<div id="alarm-panel-view__pagination-bar" style={styles.paginationBar}>
					{pagination}
				</div>
			);

			const content: JSX.Element = (
				<div
					id="alarm-panel-view__content-wrapper"
					style={styles.contentWrapper}
				>
					{state.alarms.data.length ? (
						<div
							id="alarm-panel-view__alarm_list-wrapper"
							style={styles.content}
						>
							<List style={styles.list}>
								{state.alarms.data.map((alarm: alarmDomain.Alarm) => (
									<AlarmListItem
										alarm={alarm}
										key={`${alarm.sensor.name}-${
											alarm.deviceName
										}-${alarm.triggeredAt.getTime()}`}
									/>
								))}
							</List>
							{totalPages > 1 && paginationBar}
						</div>
					) : (
						<div style={styles.emptyList}>
							<p>
								Currently there are no historical alarm records.
								<br />
								You can try expanding the time range.
							</p>
						</div>
					)}
				</div>
			);
			return (
				<div id="alarm-panel-view" style={styles.container}>
					{navBar}
					{content}
				</div>
			);
		}
	}
}

type AlarmSensor =
	| ScissorAlarmSensor
	| BoatAlarmSensor
	| PrincessYachtAlarmSensor;

function outputAlarmValue(alarm: AlarmSensor): string {
	const unit: string = "unit" in alarm ? alarm.unit : "";
	switch (typeof alarm.value) {
		case "boolean": {
			return "detected";
		}
		case "number": {
			return `reached ${alarm.value} ${unit}`;
		}
		case "object": {
			if (alarm.value instanceof Array) {
				return `reported coordinates ${alarm.value.join(", ")} ${unit}`;
			} else {
				return "";
			}
		}
		default: {
			return "";
		}
	}
}

interface AlarmListItemProps {
	alarm: alarmDomain.Alarm;
}

function AlarmListItem({ alarm }: AlarmListItemProps): JSX.Element {
	const value = outputAlarmValue(alarm.sensor);
	const icon: JSX.Element = (function (): JSX.Element {
		switch (alarm.deviceType) {
			case "Scissor": {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				return <img src={scissorIcon} style={styles.listIcon} />;
			}
			case "Boat": {
				return <DirectionsBoatIcon style={styles.listIcon} />;
			}
			case "PrincessYacht": {
				return (
					<img
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						src={princessYachtIcon}
						style={{ ...styles.listIcon, height: 30, width: 30 }}
					/>
				);
			}
		}
	})();

	const status =
		alarm.status === "clear" ? (
			<span style={styles.clearAlarmStatus}>CLEAR</span>
		) : (
			<span style={styles.activeAlarmStatus}>ACTIVE</span>
		);

	const description = (
		<div style={styles.alarmDescription}>
			{status}
			<span>{`${alarm.sensor.label} ${value}`}</span>
			<span style={{ display: "inline-block" }}>
				&nbsp;on {alarm.deviceName}
			</span>
		</div>
	);

	const activeTime: JSX.Element = (
		<div>
			Active for{" "}
			<span style={styles.highlightedText}>
				{alarm.status === "clear"
					? getDuration(alarm.triggeredAt, alarm.clearedAt)
					: getDuration(alarm.triggeredAt, new Date())}
			</span>
		</div>
	);

	const triggeredDate = (
		<div>
			from{" "}
			<span style={styles.highlightedText}>
				{dateToHumanDateString(alarm.triggeredAt)}
			</span>
		</div>
	);

	const clearedDate: JSX.Element =
		alarm.status === "clear" ? (
			<div>
				to{" "}
				<span style={styles.highlightedText}>
					{dateToHumanDateString(alarm.clearedAt)}
				</span>
			</div>
		) : (
			<div />
		);

	const timeInfo = (
		<div style={styles.timeInfo}>
			{activeTime}
			{triggeredDate}
			{clearedDate}
		</div>
	);

	const primaryText = (
		<div style={styles.primaryText}>
			{description}
			{timeInfo}
		</div>
	);
	return (
		<ListItem style={{ cursor: "default" }} disableTouchRipple={true} button>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText>{primaryText}</ListItemText>
		</ListItem>
	);
}

interface QuickRangesProps {
	ranges: Array<QuickRange>;
	dispatch: architecture.Dispatch<Action>;
	currTimeFilter: timeFilter.TimeFilter;
	appConfig: configDomain.Config;
	session: SessionToken;
}

function QuickRanges({
	ranges,
	dispatch,
	currTimeFilter,
	appConfig,
	session,
}: QuickRangesProps): JSX.Element {
	return (
		<ul style={styles.quickRangesList}>
			{ranges.map((range: QuickRange) => (
				<li
					style={{
						...styles.quickRangesItem,
						...(isQuickRangeSelected(range, currTimeFilter) &&
							styles.selectedQuickRange),
					}}
					key={range.display}
					onClick={(): void => {
						onQuickRangeClick(dispatch, appConfig, session, range);
					}}
				>
					{range.display}
				</li>
			))}
		</ul>
	);
}

function isQuickRangeSelected(
	range: QuickRange,
	filter: timeFilter.TimeFilter
): boolean {
	return range.from === filter.from && range.to === filter.to;
}
