export {
	Boat,
	Geofence,
	Actuator,
	Sensor,
	AlarmSensor,
	SensorValue,
	updateSensor,
	isAlarmActive,
	hasActiveAlarms,
	Power,
	Lock,
	PowerStatus,
	LockStatus,
	HumanProximity,
	ParkingProximity,
	DPFDifferentialPressure,
	FuelLevel,
	OilTemp,
	OilPressure,
	EngineRotationSpeed,
	BatteryVoltage,
	CoolantLevel,
	CurrentPosition,
	Compass,
	AlarmIntruder,
	AlarmSmokeDetector,
	AlarmOilTemp,
	AlarmCoolantLevel,
	AlarmDPFDifferentialPressure,
};

interface Boat {
	kind: "Boat";
	id: string;
	name: string;
	geofence: Geofence;
	Power: Power;
	Lock: Lock;
	PowerStatus: PowerStatus;
	LockStatus: LockStatus;
	HumanProximity: HumanProximity;
	ParkingProximity: ParkingProximity;
	DPFDifferentialPressure: DPFDifferentialPressure;
	FuelLevel: FuelLevel;
	OilTemp: OilTemp;
	OilPressure: OilPressure;
	EngineRotationSpeed: EngineRotationSpeed;
	BatteryVoltage: BatteryVoltage;
	CoolantLevel: CoolantLevel;
	CurrentPosition: CurrentPosition;
	Compass: Compass;
	AlarmIntruder: AlarmIntruder;
	AlarmSmokeDetector: AlarmSmokeDetector;
	AlarmOilTemp: AlarmOilTemp;
	AlarmCoolantLevel: AlarmCoolantLevel;
	AlarmDPFDifferentialPressure: AlarmDPFDifferentialPressure;
}

type GeoPoint = [number, number];

interface Geofence {
	center: GeoPoint;
	outerRadius: number;
	innerRadius: number;
}

function updateSensor(boat: Boat, sensor: Sensor): Boat {
	switch (sensor.name) {
		case "Power Status": {
			return {
				...boat,
				PowerStatus: sensor,
			};
		}

		case "Lock Status": {
			return {
				...boat,
				LockStatus: sensor,
			};
		}

		case "Human Proximity": {
			return {
				...boat,
				HumanProximity: sensor,
			};
		}

		case "Parking Proximity": {
			return {
				...boat,
				ParkingProximity: sensor,
			};
		}

		case "DPF Differential Pressure": {
			return {
				...boat,
				DPFDifferentialPressure: sensor,
			};
		}

		case "Fuel Level": {
			return {
				...boat,
				FuelLevel: sensor,
			};
		}

		case "Oil Temp": {
			return {
				...boat,
				OilTemp: sensor,
			};
		}

		case "Oil Pressure": {
			return {
				...boat,
				OilPressure: sensor,
			};
		}

		case "Engine Rotation Speed": {
			return {
				...boat,
				EngineRotationSpeed: sensor,
			};
		}

		case "Battery Voltage": {
			return {
				...boat,
				BatteryVoltage: sensor,
			};
		}

		case "Coolant Level": {
			return {
				...boat,
				CoolantLevel: sensor,
			};
		}

		case "Current Position": {
			return {
				...boat,
				CurrentPosition: sensor,
			};
		}

		case "Compass": {
			return {
				...boat,
				Compass: sensor,
			};
		}

		case "Alarm Intruder": {
			return {
				...boat,
				AlarmIntruder: sensor,
			};
		}

		case "Alarm Smoke Detector": {
			return {
				...boat,
				AlarmSmokeDetector: sensor,
			};
		}

		case "Alarm Oil Temp": {
			return {
				...boat,
				AlarmOilTemp: sensor,
			};
		}

		case "Alarm Coolant Level": {
			return {
				...boat,
				AlarmCoolantLevel: sensor,
			};
		}

		case "Alarm DPF Differential Pressure": {
			return {
				...boat,
				AlarmDPFDifferentialPressure: sensor,
			};
		}
	}
}

function isAlarmActive(alarm: AlarmSensor): boolean {
	// beware a false value mean alarm is still active since false is not equal to null
	return alarm.value !== null;
}

function hasActiveAlarms(boat: Boat): boolean {
	const alarms: Array<AlarmSensor> = [
		boat.AlarmIntruder,
		boat.AlarmSmokeDetector,
		boat.AlarmOilTemp,
		boat.AlarmCoolantLevel,
		boat.AlarmDPFDifferentialPressure,
	];
	return alarms.some(isAlarmActive);
}

type Sensor =
	| AlarmSensor
	| PowerStatus
	| LockStatus
	| HumanProximity
	| ParkingProximity
	| DPFDifferentialPressure
	| FuelLevel
	| OilTemp
	| OilPressure
	| EngineRotationSpeed
	| BatteryVoltage
	| CoolantLevel
	| CurrentPosition
	| Compass;

type AlarmSensor =
	| AlarmIntruder
	| AlarmSmokeDetector
	| AlarmOilTemp
	| AlarmCoolantLevel
	| AlarmDPFDifferentialPressure;

type Actuator = Power | Lock;

type SensorValue = boolean | number | GeoPoint | null;

interface Power {
	name: "Power";
	value: boolean | null;
	trueLiteral: "ON";
	falseLiteral: "OFF";
}

interface Lock {
	name: "Lock";
	value: boolean | null;
	trueLiteral: "LOCKED";
	falseLiteral: "UNLOCKED";
}

interface PowerStatus {
	name: "Power Status";
	value: boolean | null;
}

interface LockStatus {
	name: "Lock Status";
	value: boolean | null;
}

interface HumanProximity {
	name: "Human Proximity";
	value: boolean | null;
	icon: "HumanProximity";
}

interface ParkingProximity {
	name: "Parking Proximity";
	value: number | null;
	unit: "M";
	icon: "ParkingProximity";
}

interface DPFDifferentialPressure {
	name: "DPF Differential Pressure";
	value: number | null;
	unit: "PSI";
	icon: "DPFDifferentialPressure";
}

interface AlarmDPFDifferentialPressure {
	name: "Alarm DPF Differential Pressure";
	label: "DPF Differential Pressure";
	value: number | null;
	unit: "PSI";
}

interface FuelLevel {
	name: "Fuel Level";
	value: number | null;
	unit: "L";
	icon: "FuelLevel";
}

interface OilTemp {
	name: "Oil Temp";
	value: number | null;
	unit: "ºC";
	icon: "OilTemp";
}

interface AlarmOilTemp {
	name: "Alarm Oil Temp";
	label: "Oil Temp";
	value: number | null;
	unit: "ºC";
}

interface OilPressure {
	name: "Oil Pressure";
	value: number | null;
	unit: "PSI";
	icon: "OilPressure";
}

interface EngineRotationSpeed {
	name: "Engine Rotation Speed";
	value: number | null;
	unit: "RPM";
	icon: "EngineRotationSpeed";
}

interface BatteryVoltage {
	name: "Battery Voltage";
	value: number | null;
	unit: "V";
	icon: "BatteryVoltage";
}

interface CoolantLevel {
	name: "Coolant Level";
	value: number | null;
	unit: "CM";
	icon: "CoolantLevel";
}

interface AlarmCoolantLevel {
	name: "Alarm Coolant Level";
	label: "Coolant Level";
	value: number | null;
	unit: "CM";
}

interface CurrentPosition {
	name: "Current Position";
	value: GeoPoint;
	icon: "CurrentPosition";
}

interface Compass {
	name: "Compass";
	value: number | null;
	unit: "º";
	icon: "Compass";
}

interface AlarmIntruder {
	name: "Alarm Intruder";
	label: "Intruder";
	value: boolean | null;
}

interface AlarmSmokeDetector {
	name: "Alarm Smoke Detector";
	label: "Smoke";
	value: boolean | null;
}
