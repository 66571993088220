// 3rd party
import * as React from "react";
import * as reactLeaflet from "react-leaflet";
import { Button } from "@material-ui/core";
import { Either } from "tsmonad";
import JsonousDecoder, * as jsonous from "jsonous";

import { fontFamily } from "@src/presentation/styles/typography";
import { custom } from "@src/presentation/styles/palette";

// Domain
import * as scissorDomain from "@src/domain/scissor";
import * as boatDomain from "@src/domain/boat";
import * as princessYachtDomain from "@src/domain/princess-yacht";

import * as iconPaths from "./assets/icon-paths.json";
import * as princessYachtPaths from "./assets/princess-yacht-icons.json";

// helpers
import * as decoding from "@src/persistence/services/decoding";

export { Scissor, Boat, PrincessYacht };

interface ScissorHandlerProps {
	onShowDetails: (device: scissorDomain.Scissor) => void;
}
interface ScissorStateProps {
	scissor: scissorDomain.Scissor;
}
type ScissorProps = ScissorHandlerProps & ScissorStateProps;

const popupWidth: number = Math.min(window.screen.width * 0.8, 350);

function Scissor({ scissor, onShowDetails }: ScissorProps): JSX.Element {
	return (
		<Popup autoPan={false} maxWidth={popupWidth} minWidth={popupWidth}>
			<div style={popupStyles.popup}>
				<h2>{scissor.name}</h2>
				{
					<ul style={popupStyles.gridList}>
						<Sensor
							key={scissor.EngineTime.name}
							sensor={scissor.EngineTime}
							type={scissor.kind}
						/>
						<Sensor
							key={scissor.OilTemp.name}
							sensor={scissor.OilTemp}
							type={scissor.kind}
						/>
						<Sensor
							key={scissor.FuelLevel.name}
							sensor={scissor.FuelLevel}
							type={scissor.kind}
						/>
						<Sensor
							key={scissor.TyrePressure.name}
							sensor={scissor.TyrePressure}
							type={scissor.kind}
						/>
					</ul>
				}
				<Button
					style={{ boxShadow: "0" }}
					onClick={(): void => {
						onShowDetails(scissor);
					}}
					variant="contained"
				>
					More Details
				</Button>
			</div>
		</Popup>
	);
}

interface BoatHandlerProps {
	onShowDetails: (device: boatDomain.Boat) => void;
}

interface BoatStateProps {
	boat: boatDomain.Boat;
}

type BoatProps = BoatHandlerProps & BoatStateProps;

function Boat({ boat, onShowDetails }: BoatProps): JSX.Element {
	return (
		<Popup autoPan={false} maxWidth={popupWidth} minWidth={popupWidth}>
			<div style={popupStyles.popup}>
				<h2>{boat.name}</h2>
				{
					<ul style={popupStyles.gridList}>
						<Sensor
							key={boat.EngineRotationSpeed.name}
							sensor={boat.EngineRotationSpeed}
							type={boat.kind}
						/>
						<Sensor
							key={boat.OilTemp.name}
							sensor={boat.OilTemp}
							type={boat.kind}
						/>
						<Sensor
							key={boat.OilPressure.name}
							sensor={boat.OilPressure}
							type={boat.kind}
						/>
						<Sensor
							key={boat.FuelLevel.name}
							sensor={boat.FuelLevel}
							type={boat.kind}
						/>
					</ul>
				}
				<Button
					style={{ boxShadow: "0" }}
					onClick={(): void => {
						onShowDetails(boat);
					}}
					variant="contained"
				>
					More Details
				</Button>
			</div>
		</Popup>
	);
}

interface PrincessYachtHandlerProps {
	onShowDetails: (device: princessYachtDomain.PrincessYacht) => void;
}
interface PrincessYachtStateProps {
	yacht: princessYachtDomain.PrincessYacht;
}
type PrincessYachtProps = PrincessYachtStateProps & PrincessYachtHandlerProps;

function PrincessYacht({
	yacht,
	onShowDetails,
}: PrincessYachtProps): JSX.Element {
	return (
		<Popup autoPan={false} maxWidth={popupWidth} minWidth={popupWidth}>
			<div style={popupStyles.popup}>
				<h2>{yacht.name}</h2>
				{
					<ul style={popupStyles.gridList}>
						<Sensor
							key={yacht.EngineRPM.name}
							sensor={yacht.EngineRPM}
							type={yacht.kind}
						/>
						<Sensor
							key={yacht.EngineRunningHours.name}
							sensor={yacht.EngineRunningHours}
							type={yacht.kind}
						/>
						<Sensor
							key={yacht.BatteriesDomestic.name}
							sensor={yacht.BatteriesDomestic}
							type={yacht.kind}
						/>
						<Sensor
							key={yacht.FuelLevel.name}
							sensor={yacht.FuelLevel}
							type={yacht.kind}
						/>
					</ul>
				}
				<Button
					style={{ boxShadow: "0" }}
					onClick={(): void => {
						onShowDetails(yacht);
					}}
					variant="contained"
				>
					More Details
				</Button>
			</div>
		</Popup>
	);
}

const popupStyles: {
	listItem: React.CSSProperties;
	value: React.CSSProperties;
	unit: React.CSSProperties;
	readerName: React.CSSProperties;
	gridList: React.CSSProperties;
	popup: React.CSSProperties;
} = {
	listItem: {
		minWidth: popupWidth / 2,
		flex: 1,
		margin: "2px 0 16px 0",
	},
	value: {
		fontSize: 20,
		alignSelf: "center",
		marginLeft: 6,
		marginBottom: -3,
		color: custom.gray,
	},
	unit: { alignSelf: "flex-end", marginBottom: 3, fontSize: 14 },
	readerName: { textTransform: "uppercase", fontSize: 11 },
	gridList: {
		listStyle: "none",
		padding: 0,
		flexFlow: "wrap",
		display: "flex",
	},
	popup: {
		fontFamily,
	},
};

const Popup = reactLeaflet.Popup;

type SensorType = "Scissor" | "Boat" | "PrincessYacht";

interface SensorProps {
	type: SensorType;
	sensor: {
		icon: string;
		value:
			| scissorDomain.SensorValue
			| boatDomain.SensorValue
			| princessYachtDomain.SensorValue;
		unit: string;
		name: string;
	};
}

function Sensor({ sensor, type }: SensorProps): JSX.Element {
	const iconPath = getPath(sensor.icon, type);
	return (
		<li style={popupStyles.listItem}>
			<div style={{ display: "flex" }}>
				<svg
					width="32"
					height="32"
					viewBox="0 0 1024 1024"
					style={{ fill: custom.gray }}
				>
					<path d={iconPath}></path>
				</svg>
				<b style={popupStyles.value}>{sensor.value}</b>
				&nbsp;
				<small style={popupStyles.unit}>{sensor.unit}</small>
			</div>
			<div style={popupStyles.readerName}>{sensor.name}</div>
		</li>
	);
}

interface Icon {
	properties: {
		name: string;
	};
	icon: {
		paths: Array<string>;
	};
}

interface Icons {
	icons: Icon[];
}

const iconDecoder: JsonousDecoder<Icon> = jsonous
	.at(["properties", "name"], jsonous.string)
	.andThen((name) =>
		jsonous
			.at(["icon", "paths"], jsonous.array(jsonous.string))
			.andThen((paths) =>
				jsonous.succeed({ properties: { name }, icon: { paths } })
			)
	);

const iconsDecoder: JsonousDecoder<Icons> = jsonous.field(
	"icons",
	jsonous.array(iconDecoder).andThen((icons) => jsonous.succeed({ icons }))
);

function decodeIcons(icons: unknown): Either<string, Icons> {
	return decoding.applyJsonousDecoder(
		iconsDecoder,
		{
			failure: (error: string): string => `Cannot decode icons: ${error}`,
			success: (result: Icons): Icons => result,
		},
		icons
	);
}

function getPath(iconName: string, sensorType: SensorType): string {
	switch (sensorType) {
		case "Boat":
		case "Scissor": {
			return decodeIcons(iconPaths).caseOf({
				left: () => "",
				right: (icons) => {
					const icon = icons.icons.find(
						(possibleIcon: Icon) => possibleIcon.properties.name === iconName
					);
					return icon ? icon.icon.paths.join(" ") : "";
				},
			});
		}
		case "PrincessYacht": {
			return decodeIcons(princessYachtPaths).caseOf({
				left: () => "",
				right: (icons) => {
					const icon = icons.icons.find(
						(possibleIcon: Icon) => possibleIcon.properties.name === iconName
					);
					return icon ? icon.icon.paths.join(" ") : "";
				},
			});
		}
	}
}
