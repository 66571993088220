export {
	PrincessYacht,
	SensorValue,
	Sensor,
	AlarmSensor,
	Actuator,
	Power,
	Lock,
	PowerStatus,
	LockStatus,
	CurrentPosition,
	BatteriesDomestic,
	Shoreline1LowHighVoltage,
	Generator1LowHighVoltage,
	FreshWaterLevel,
	GreyWaterLevel,
	BlackWaterLevel,
	FuelLevel,
	OilPressure,
	EngineRPM,
	EngineRunningHours,
	AlarmExhaustHighTemp,
	isAlarmActive,
	hasActiveAlarms,
	updateSensor,
};

type GeoPoint = [number, number];

interface Geofence {
	center: GeoPoint;
	outerRadius: number;
	innerRadius: number;
}
function updateSensor(yacht: PrincessYacht, sensor: Sensor): PrincessYacht {
	switch (sensor.name) {
		case "Power Status": {
			return {
				...yacht,
				PowerStatus: sensor,
			};
		}

		case "Lock Status": {
			return {
				...yacht,
				LockStatus: sensor,
			};
		}
		case "Generator 1 Volt": {
			return {
				...yacht,
				Generator1LowHighVoltage: sensor,
			};
		}
		case "Shoreline 1 Volt": {
			return {
				...yacht,
				Shoreline1LowHighVoltage: sensor,
			};
		}

		case "Fuel Level": {
			return {
				...yacht,
				FuelLevel: sensor,
			};
		}

		case "Batteries Domestic": {
			return {
				...yacht,
				BatteriesDomestic: sensor,
			};
		}

		case "Fresh Water Level": {
			return {
				...yacht,
				FreshWaterLevel: sensor,
			};
		}

		case "Grey Water Level": {
			return {
				...yacht,
				GreyWaterLevel: sensor,
			};
		}
		case "Black Water Level": {
			return {
				...yacht,
				BlackWaterLevel: sensor,
			};
		}

		case "Current Position": {
			return {
				...yacht,
				CurrentPosition: sensor,
			};
		}

		case "Oil Pressure": {
			return {
				...yacht,
				OilPressure: sensor,
			};
		}
		case "Engine RPM": {
			return {
				...yacht,
				EngineRPM: sensor,
			};
		}
		case "Engine Running Hours": {
			return {
				...yacht,
				EngineRunningHours: sensor,
			};
		}

		case "Exhaust High Temp Alarm": {
			return {
				...yacht,
				AlarmExhaustHighTemp: sensor,
			};
		}
	}
}

function isAlarmActive(alarm: AlarmSensor): boolean {
	// beware a false value mean alarm is still active since false is not equal to null
	return alarm.value !== null;
}

function hasActiveAlarms(yacht: PrincessYacht): boolean {
	return isAlarmActive(yacht.AlarmExhaustHighTemp);
}

type Sensor =
	| LockStatus
	| PowerStatus
	| CurrentPosition
	| BatteriesDomestic
	| Shoreline1LowHighVoltage
	| Generator1LowHighVoltage
	| FreshWaterLevel
	| GreyWaterLevel
	| BlackWaterLevel
	| FuelLevel
	| EngineRPM
	| OilPressure
	| EngineRunningHours
	| AlarmSensor;

type AlarmSensor = AlarmExhaustHighTemp;

type Actuator = Lock | Power;

type SensorValue = boolean | number | GeoPoint | null;

interface PrincessYacht {
	kind: "PrincessYacht";
	id: string;
	name: string;
	geofence: Geofence;
	CurrentPosition: CurrentPosition;
	Power: Power;
	Lock: Lock;
	PowerStatus: PowerStatus;
	LockStatus: LockStatus;
	BatteriesDomestic: BatteriesDomestic;
	Shoreline1LowHighVoltage: Shoreline1LowHighVoltage;
	Generator1LowHighVoltage: Generator1LowHighVoltage;
	FreshWaterLevel: FreshWaterLevel;
	GreyWaterLevel: GreyWaterLevel;
	BlackWaterLevel: BlackWaterLevel;
	FuelLevel: FuelLevel;
	OilPressure: OilPressure;
	EngineRunningHours: EngineRunningHours;
	EngineRPM: EngineRPM;
	AlarmExhaustHighTemp: AlarmExhaustHighTemp;
}

interface CurrentPosition {
	name: "Current Position";
	value: GeoPoint;
	icon: "CurrentPosition";
}

interface Power {
	name: "Power";
	value: boolean | null;
	trueLiteral: "ON";
	falseLiteral: "OFF";
}

interface Lock {
	name: "Lock";
	value: boolean | null;
	trueLiteral: "LOCKED";
	falseLiteral: "UNLOCKED";
}

interface PowerStatus {
	name: "Power Status";
	value: boolean | null;
}

interface LockStatus {
	name: "Lock Status";
	value: boolean | null;
}

interface BatteriesDomestic {
	name: "Batteries Domestic";
	value: number | null;
	unit: "V";
	icon: "BatteriesDomestic";
}

interface Shoreline1LowHighVoltage {
	name: "Shoreline 1 Volt";
	value: number | null;
	unit: "V";
	icon: "ShorelineLowHighVoltage";
}

interface Generator1LowHighVoltage {
	name: "Generator 1 Volt";
	value: number | null;
	unit: "V";
	icon: "GeneratorLowHighVoltage";
}

interface FreshWaterLevel {
	name: "Fresh Water Level";
	value: number | null;
	unit: "%";
	icon: "FreshWaterLevel";
}

interface GreyWaterLevel {
	name: "Grey Water Level";
	value: number | null;
	unit: "%";
	icon: "GreyWaterLevel";
}

interface BlackWaterLevel {
	name: "Black Water Level";
	value: number | null;
	unit: "%";
	icon: "BlackWaterLevel";
}

interface FuelLevel {
	name: "Fuel Level";
	value: number | null;
	unit: "%";
	icon: "FuelLevel";
}

interface EngineRunningHours {
	name: "Engine Running Hours";
	value: number | null;
	unit: "h";
	icon: "EngineRunningHours";
}

interface EngineRPM {
	name: "Engine RPM";
	value: number | null;
	unit: "RPM";
	icon: "EngineRPM";
}

interface OilPressure {
	name: "Oil Pressure";
	value: number | null;
	unit: "PSI";
	icon: "OilPressure";
}

interface AlarmExhaustHighTemp {
	name: "Exhaust High Temp Alarm";
	label: "Exhaust High Temp";
	value: boolean | null;
	icon: "AlarmExhaustHighTemp";
}
