import { Boat, Actuator } from "../domain/boat";
import { Either } from "tsmonad";
import * as jsonous from "jsonous";

import * as miimetiqRest from "./services/miimetiq-rest";
import * as decoding from "./services/decoding";

export { getBoats, sendActuator };

function getBoats(
	url: string,
	sessionToken: miimetiqRest.SessionToken
): Promise<Array<Boat>> {
	return miimetiqRest
		.getAssets(url, sessionToken)
		.then(decodeBoats)
		.then(filterDecodeErrors);
}

function decodeBoats(
	assets: Array<miimetiqRest.AssetJSON>
): Array<Either<string, Boat>> {
	return assets.map(decodeBoat);
}

function filterDecodeErrors(
	eitherBoatsOrErrors: Array<Either<string, Boat>>
): Array<Boat> {
	return eitherBoatsOrErrors
		.filter((eitherBoatOrError: Either<string, Boat>) =>
			eitherBoatOrError.caseOf({
				right: (): boolean => true,
				left: (error: string): boolean => {
					console.log(`Ignoring Boat. Reason: ${error}`);

					return false;
				},
			})
		)
		.map((eitherBoatOrError: Either<never, Boat>): Boat => {
			return eitherBoatOrError.caseOf({
				right: (asset: Boat): Boat => asset,

				left: (error: never): never => {
					throw new Error(`UNEXPECTED ERROR: ${error}`);
				},
			});
		});
}

// prettier-ignore
function decodeBoat(asset: miimetiqRest.AssetJSON): Either<string, Boat> {
	// tslint:disable:max-line-length
	const decoder =
		jsonous.field("lon_lat", miimetiqRest.lonlatDecoder).andThen((geofence: [number, number]) =>
		miimetiqRest.decodeNullableWriter(jsonous.boolean, [ 'Boat', 'Power' ]).andThen((Power: boolean | null) =>
		miimetiqRest.decodeNullableWriter(jsonous.boolean, [ 'Boat', 'Lock' ]).andThen((Lock: boolean | null) =>
		miimetiqRest.decodeNullableReader(jsonous.boolean, [ 'Boat', 'PowerStatus' ]).andThen((PowerStatus: boolean | null) =>
		miimetiqRest.decodeNullableReader(jsonous.boolean, [ 'Boat', 'LockStatus' ]).andThen((LockStatus: boolean | null) =>
		miimetiqRest.decodeNullableReader(jsonous.boolean, [ 'Boat', 'HumanProximity' ]).andThen((HumanProximity: boolean | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'Boat', 'ParkingProximity' ]).andThen((ParkingProximity: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'Boat', 'DPFDifferentialPressure' ]).andThen((DPFDifferentialPressure: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'Boat', 'FuelLevel' ]).andThen((FuelLevel: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'Boat', 'OilTemp' ]).andThen((OilTemp: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'Boat', 'OilPressure' ]).andThen((OilPressure: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'Boat', 'EngineRotationSpeed' ]).andThen((EngineRotationSpeed: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'Boat', 'BatteryVoltage' ]).andThen((BatteryVoltage: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'Boat', 'CoolantLevel' ]).andThen((CoolantLevel: number | null) =>
		miimetiqRest.decodeNonnullableReader(miimetiqRest.lonlatDecoder, [ 'Boat', 'CurrentPosition' ]).andThen((CurrentPosition: [number, number]) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'Boat', 'Compass' ]).andThen((Compass: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.boolean, [ 'Boat', 'alarms', 'Intruder' ]).andThen((AlarmIntruder: boolean | null) =>
		miimetiqRest.decodeNullableReader(jsonous.boolean, [ 'Boat', 'alarms', 'SmokeDetector' ]).andThen((AlarmSmokeDetector: boolean | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'Boat', 'alarms', 'OilTemp' ]).andThen((AlarmOilTemp: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'Boat', 'alarms', 'CoolantLevel' ]).andThen((AlarmCoolantLevel: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'Boat', 'alarms', 'DPFDifferentialPressure' ]).andThen((AlarmDPFDifferentialPressure: number | null) =>
		jsonous.succeed<Boat>({
			kind: "Boat",
			id: asset._id,
			name: asset.name,
			geofence: {
				center: geofence,
				outerRadius: 10000,
				innerRadius: 8000
			},
			Power: {
				name: "Power",
				value: Power,
				trueLiteral: "ON",
				falseLiteral: "OFF",
			},
			Lock: {
				name: "Lock",
				value: Lock,
				trueLiteral: "LOCKED",
				falseLiteral: "UNLOCKED",
			},
			PowerStatus: {
				name: "Power Status",
				value: PowerStatus,
			},
			LockStatus: {
				name: "Lock Status",
				value: LockStatus,
			},
			HumanProximity: {
				name: "Human Proximity",
				value: HumanProximity,
				icon: "HumanProximity"
			},
			ParkingProximity: {
				name: "Parking Proximity",
				value: ParkingProximity,
				unit: "M",
				icon: "ParkingProximity",
			},
			DPFDifferentialPressure: {
				name: "DPF Differential Pressure",
				value: DPFDifferentialPressure,
				unit: "PSI",
				icon: "DPFDifferentialPressure",
			},
			FuelLevel: {
				name: "Fuel Level",
				value: FuelLevel,
				unit: "L",
				icon: "FuelLevel",
			},
			OilTemp: {
				name: "Oil Temp",
				value: OilTemp,
				unit: "ºC",
				icon: "OilTemp",
			},
			OilPressure: {
				name: "Oil Pressure",
				value: OilPressure,
				unit: "PSI",
				icon: "OilPressure",
			},
			EngineRotationSpeed: {
				name: "Engine Rotation Speed",
				value: EngineRotationSpeed,
				unit: "RPM",
				icon: "EngineRotationSpeed",
			},
			BatteryVoltage: {
				name: "Battery Voltage",
				value: BatteryVoltage,
				unit: "V",
				icon: "BatteryVoltage",
			},
			CoolantLevel: {
				name: "Coolant Level",
				value: CoolantLevel,
				unit: "CM",
				icon: "CoolantLevel",
			},
			CurrentPosition: {
				name: "Current Position",
				value: CurrentPosition,
				icon: "CurrentPosition",
			},
			Compass: {
				name: "Compass",
				value: Compass,
				unit: "º",
				icon: "Compass",
			},
			AlarmIntruder: {
				name: "Alarm Intruder",
				label: "Intruder",
				value: AlarmIntruder,
			},
			AlarmSmokeDetector: {
				name: "Alarm Smoke Detector",
				label: "Smoke",
				value: AlarmSmokeDetector
			},
			AlarmOilTemp: {
				name: "Alarm Oil Temp",
				label: "Oil Temp",
				value: AlarmOilTemp,
				unit: "ºC"
			},
			AlarmCoolantLevel: {
				name: "Alarm Coolant Level",
				label: "Coolant Level",
				value: AlarmCoolantLevel,
				unit: "CM"
			},
			AlarmDPFDifferentialPressure: {
				name: "Alarm DPF Differential Pressure",
				label: "DPF Differential Pressure",
				unit: "PSI",
				value: AlarmDPFDifferentialPressure,
			},
		}))))))))))))))))))))));
	return decoding.applyJsonousDecoder(
		decoder,
		{
			success: (boat: Boat): Boat => boat,
			failure: (error: string): string => `Cannot decode boat ${asset.name}: ${error}`
		},
		asset.raw
	);
}

function sendActuator(
	url: string,
	sessionToken: miimetiqRest.SessionToken,
	actuator: Actuator
): Promise<void> {
	return miimetiqRest
		.sendRpc(url, sessionToken, { value: actuator.value })
		.then((): Promise<void> => {
			return Promise.resolve();
		});
}
