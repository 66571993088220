import { Either } from "tsmonad";
import JsonousDecoder from "jsonous";

export { applyJsonousDecoder };

interface DecoderResultProcessor<A, B> {
	success: (result: B) => B;
	failure: (error: string) => A;
}

function applyJsonousDecoder<A, B>(
	decoder: JsonousDecoder<B>,
	resultProcessor: DecoderResultProcessor<A, B>,
	decodee: unknown
): Either<A, B> {
	return decoder.decodeAny(decodee).cata<Either<A, B>>({
		Err: (error: string): Either<A, B> =>
			Either.left<A, B>(resultProcessor.failure(error)),
		Ok: (result: B): Either<A, B> =>
			Either.right<A, B>(resultProcessor.success(result)),
	});
}
