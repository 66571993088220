// 3rd party
import * as elastic from "elasticsearch-browser";
import { Either } from "tsmonad";
import JsonousDecoder, * as jsonous from "jsonous";
import * as maybeasy from "maybeasy";
import * as resulty from "resulty";

// helpers
import * as decoding from "./services/decoding";

// persistence
import * as miimetiqRest from "@src/persistence/services/miimetiq-rest";

// domain
import * as configDomain from "@src/domain/config";
import * as alarmDomain from "@src/domain/alarm";
import * as scissorDomain from "@src/domain/scissor";
import * as boatDomain from "@src/domain/boat";
import * as princessYachtDomain from "@src/domain/princess-yacht";

export { getAlarms, TimeFilter, Config, AlarmsList, isValidDateMath };

function isValidDateMath(value: string): boolean {
	return /now((\/[yMwdhms])|([+-]\d+[yMwdhms]))?([+-]\d+[yMwdhms])?$/.test(
		value
	);
}

interface AssetIdNameMapping {
	[assetId: string]: string;
}

function mapAssetNameToId(
	assets: Array<miimetiqRest.AssetJSON>
): AssetIdNameMapping {
	return assets.reduce(
		(
			mapping: AssetIdNameMapping,
			asset: miimetiqRest.AssetJSON
		): AssetIdNameMapping => {
			return {
				...mapping,
				[asset._id]: asset.name,
			};
		},
		{}
	);
}

function getAssetNames(
	config: configDomain.Config,
	token: miimetiqRest.SessionToken
): Promise<Array<miimetiqRest.AssetJSON>> {
	const restConfig: miimetiqRest.RestConfig = {
		includeFields: ["name"],
	};
	return miimetiqRest.getAssets(
		config.DEVICE_MANAGEMENT.ALL.REST_API.DEVICE_LIST,
		token,
		restConfig
	);
}

interface ScissorAlarmJSON {
	sensor: scissorDomain.AlarmSensor;
	device_type: "ScissorLift";
	device_id: string;
	triggered_at: number;
	cleared_at: maybeasy.Maybe<number>;
}

interface BoatAlarmJSON {
	sensor: boatDomain.AlarmSensor;
	device_type: "Boat";
	device_id: string;
	triggered_at: number;
	cleared_at: maybeasy.Maybe<number>;
}

interface PrincessYachtAlarmJSON {
	sensor: princessYachtDomain.AlarmSensor;
	device_type: "PrincessYacht";
	device_id: string;
	triggered_at: number;
	cleared_at: maybeasy.Maybe<number>;
}

type AlarmJSON = ScissorAlarmJSON | BoatAlarmJSON | PrincessYachtAlarmJSON;

function alarmJSON2DomainAlarm(
	assetMap: AssetIdNameMapping,
	alarmJson: AlarmJSON
): alarmDomain.Alarm {
	const triggeredAt = new Date(alarmJson.triggered_at * 1000);
	switch (alarmJson.device_type) {
		case "ScissorLift": {
			return alarmJson.cleared_at.cata({
				Just: (value: number): alarmDomain.Alarm => ({
					status: "clear",
					sensor: alarmJson.sensor,
					deviceType: "Scissor",
					deviceName: assetMap[alarmJson.device_id],
					triggeredAt: triggeredAt,
					clearedAt: new Date(value * 1000),
				}),

				Nothing: (): alarmDomain.Alarm => ({
					status: "active",
					sensor: alarmJson.sensor,
					deviceType: "Scissor",
					deviceName: assetMap[alarmJson.device_id],
					triggeredAt: triggeredAt,
				}),
			});
		}
		case "Boat": {
			return alarmJson.cleared_at.cata({
				Just: (value: number): alarmDomain.Alarm => ({
					status: "clear",
					sensor: alarmJson.sensor,
					deviceType: "Boat",
					deviceName: assetMap[alarmJson.device_id],
					triggeredAt: triggeredAt,
					clearedAt: new Date(value * 1000),
				}),
				Nothing: (): alarmDomain.Alarm => ({
					status: "active",
					sensor: alarmJson.sensor,
					deviceType: "Boat",
					deviceName: assetMap[alarmJson.device_id],
					triggeredAt: triggeredAt,
				}),
			});
		}
		case "PrincessYacht": {
			return alarmJson.cleared_at.cata({
				Just: (value: number): alarmDomain.Alarm => ({
					status: "clear",
					sensor: alarmJson.sensor,
					deviceType: "PrincessYacht",
					deviceName: assetMap[alarmJson.device_id],
					triggeredAt: triggeredAt,
					clearedAt: new Date(value * 1000),
				}),
				Nothing: (): alarmDomain.Alarm => ({
					status: "active",
					sensor: alarmJson.sensor,
					deviceType: "PrincessYacht",
					deviceName: assetMap[alarmJson.device_id],
					triggeredAt: triggeredAt,
				}),
			});
		}
	}
}

interface TimeFilter {
	from: string | Date;
	to: string | Date;
}

interface Config {
	appConfig: configDomain.Config;
	timeFilter: TimeFilter;
	pagination: {
		pageNumber: number;
		perPage: number;
	};
}

interface AlarmsList {
	data: Array<alarmDomain.Alarm>;
	meta: {
		pageNumber: number;
		totalPages: number;
		totalAlarms: number;
	};
}

interface DecodedApiResponse {
	list: Array<AlarmJSON>;
	total: number;
}

function getAlarms(
	config: Config,
	session: miimetiqRest.SessionToken
): Promise<AlarmsList> {
	const alarmsPromise = fetchAlarmsFromServer(config);
	const assetIdNameMappingPromise = getAssetNames(
		config.appConfig,
		session
	).then(mapAssetNameToId);
	return Promise.all([alarmsPromise, assetIdNameMappingPromise]).then(
		([alarms, assetIdNameMapping]: [
			DecodedApiResponse,
			AssetIdNameMapping
		]) => {
			const modelledAlarms: Array<alarmDomain.Alarm> = alarms.list.map(
				(alarmJson: AlarmJSON): alarmDomain.Alarm =>
					alarmJSON2DomainAlarm(assetIdNameMapping, alarmJson)
			);
			const totalPages = Math.ceil(alarms.total / config.pagination.perPage);
			return {
				meta: {
					pageNumber: config.pagination.pageNumber,
					totalPages,
					totalAlarms: alarms.total,
				},
				data: modelledAlarms,
			};
		}
	);
}

interface ElasticApiResponseError {
	message: string;
}

function fetchAlarmsFromServer({
	appConfig,
	timeFilter,
	pagination,
}: Config): Promise<DecodedApiResponse> {
	const skippedPages: number = pagination.pageNumber - 1;
	const skippedAlarms: number = skippedPages * pagination.perPage;
	if (
		typeof timeFilter.from === "string" &&
		!isValidDateMath(timeFilter.from)
	) {
		return Promise.reject(
			`'${timeFilter.from}' is not a valid date math expression`
		);
	}
	if (typeof timeFilter.to === "string" && !isValidDateMath(timeFilter.to)) {
		return Promise.reject(
			`'${timeFilter.to}' is not a valid date math expression`
		);
	}
	const rangeFilter = {
		gte:
			timeFilter.from instanceof Date
				? timeFilter.from.valueOf()
				: timeFilter.from,
		lte:
			timeFilter.to instanceof Date ? timeFilter.to.valueOf() : timeFilter.to,
		format: "epoch_millis",
	};
	const searchParams: elastic.SearchParams = {
		index: "alarms",
		from: skippedAlarms,
		size: pagination.perPage,
		sort: ["triggered_at:desc"],
		_source: [
			"device_type",
			"device_id",
			"name",
			"triggered_at",
			"trigger_value",
			"cleared_at",
		],
		body: {
			query: {
				bool: {
					must: [
						{
							range: {
								triggered_at: rangeFilter,
							},
						},
					],
				},
			},
		},
	};
	const alarmsUrl = appConfig.ALARMS.REST_API.URL;
	const client = new elastic.Client({
		host: alarmsUrl,
		apiVersion: "7.6",
	});
	return client
		.search(searchParams)
		.then((response) => {
			client.close();
			return response;
		})
		.catch((error: ElasticApiResponseError) => {
			client.close();
			return Promise.reject(
				`Historical API not reachable! ${error.message}. Is ${alarmsUrl} up?`
			);
		})
		.then((response) => {
			const total = extractTotalFromResponse(response);
			const list = filterDecodeErrors(
				decodeAlarms(extractHitsFromResponse(response))
			);
			return { list, total };
		});
}

function filterDecodeErrors(
	eitherAlarmsOrError: Array<Either<string, AlarmJSON>>
): Array<AlarmJSON> {
	return eitherAlarmsOrError
		.filter((eitherAlarmOrError: Either<string, AlarmJSON>) =>
			eitherAlarmOrError.caseOf({
				right: (): boolean => true,
				left: (error: string): boolean => {
					console.log(`Ignoring alarm. Reason: ${error}`);
					return false;
				},
			})
		)
		.map((eitherAlarmOrError: Either<never, AlarmJSON>): AlarmJSON => {
			return eitherAlarmOrError.caseOf({
				right: (alarm: AlarmJSON): AlarmJSON => alarm,
				left: (error: never): never => {
					throw new Error(`UNEXPECTED ERROR: ${error}`);
				},
			});
		});
}

interface Hit {
	_source: unknown;
}

function extractHitsFromResponse(
	response: elastic.SearchResponse<unknown>
): Array<unknown> {
	return response.hits.hits.map((hit: Hit) => hit._source);
}

function extractTotalFromResponse(
	response: elastic.SearchResponse<unknown>
): number {
	return response.hits.total;
}

function decodeAlarms(
	alarms: Array<unknown>
): Array<Either<string, AlarmJSON>> {
	return alarms.map(decodeAlarm);
}

function decodeAlarm(alarm: unknown): Either<string, AlarmJSON> {
	const alarmDecoder: JsonousDecoder<AlarmJSON> = jsonous
		.field("device_type", jsonous.string)
		.andThen((device_type: string) =>
			jsonous
				.field("device_id", jsonous.string)
				.andThen((device_id: string) =>
					jsonous
						.field("name", jsonous.string)
						.andThen((name: string) =>
							jsonous
								.field("triggered_at", jsonous.number)
								.andThen((triggered_at: number) =>
									jsonous
										.field("trigger_value", jsonous.string)
										.andThen((trigger_value: string) =>
											jsonous
												.maybe(jsonous.field("cleared_at", jsonous.number))
												.andThen((cleared_at: maybeasy.Maybe<number>) =>
													decodeAlarmJSON(
														device_type,
														device_id,
														name,
														triggered_at,
														trigger_value,
														cleared_at
													)
												)
										)
								)
						)
				)
		);
	return decoding.applyJsonousDecoder(
		alarmDecoder,
		{
			failure: (error: string): string => `Cannot decode alarm: ${error}`,
			success: (result: AlarmJSON): AlarmJSON => result,
		},
		alarm
	);
}

function decodeAlarmJSON(
	device_type: string,
	device_id: string,
	name: string,
	triggered_at: number,
	trigger_value: string,
	cleared_at: maybeasy.Maybe<number>
): JsonousDecoder<AlarmJSON> {
	switch (device_type) {
		case "Boat": {
			return decodeBoatAlarmJSON(
				device_type,
				device_id,
				name,
				triggered_at,
				trigger_value,
				cleared_at
			);
		}
		case "ScissorLift": {
			return decodeScissorAlarmJson(
				device_type,
				device_id,
				name,
				triggered_at,
				trigger_value,
				cleared_at
			);
		}
		case "PrincessYacht": {
			return decodePrincessYachtAlarmJson(
				device_type,
				device_id,
				name,
				triggered_at,
				trigger_value,
				cleared_at
			);
		}
		default: {
			const errorMsg = `Expected to find a supported device type. Instead found ${trigger_value}`;
			return jsonous.fail(errorMsg);
		}
	}
}

function decodePrincessYachtAlarmJson(
	device_type: "PrincessYacht",
	device_id: string,
	name: string,
	triggered_at: number,
	trigger_value: string,
	cleared_at: maybeasy.Maybe<number>
): JsonousDecoder<AlarmJSON> {
	return new JsonousDecoder<AlarmJSON>(
		(): resulty.Result<string, AlarmJSON> => {
			const sensor = ((): resulty.Result<
				string,
				princessYachtDomain.AlarmSensor
			> => {
				switch (name) {
					case "ExhaustHighTemp": {
						return jsonous.boolean.decodeJson(trigger_value).map(
							(v: boolean): princessYachtDomain.AlarmExhaustHighTemp => ({
								name: "Exhaust High Temp Alarm",
								label: "Exhaust High Temp",
								value: v,
								icon: "AlarmExhaustHighTemp",
							})
						);
					}

					default: {
						return resulty.err(`
							Expected to find a supported reader for a Princess Yacht. Instead found ${name}`);
					}
				}
			})();
			return sensor.map(
				(yachtSensor: princessYachtDomain.AlarmSensor): AlarmJSON => ({
					sensor: yachtSensor,
					device_type,
					device_id,
					triggered_at,
					cleared_at,
				})
			);
		}
	);
}

function decodeBoatAlarmJSON(
	device_type: "Boat",
	device_id: string,
	name: string,
	triggered_at: number,
	trigger_value: string,
	cleared_at: maybeasy.Maybe<number>
): JsonousDecoder<AlarmJSON> {
	return new JsonousDecoder<AlarmJSON>(
		(): resulty.Result<string, AlarmJSON> => {
			const sensor = ((): resulty.Result<string, boatDomain.AlarmSensor> => {
				switch (name) {
					case "Intruder": {
						return jsonous.boolean.decodeJson(trigger_value).map(
							(v: boolean): boatDomain.AlarmIntruder => ({
								name: "Alarm Intruder",
								label: "Intruder",
								value: v,
							})
						);
					}
					case "SmokeDetector": {
						return jsonous.boolean.decodeJson(trigger_value).map(
							(v: boolean): boatDomain.AlarmSmokeDetector => ({
								name: "Alarm Smoke Detector",
								label: "Smoke",
								value: v,
							})
						);
					}
					case "OilTemp": {
						return jsonous.number.decodeJson(trigger_value).map(
							(v: number): boatDomain.AlarmOilTemp => ({
								name: "Alarm Oil Temp",
								label: "Oil Temp",
								value: v,
								unit: "ºC",
							})
						);
					}
					case "CoolantLevel": {
						return jsonous.number.decodeJson(trigger_value).map(
							(v: number): boatDomain.AlarmCoolantLevel => ({
								name: "Alarm Coolant Level",
								label: "Coolant Level",
								value: v,
								unit: "CM",
							})
						);
					}
					case "DPFDifferentialPressure": {
						return jsonous.number.decodeJson(trigger_value).map(
							(v: number): boatDomain.AlarmDPFDifferentialPressure => ({
								name: "Alarm DPF Differential Pressure",
								label: "DPF Differential Pressure",
								value: v,
								unit: "PSI",
							})
						);
					}
					default: {
						return resulty.err(`
							Expected to find a supported reader for a Boat. Instead found ${name}`);
					}
				}
			})();
			return sensor.map(
				(boatSensor: boatDomain.AlarmSensor): AlarmJSON => ({
					sensor: boatSensor,
					device_type,
					device_id,
					triggered_at,
					cleared_at,
				})
			);
		}
	);
}

function decodeScissorAlarmJson(
	device_type: "ScissorLift",
	device_id: string,
	name: string,
	triggered_at: number,
	trigger_value: string,
	cleared_at: maybeasy.Maybe<number>
): JsonousDecoder<AlarmJSON> {
	return new JsonousDecoder<AlarmJSON>(
		(): resulty.Result<string, AlarmJSON> => {
			const sensor = ((): resulty.Result<string, scissorDomain.AlarmSensor> => {
				switch (name) {
					case "DistanceFromObstacle": {
						return jsonous.number.decodeJson(trigger_value).map(
							(v: number): scissorDomain.AlarmDistanceFromObstacle => ({
								name: "AlarmDistanceFromObstacle",
								value: v,
								unit: "metres",
								label: "Distance From Obstacle",
							})
						);
					}
					case "Angle": {
						return jsonous.number.decodeJson(trigger_value).map(
							(v: number): scissorDomain.AlarmAngle => ({
								name: "AlarmAngle",
								label: "Angle",
								value: v,
								unit: "º",
							})
						);
					}
					case "OilTemp": {
						return jsonous.number.decodeJson(trigger_value).map(
							(v: number): scissorDomain.AlarmOilTemp => ({
								name: "AlarmOilTemp",
								label: "Oil Temp",
								value: v,
								unit: "ºC",
							})
						);
					}
					case "WindSpeedAtTop": {
						return jsonous.number.decodeJson(trigger_value).map(
							(v: number): scissorDomain.AlarmWindSpeedAtTop => ({
								name: "AlarmWindSpeedAtTop",
								label: "Wind Speed At Top",
								value: v,
								unit: "m/s",
							})
						);
					}
					case "EngineTime": {
						return jsonous.number.decodeJson(trigger_value).map(
							(v: number): scissorDomain.AlarmEngineTime => ({
								name: "AlarmEngineTime",
								label: "Engine Time",
								value: v,
								unit: "hour",
							})
						);
					}
					case "CurrentPosition": {
						const values: Array<string> = trigger_value.split(",", 2);
						return jsonous.number
							.decodeJson(values[0])
							.andThen(
								(lon: number): resulty.Result<string, [number, number]> => {
									return jsonous.number
										.decodeJson(values[1])
										.andThen(
											(
												lat: number
											): resulty.Result<string, [number, number]> => {
												return resulty.ok<string, [number, number]>([lat, lon]);
											}
										);
								}
							)
							.map(
								(v: [number, number]): scissorDomain.AlarmCurrentPosition => ({
									name: "AlarmCurrentPosition",
									label: "Current Position",
									value: v,
								})
							);
					}
					case "WindSpeedForecast": {
						return jsonous.number.decodeJson(trigger_value).map(
							(v: number): scissorDomain.AlarmWindSpeedForecast => ({
								name: "AlarmWindSpeedForecast",
								label: "Wind Speed Forecast",
								value: v,
								unit: "m/s",
							})
						);
					}
					case "TyrePressure": {
						return jsonous.number.decodeJson(trigger_value).map(
							(v: number): scissorDomain.AlarmTyrePressure => ({
								name: "AlarmTyrePressure",
								label: "Tyre Pressure",
								value: v,
								unit: "psi",
							})
						);
					}
					case "FuelLevel": {
						return jsonous.number.decodeJson(trigger_value).map(
							(v: number): scissorDomain.AlarmFuelLevel => ({
								name: "AlarmFuelLevel",
								label: "Fuel Level",
								value: v,
								unit: "%",
							})
						);
					}
					default: {
						return resulty.err(
							`Expected to find a supported reader for a Scissor. Instead found ${name}`
						);
					}
				}
			})();
			return sensor.map(
				(scissorSensor: scissorDomain.AlarmSensor): AlarmJSON => ({
					sensor: scissorSensor,
					device_type,
					device_id,
					triggered_at,
					cleared_at,
				})
			);
		}
	);
}
