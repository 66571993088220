export {
	map,
	wrapper,
	searchbox,
	searchboxSuggestionsList,
	actionButton,
	pollingActions,
	refreshButton,
	topInputs,
	pollingLabel,
};

const wrapper: React.CSSProperties = {
	position: "relative", // Necessary to keep the absolute-positioned childs relative to the wrapper
	height: "100vh",
	maxHeight: "100%",
};

const topInputs: React.CSSProperties = {
	position: "absolute",
	top: 10,
	zIndex: 1000,
	left: 54,
	display: "flex",
	flexWrap: "wrap",
};

const searchbox: React.CSSProperties = {
	width: 240,
	margin: "0 10px 10px 0",
	paddingLeft: 8,
	paddingRight: 8,
	backgroundColor: "white",
	borderRadius: 4,
	border: "2px solid rgba(0,0,0,0.2)", // Same as zoom control of map
	backgroundClip: "padding-box", // Same as zoom control of map
};

const searchboxSuggestionsList: React.CSSProperties = {
	maxHeight: 500,
	overflowY: "auto",
};

const map: React.CSSProperties = {
	position: "absolute",
	top: 0,
	bottom: 0,
	width: "100%",
	left: 0,
};

const actionButton: React.CSSProperties = {
	zIndex: 1000,
	position: "absolute",
	bottom: 30,
	right: 20,
};

const pollingActions: React.CSSProperties = {
	height: 35,
	lineHeight: 0,
	minWidth: 0,
	backgroundColor: "white",
	borderRadius: 4,
	paddingRight: 8,
	border: "2px solid rgba(0,0,0,0.2)", // Same as zoom control of map
	backgroundClip: "padding-box", // Same as zoom control of map
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
};

const refreshButton: React.CSSProperties = {
	width: 40,
	minWidth: 0,
	lineHeight: 0,
};

const pollingLabel: React.CSSProperties = {
	marginRight: "6px",
	marginLeft: "-4px",
};
