import { PrincessYacht, Actuator } from "../domain/princess-yacht";
import { Either } from "tsmonad";
import * as jsonous from "jsonous";

import * as miimetiqRest from "./services/miimetiq-rest";
import * as decoding from "./services/decoding";

export { getPrincessYachts, sendActuator };

function getPrincessYachts(
	url: string,
	sessionToken: miimetiqRest.SessionToken
): Promise<Array<PrincessYacht>> {
	return miimetiqRest
		.getAssets(url, sessionToken)
		.then(decodePrincessYachts)
		.then(filterDecodeErrors);
}

function decodePrincessYachts(
	assets: Array<miimetiqRest.AssetJSON>
): Array<Either<string, PrincessYacht>> {
	return assets.map(decodePrincessYacht);
}

function filterDecodeErrors(
	eitherYachtsOrErrors: Array<Either<string, PrincessYacht>>
): Array<PrincessYacht> {
	return eitherYachtsOrErrors
		.filter((eitherYachtOrError: Either<string, PrincessYacht>) =>
			eitherYachtOrError.caseOf({
				right: (): boolean => true,
				left: (error: string): boolean => {
					console.log(`Ignoring Yacht. Reason: ${error}`);
					return false;
				},
			})
		)
		.map((eitherYachtOrError: Either<never, PrincessYacht>): PrincessYacht => {
			return eitherYachtOrError.caseOf({
				right: (asset: PrincessYacht): PrincessYacht => asset,

				left: (error: never): never => {
					throw new Error(`UNEXPECTED ERROR: ${error}`);
				},
			});
		});
}

// prettier-ignore
function decodePrincessYacht(asset: miimetiqRest.AssetJSON): Either<string, PrincessYacht> {
	// tslint:disable:max-line-length
	const decoder =
		jsonous.field("lon_lat", miimetiqRest.lonlatDecoder).andThen((geofence: [number, number]) =>
		miimetiqRest.decodeNullableWriter(jsonous.boolean, [ 'PrincessYacht', 'Power' ]).andThen((Power: boolean | null) =>
		miimetiqRest.decodeNullableWriter(jsonous.boolean, [ 'PrincessYacht', 'Lock' ]).andThen((Lock: boolean | null) =>
		miimetiqRest.decodeNullableReader(jsonous.boolean, [ 'PrincessYacht', 'PowerStatus' ]).andThen((PowerStatus: boolean | null) =>
		miimetiqRest.decodeNullableReader(jsonous.boolean, [ 'PrincessYacht', 'LockStatus' ]).andThen((LockStatus: boolean | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'PrincessYacht', 'BatteriesDomestic' ]).andThen((BatteriesDomestic: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'PrincessYacht', 'Shoreline1LowHighVoltage' ]).andThen((Shoreline1LowHighVoltage: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'PrincessYacht', 'Generator1LowHighVoltage' ]).andThen((Generator1LowHighVoltage: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'PrincessYacht', 'FuelLevel' ]).andThen((FuelLevel: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'PrincessYacht', 'FreshWaterLevel' ]).andThen((FreshWaterLevel: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'PrincessYacht', 'BlackWaterLevel' ]).andThen((BlackWaterLevel: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'PrincessYacht', 'GreyWaterLevel' ]).andThen((GreyWaterLevel: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'PrincessYacht', 'OilPressure' ]).andThen((OilPressure: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'PrincessYacht', 'EngineRPM' ]).andThen((EngineRPM: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'PrincessYacht', 'EngineRunningHours' ]).andThen((EngineRunningHours: number | null) =>
		miimetiqRest.decodeNonnullableReader(miimetiqRest.lonlatDecoder, [ 'PrincessYacht', 'CurrentPosition' ]).andThen((CurrentPosition: [number, number]) =>
		miimetiqRest.decodeNullableReader(jsonous.boolean, [ 'PrincessYacht', 'alarms', 'ExhaustHighTemp' ]).andThen((AlarmExhaustHighTemp: boolean | null) =>
		jsonous.succeed<PrincessYacht>({
			kind: "PrincessYacht",
			id: asset._id,
			name: asset.name,
			geofence: {
				center: geofence,
				outerRadius: 10000,
				innerRadius: 8000
			},
			Power: {
				name: "Power",
				value: Power,
				trueLiteral: "ON",
				falseLiteral: "OFF",
			},
			Lock: {
				name: "Lock",
				value: Lock,
				trueLiteral: "LOCKED",
				falseLiteral: "UNLOCKED",
			},
			PowerStatus: {
				name: "Power Status",
				value: PowerStatus,
			},
			LockStatus: {
				name: "Lock Status",
				value: LockStatus,
			},
			CurrentPosition: {
				name: "Current Position",
				value: CurrentPosition,
				icon: "CurrentPosition",
			},
			FuelLevel: {
				name: "Fuel Level",
				value: FuelLevel,
				unit: "%",
				icon: "FuelLevel",
			},
			BatteriesDomestic: {
				name: "Batteries Domestic",
				value: BatteriesDomestic,
				unit: "V",
				icon: "BatteriesDomestic",
			},
			Shoreline1LowHighVoltage: {
				name: "Shoreline 1 Volt",
				value: Shoreline1LowHighVoltage,
				unit: "V",
				icon: "ShorelineLowHighVoltage",
			},
			Generator1LowHighVoltage: {
				name: "Generator 1 Volt",
				value: Generator1LowHighVoltage,
				unit: "V",
				icon: "GeneratorLowHighVoltage",
			},
			FreshWaterLevel: {
				name: "Fresh Water Level",
				value: FreshWaterLevel,
				unit: "%",
				icon: "FreshWaterLevel"
			},
			GreyWaterLevel: {
				name: "Grey Water Level",
				value: GreyWaterLevel,
				unit: "%",
				icon: "GreyWaterLevel"
			},
			BlackWaterLevel: {
				name: "Black Water Level",
				value: BlackWaterLevel,
				unit: "%",
				icon: "BlackWaterLevel"
			},
			EngineRPM: {
				name: "Engine RPM",
				value: EngineRPM,
				unit: "RPM",
				icon: "EngineRPM",
			},
			OilPressure: {
				name: "Oil Pressure",
				value: OilPressure,
				unit: "PSI",
				icon: "OilPressure",
			},
			EngineRunningHours: {
				name: "Engine Running Hours",
				value: EngineRunningHours,
				unit: "h",
				icon: "EngineRunningHours",
			},
			AlarmExhaustHighTemp: {
				name: "Exhaust High Temp Alarm",
				label: "Exhaust High Temp",
				value: AlarmExhaustHighTemp,
				icon: "AlarmExhaustHighTemp"
			}
		}))))))))))))))))));
	return decoding.applyJsonousDecoder(
		decoder,
		{
			success: (yacht: PrincessYacht): PrincessYacht => yacht,
			failure: (error: string): string => `Cannot decode yacht ${asset.name}: ${error}`
		},
		asset.raw
	);
}

function sendActuator(
	url: string,
	sessionToken: miimetiqRest.SessionToken,
	actuator: Actuator
): Promise<void> {
	return miimetiqRest
		.sendRpc(url, sessionToken, { value: actuator.value })
		.then((): Promise<void> => {
			return Promise.resolve();
		});
}
