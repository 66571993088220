import * as React from "react";
import * as reduxLoop from "redux-loop";
import { IconButton, AppBar, Tabs, Tab } from "@material-ui/core";
import {
	FullscreenExit as FullscreenExitIcon,
	Fullscreen as FullscreenIcon,
	Close as CloseIcon,
} from "@material-ui/icons";

// Domain
import { Scissor } from "../../../domain/scissor";
import { Boat } from "../../../domain/boat";
import { PrincessYacht } from "../../../domain/princess-yacht";
import { Config } from "../../../domain/config";

import { SessionToken } from "@src/persistence/session";

// Toolkit
import * as architecture from "../../toolkit/architecture";

// Components
import * as boatSynoptic from "./views/synoptic/boat";
import * as scissorSynoptic from "./views/synoptic/scissor";
import * as princessYachtSynoptic from "./views/synoptic/princess-yacht";
import * as grafana from "./views/grafana";

// Styles
import * as styles from "./styles";
import { custom } from "@src/presentation/styles/palette";

export { Model, init, Action, reducer, View };

type Device = Scissor | Boat | PrincessYacht;

// MODEL

type TabType = "synoptic" | "historical";

interface Model {
	tab: TabType;
	synoptic: {
		scissor: scissorSynoptic.Model;
		boat: boatSynoptic.Model;
		princessYacht: princessYachtSynoptic.Model;
	};
}

const init: Model = {
	tab: "synoptic",
	synoptic: {
		scissor: scissorSynoptic.init,
		boat: boatSynoptic.init,
		princessYacht: princessYachtSynoptic.init,
	},
};

// UPDATE

type Action =
	| { type: "SELECT TAB"; tab: TabType }
	| { type: "BOAT SYNOPTIC ACTION"; action: boatSynoptic.Action }
	| { type: "SCISSOR SYNOPTIC ACTION"; action: scissorSynoptic.Action }
	| {
			type: "PRINCESS YACHT SYNOPTIC ACTION";
			action: princessYachtSynoptic.Action;
	  }
	| { type: "NoOp" };

function reducer(state: Model, action: Action): [Model, reduxLoop.CmdType] {
	switch (action.type) {
		case "SELECT TAB": {
			return [{ ...state, tab: action.tab }, reduxLoop.Cmd.none];
		}
		case "SCISSOR SYNOPTIC ACTION": {
			const [newScissorSynopticState, newScissorSynopticCmd]: [
				scissorSynoptic.Model,
				reduxLoop.CmdType
			] = scissorSynoptic.reducer(state.synoptic.scissor, action.action);
			const newState: Model = {
				...state,
				synoptic: { ...state.synoptic, scissor: newScissorSynopticState },
			};
			const newCmd = reduxLoop.Cmd.map(
				newScissorSynopticCmd,
				(scissorSynopticAction: scissorSynoptic.Action): Action => ({
					type: "SCISSOR SYNOPTIC ACTION",
					action: scissorSynopticAction,
				})
			);
			return [newState, newCmd];
		}
		case "BOAT SYNOPTIC ACTION": {
			const [newBoatSynopticState, newBoatSynopticCmd]: [
				boatSynoptic.Model,
				reduxLoop.CmdType
			] = boatSynoptic.reducer(state.synoptic.boat, action.action);
			const newState: Model = {
				...state,
				synoptic: { ...state.synoptic, boat: newBoatSynopticState },
			};
			const newCmd = reduxLoop.Cmd.map(
				newBoatSynopticCmd,
				(boatSynopticAction: boatSynoptic.Action): Action => ({
					type: "BOAT SYNOPTIC ACTION",
					action: boatSynopticAction,
				})
			);
			return [newState, newCmd];
		}
		case "PRINCESS YACHT SYNOPTIC ACTION": {
			const [newYachtSynopticState, newYachtSynopticCmd]: [
				princessYachtSynoptic.Model,
				reduxLoop.CmdType
			] = princessYachtSynoptic.reducer(
				state.synoptic.princessYacht,
				action.action
			);
			const newState: Model = {
				...state,
				synoptic: { ...state.synoptic, princessYacht: newYachtSynopticState },
			};
			const newCmd = reduxLoop.Cmd.map(
				newYachtSynopticCmd,
				(yachtSynopticAction: princessYachtSynoptic.Action): Action => ({
					type: "PRINCESS YACHT SYNOPTIC ACTION",
					action: yachtSynopticAction,
				})
			);
			return [newState, newCmd];
		}

		case "NoOp": {
			return [state, reduxLoop.Cmd.none];
		}
	}
}

// VIEW

// EVENT HANDLERS

function onTabChange(
	dispatch: architecture.Dispatch<Action>,
	tab: TabType
): void {
	dispatch({ type: "SELECT TAB", tab });
}

interface StateProps {
	state: Model;
	device: Device;
	maximized: boolean;
	config: Config;
	session: SessionToken;
	enableScreenModeSwitch: boolean;
}

interface HandlerProps {
	dispatch: architecture.Dispatch<Action>;
	onCloseClicked: () => void;
	onMaximizeClicked: () => void;
	onNormalScreenClicked: () => void;
}

type ViewProps = StateProps & HandlerProps;

function View({
	config,
	session,
	state,
	dispatch,
	maximized,
	device,
	onCloseClicked,
	onMaximizeClicked,
	onNormalScreenClicked,
	enableScreenModeSwitch,
}: ViewProps): JSX.Element {
	const changeScreenModeButton: JSX.Element = enableScreenModeSwitch ? (
		<div></div>
	) : maximized ? (
		<IconButton onClick={onNormalScreenClicked}>
			<FullscreenExitIcon style={{ fill: custom.lightGray }} />
		</IconButton>
	) : (
		<IconButton onClick={onMaximizeClicked}>
			<FullscreenIcon style={{ fill: custom.lightGray }} />
		</IconButton>
	);

	const navRightButtons: JSX.Element = (
		<div>
			{changeScreenModeButton}
			<IconButton onClick={onCloseClicked}>
				<CloseIcon style={{ fill: custom.lightGray }} />
			</IconButton>
		</div>
	);

	const tabs: JSX.Element = (
		<Tabs
			value={state.tab}
			onChange={(_, tab: TabType): void => {
				onTabChange(dispatch, tab);
			}}
			indicatorColor="secondary"
		>
			<Tab label="synoptic" value="synoptic" />
			<Tab label="historical" value="historical" />
		</Tabs>
	);

	const boatSynopticDispatcher: architecture.Dispatch<boatSynoptic.Action> =
		architecture.mapDispatch(
			dispatch,
			(action: boatSynoptic.Action): Action => ({
				type: "BOAT SYNOPTIC ACTION",
				action: action,
			})
		);

	const scissorSynopticDispatcher: architecture.Dispatch<scissorSynoptic.Action> =
		architecture.mapDispatch(
			dispatch,
			(action: scissorSynoptic.Action): Action => ({
				type: "SCISSOR SYNOPTIC ACTION",
				action: action,
			})
		);
	const princessYachtSynopticDispatcher: architecture.Dispatch<princessYachtSynoptic.Action> =
		architecture.mapDispatch(
			dispatch,
			(action: princessYachtSynoptic.Action): Action => ({
				type: "PRINCESS YACHT SYNOPTIC ACTION",
				action,
			})
		);
	const synoptic: JSX.Element = ((): JSX.Element => {
		switch (device.kind) {
			case "Scissor": {
				return (
					<scissorSynoptic.View
						scissor={device}
						dispatch={scissorSynopticDispatcher}
						state={state.synoptic.scissor}
						style={styles.scissorSynoptic}
						config={config}
						session={session}
					/>
				);
			}
			case "Boat": {
				return (
					<boatSynoptic.View
						boat={device}
						dispatch={boatSynopticDispatcher}
						state={state.synoptic.boat}
						config={config}
						session={session}
					/>
				);
			}
			case "PrincessYacht": {
				return (
					<princessYachtSynoptic.View
						yacht={device}
						dispatch={princessYachtSynopticDispatcher}
						state={state.synoptic.princessYacht}
						config={config}
						session={session}
					/>
				);
			}
		}
	})();

	const historicsUrl: string = ((): string => {
		switch (device.kind) {
			case "Scissor": {
				return `${config.HISTORICS_VIEW.SCISSORS.URL}&var-device=${device.id}`;
			}
			case "Boat": {
				return `${config.HISTORICS_VIEW.BOATS.URL}&var-device=${device.id}`;
			}
			case "PrincessYacht": {
				return `${config.HISTORICS_VIEW.PRINCESS_YACHTS.URL}&var-device=${device.id}`;
			}
		}
	})();

	return (
		<div id="details-view" style={styles.detailsView}>
			<div id="details-view__nav-bar">
				<AppBar style={styles.appBar}>
					{tabs}
					{navRightButtons}
				</AppBar>
			</div>
			{state.tab === "synoptic" ? (
				<div id="details-view__synoptic-container" style={styles.container}>
					{synoptic}
				</div>
			) : (
				<div id="details-view__historical-container" style={styles.container}>
					<grafana.View url={historicsUrl} />
				</div>
			)}
		</div>
	);
}
