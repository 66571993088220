import { custom } from "@src/presentation/styles/palette";

export { loadingScreen, loadingSvg };

const loadingScreen: React.CSSProperties = {
	backgroundColor: custom.lightBlue,
	position: "absolute",
	top: 0,
	bottom: 0,
	right: 0,
	left: 0,
};

const loadingSvg: React.CSSProperties = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate3d(-50%,-50%,0) scaleX(-1)" /* flip the material svg*/,
	animation: "spin 1s linear infinite",
};
