// Cardinal point ranges to show the wind direction
// These ranges should be the same as those defined in Grafana

export { degrees2cardinalPoint };

interface Range {
	from: number;
	to: number;
	text: CardinalPoint;
}

const ranges: Array<Range> = [
	{
		from: 348.75,
		text: "N",
		to: 360,
	},
	{
		from: 0,
		text: "N",
		to: 11.25,
	},
	{
		from: 11.25,
		text: "NNE",
		to: 33.75,
	},
	{
		from: 33.75,
		text: "NE",
		to: 56.25,
	},
	{
		from: 56.25,
		to: 78.75,
		text: "ENE",
	},
	{
		from: 78.75,
		text: "E",
		to: 101.25,
	},
	{
		from: 101.25,
		text: "ESE",
		to: 123.75,
	},
	{
		from: 123.75,
		text: "SE",
		to: 146.25,
	},
	{
		from: 146.25,
		text: "SSE",
		to: 168.75,
	},
	{
		from: 168.75,
		text: "S",
		to: 191.25,
	},
	{
		from: 191.25,
		text: "SSW",
		to: 213.75,
	},
	{
		from: 213.75,
		text: "SW",
		to: 236.25,
	},
	{
		from: 236.25,
		text: "WSW",
		to: 258.75,
	},
	{
		from: 258.75,
		text: "W",
		to: 281.25,
	},
	{
		from: 281.25,
		text: "WNW",
		to: 303.75,
	},
	{
		from: 303.75,
		text: "NW",
		to: 326.25,
	},
	{
		from: 326.25,
		text: "NNW",
		to: 348.75,
	},
];

type CardinalPoint =
	| "N"
	| "NW"
	| "NE"
	| "S"
	| "SW"
	| "SE"
	| "E"
	| "W"
	| "NNW"
	| "WNW"
	| "WSW"
	| "NNE"
	| "ENE"
	| "SSW"
	| "SSE"
	| "ESE"
	| NoCardinalPoint;

type NoCardinalPoint = "";

function degrees2cardinalPoint(degrees: number): CardinalPoint {
	const range: Range | undefined = ranges.find(
		(possibleRange: Range) =>
			possibleRange.from <= degrees && possibleRange.to >= degrees
	);
	if (range) {
		return range.text;
	} else {
		return "";
	}
}
