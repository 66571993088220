import * as React from "react";

export { View };

const iframeWrapperStyle: React.CSSProperties = {
	WebkitOverflowScrolling: "touch",
	overflowY: "auto",
	position: "absolute",
	right: 0,
	bottom: 0,
	left: 0,
	top: 0,
};

const iframeStyle: React.CSSProperties = {
	width: "100%",
	height: "100%",
	border: 0,
	position: "absolute",
};

interface GrafanaProps {
	url: string;
}

function View({ url }: GrafanaProps): JSX.Element {
	return (
		<div style={iframeWrapperStyle}>
			<iframe src={url} style={iframeStyle} />
		</div>
	);
}
