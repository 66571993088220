import * as React from "react";
import * as reactDom from "react-dom";
import * as redux from "redux";
import * as reactRedux from "react-redux";
import * as reduxLoop from "redux-loop";
import * as reactHotLoader from "react-hot-loader";
import { ThemeProvider } from "@material-ui/core";
import GoogleFontLoader from "react-google-font-loader";

import * as rootView from "./presentation/views/root";
import { customTheme } from "./presentation/styles/material-theme";
import { fontFamily } from "@src/presentation/styles/typography";

// For Webpack!

import "leaflet/dist/leaflet.css";

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const composeEnhancers =
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-explicit-any
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || redux.compose;
const store: redux.Store<rootView.Model> = redux.createStore<
	rootView.Model,
	redux.AnyAction,
	unknown,
	unknown
>(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rootView.reducer as any,
	rootView.init,
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	composeEnhancers(reduxLoop.install())
);

const App: JSX.Element = (
	<reactHotLoader.AppContainer>
		<reactRedux.Provider store={store}>
			<ThemeProvider theme={customTheme}>
				<GoogleFontLoader fonts={[{ font: fontFamily }]} />
				<rootView.ConnectedView />
			</ThemeProvider>
		</reactRedux.Provider>
	</reactHotLoader.AppContainer>
);
function render(): void {
	reactDom.render(App, document.getElementById("app"));
}
render();
if (module.hot) {
	// Hot reloading works for subviews, but not foor root
	// Don't know why yet
	module.hot.accept("./presentation/views/root", render);
}
