// 3rd party
import * as React from "react";
import * as reactRedux from "react-redux";
import * as reduxLoop from "redux-loop";
import { Maybe } from "tsmonad";
import { CircularProgress } from "@material-ui/core";

// Domain
import { Config } from "@src/domain/config";
import { Scissor } from "@src/domain/scissor";
import { Boat } from "@src/domain/boat";
import { PrincessYacht } from "@src/domain/princess-yacht";
import * as errorDomain from "@src/domain/error";

// Persistence
import * as scissorPersistence from "@src/persistence/scissor";
import * as boatPersistence from "@src/persistence/boat";
import * as princessYachtPersistence from "@src/persistence/princess-yacht";
import * as configPersistence from "@src/persistence/config";
import { SessionToken, ensureValidSession } from "@src/persistence/session";

// Toolkits
import * as architecture from "@src/presentation/toolkit/architecture";

// Styles
import * as styles from "./styles";

// Components
import * as navigationView from "../navigation";
export { Model, init, ConnectedView, reducer };
import { ErrorAlert } from "./components/errorAlert";

// MODEL
type Device = Scissor | Boat | PrincessYacht;
type DeviceList = Array<Device>;

interface Pending {
	initialization: "pending";
	navigation: navigationView.Model;
}

interface Failed {
	initialization: "failed";
	error: errorDomain.MIIMETIQError;
	navigation: navigationView.Model;
}

interface Initialized {
	configuration: Config;
	session: SessionToken;
	navigation: navigationView.Model;
	devices: DeviceList;
	initialization: "success";
	timerId: Maybe<TimerId>;
	activePolling: boolean;
}

interface Reloading {
	configuration: Config;
	session: SessionToken;
	navigation: navigationView.Model;
	devices: DeviceList;
	initialization: "reloading";
	timerId: Maybe<TimerId>;
	activePolling: boolean;
}

type Model = Initialized | Pending | Failed | Reloading;

const init: Pending = {
	navigation: navigationView.init,
	initialization: "pending",
};

// UPDATE

type Action =
	| {
			type: "NAVIGATION VIEW ACTION";
			viewAction: navigationView.Action;
	  }
	| {
			type: "INITIALIZATION";
			step: "START";
			dispatch: architecture.Dispatch<Action>;
	  }
	| {
			type: "INITIALIZATION";
			step: "CONFIGURE";
			status: "START";
			dispatch: architecture.Dispatch<Action>;
	  }
	| {
			type: "INITIALIZATION";
			step: "CONFIGURE";
			status: "SUCCESS";
			config: Config;
			dispatch: architecture.Dispatch<Action>;
	  }
	| {
			type: "INITIALIZATION";
			step: "ENSURE VALID SESSION";
			status: "START";
			config: Config;
			dispatch: architecture.Dispatch<Action>;
	  }
	| {
			type: "INITIALIZATION";
			step: "ENSURE VALID SESSION";
			status: "SUCCESS";
			config: Config;
			session: SessionToken;
			dispatch: architecture.Dispatch<Action>;
	  }
	| {
			type: "INITIALIZATION";
			step: "LOAD DEVICES";
			status: "START";
			config: Config;
			session: SessionToken;
			dispatch: architecture.Dispatch<Action>;
	  }
	| {
			type: "INITIALIZATION";
			step: "LOAD DEVICES";
			status: "SUCCESS";
			devices: DeviceList;
			config: Config;
			session: SessionToken;
			dispatch: architecture.Dispatch<Action>;
	  }
	| {
			type: "INITIALIZATION";
			step: "CONFIGURE" | "LOAD DEVICES" | "ENSURE VALID SESSION";
			status: "FAILURE";
			error: errorDomain.MIIMETIQError;
	  }
	| {
			type: "INITIALIZATION";
			step: "FINISH";
			devices: DeviceList;
			config: Config;
			session: SessionToken;
			dispatch: architecture.Dispatch<Action>;
	  }
	| {
			type: "INITIALIZATION";
			step: "RELOAD DEVICES";
			status: "START";
			config: Config;
			session: SessionToken;
			dispatch: architecture.Dispatch<Action>;
			devices: DeviceList;
	  }
	| {
			type: "CHANGE_ACTIVE_POLLING";
			status: boolean;
			config: Config;
			session: SessionToken;
			dispatch: architecture.Dispatch<Action>;
			devices: DeviceList;
	  }
	| PollingAction
	| {
			type: "NoOp";
	  };

type TimerId = number;

type PollingAction =
	| {
			type: "SCHEDULE NEXT POLL";
			config: Config;
			session: SessionToken;
			dispatch: architecture.Dispatch<Action>;
			devices: DeviceList;
	  }
	| {
			type: "STORE POLL TIMER ID";
			timerId: TimerId;
	  };

function reducer(state: Model, action: Action): [Model, reduxLoop.CmdType] {
	switch (action.type) {
		case "INITIALIZATION": {
			switch (action.step) {
				case "START": {
					return [
						state,
						reduxLoop.Cmd.action<Action>({
							type: "INITIALIZATION",
							step: "CONFIGURE",
							status: "START",
							dispatch: action.dispatch,
						}),
					];
				}
				case "CONFIGURE": {
					switch (action.status) {
						case "START": {
							const actionIfConfigureFails = (
								error: errorDomain.MIIMETIQError
							): Action => ({
								type: "INITIALIZATION",
								step: "CONFIGURE",
								status: "FAILURE",
								error: error,
							});

							const actionIfConfigureSucceeds = (config: Config): Action => {
								return {
									type: "INITIALIZATION",
									step: "CONFIGURE",
									status: "SUCCESS",
									config,
									dispatch: action.dispatch,
								};
							};

							const configureCmd = reduxLoop.Cmd.run(configureApplication, {
								successActionCreator: actionIfConfigureSucceeds,
								failActionCreator: actionIfConfigureFails,
							});
							return [state, configureCmd];
						}
						case "FAILURE": {
							const error: errorDomain.MIIMETIQError = action.error;
							const newState: Model = {
								error,
								initialization: "failed",
								navigation: state.navigation,
							};
							return [newState, reduxLoop.Cmd.none];
						}
						case "SUCCESS": {
							return [
								state,
								reduxLoop.Cmd.action<Action>({
									type: "INITIALIZATION",
									step: "ENSURE VALID SESSION",
									status: "START",
									config: action.config,
									dispatch: action.dispatch,
								}),
							];
						}
						default: {
							return architecture.assertExhaustiveSwitch(action, [
								state,
								reduxLoop.Cmd.none,
							]);
						}
					}
				}
				case "ENSURE VALID SESSION": {
					switch (action.status) {
						case "START": {
							const actionIfEnsureValidSessionSucceeds = (
								session: SessionToken
							): Action => {
								return {
									type: "INITIALIZATION",
									step: "ENSURE VALID SESSION",
									status: "SUCCESS",
									config: action.config,
									session,
									dispatch: action.dispatch,
								};
							};

							const actionIfEnsureValidSessionFails = (
								error: errorDomain.MIIMETIQError
							): Action => ({
								type: "INITIALIZATION",
								step: "ENSURE VALID SESSION",
								status: "FAILURE",
								error: error,
							});

							const ensureValidSessionCmd = reduxLoop.Cmd.run(
								ensureValidSession,
								{
									successActionCreator: actionIfEnsureValidSessionSucceeds,
									failActionCreator: actionIfEnsureValidSessionFails,
									args: [action.config],
								}
							);
							return [state, ensureValidSessionCmd];
						}
						case "FAILURE": {
							const error = action.error;
							const newState: Model = {
								error,
								initialization: "failed",
								navigation: state.navigation,
							};
							return [newState, reduxLoop.Cmd.none];
						}
						case "SUCCESS": {
							return [
								state,
								reduxLoop.Cmd.action<Action>({
									type: "INITIALIZATION",
									step: "LOAD DEVICES",
									status: "START",
									config: action.config,
									session: action.session,
									dispatch: action.dispatch,
								}),
							];
						}
						default: {
							return architecture.assertExhaustiveSwitch(action, [
								state,
								reduxLoop.Cmd.none,
							]);
						}
					}
				}

				case "LOAD DEVICES": {
					switch (action.status) {
						case "START": {
							const actionIfCmdSucceeds = (devices: DeviceList): Action => {
								return {
									type: "INITIALIZATION",
									step: "LOAD DEVICES",
									status: "SUCCESS",
									devices: devices,
									config: action.config,
									session: action.session,
									dispatch: action.dispatch,
								};
							};

							const actionIfCmdFails = (
								error: errorDomain.MIIMETIQError
							): Action => {
								return {
									type: "INITIALIZATION",
									step: "LOAD DEVICES",
									status: "FAILURE",
									error: error,
								};
							};

							const startGetDevicesCmd = reduxLoop.Cmd.run(loadDevices, {
								successActionCreator: actionIfCmdSucceeds,
								failActionCreator: actionIfCmdFails,
								args: [action.config, action.session],
							});
							return [state, startGetDevicesCmd];
						}
						case "FAILURE": {
							const newState: Model = {
								error: action.error,
								initialization: "failed",
								navigation: state.navigation,
							};
							return [newState, reduxLoop.Cmd.none];
						}
						case "SUCCESS": {
							return [
								state,
								reduxLoop.Cmd.action<Action>({
									type: "INITIALIZATION",
									step: "FINISH",
									devices: action.devices,
									config: action.config,
									session: action.session,
									dispatch: action.dispatch,
								}),
							];
						}
						default: {
							return architecture.assertExhaustiveSwitch(action, [
								state,
								reduxLoop.Cmd.none,
							]);
						}
					}
				}
				case "RELOAD DEVICES": {
					switch (action.status) {
						case "START": {
							const actionIfCmdSucceeds = (devices: DeviceList): Action => {
								return {
									type: "INITIALIZATION",
									step: "LOAD DEVICES",
									status: "SUCCESS",
									devices: devices,
									config: action.config,
									session: action.session,
									dispatch: action.dispatch,
								};
							};

							const actionIfCmdFails = (
								error: errorDomain.MIIMETIQError
							): Action => {
								return {
									type: "INITIALIZATION",
									step: "LOAD DEVICES",
									status: "FAILURE",
									error: error,
								};
							};

							const startGetDevicesCmd = reduxLoop.Cmd.run(loadDevices, {
								successActionCreator: actionIfCmdSucceeds,
								failActionCreator: actionIfCmdFails,
								args: [action.config, action.session],
							});
							const newState: Reloading = {
								navigation: state.navigation,
								initialization: "reloading",
								devices: action.devices,
								configuration: action.config,
								session: action.session,
								timerId: Maybe.nothing(),
								activePolling:
									state.initialization === "success" ||
									state.initialization === "reloading"
										? state.activePolling
										: true,
							};
							return [newState, startGetDevicesCmd];
						}
						default: {
							return [state, reduxLoop.Cmd.none];
						}
					}
				}
				case "FINISH": {
					const scheduleNextPollAction: () => PollingAction =
						(): PollingAction => {
							return {
								type: "SCHEDULE NEXT POLL",
								config: action.config,
								session: action.session,
								dispatch: action.dispatch,
								devices: action.devices,
							};
						};
					switch (state.initialization) {
						case "pending": {
							const newState: Initialized = {
								navigation: state.navigation,
								initialization: "success",
								devices: action.devices,
								configuration: action.config,
								session: action.session,
								timerId: Maybe.nothing(),
								activePolling: true,
							};
							return [
								newState,
								reduxLoop.Cmd.run(cancelNextPoll, {
									successActionCreator: scheduleNextPollAction,
									args: [Maybe.nothing()],
								}),
							];
						}
						case "failed": {
							return [
								state,
								reduxLoop.Cmd.run(cancelNextPoll, {
									successActionCreator: scheduleNextPollAction,
									args: [Maybe.nothing()],
								}),
							];
						}
						case "success":
						case "reloading": {
							const newState: Initialized = {
								navigation: state.navigation,
								initialization: "success",
								devices: action.devices,
								configuration: action.config,
								session: action.session,
								timerId: Maybe.nothing(),
								activePolling: state.activePolling,
							};
							const nextCmd:
								| reduxLoop.RunCmd<PollingAction>
								| reduxLoop.NoneCmd =
								state.activePolling === false
									? reduxLoop.Cmd.none
									: reduxLoop.Cmd.run(cancelNextPoll, {
											successActionCreator: scheduleNextPollAction,
											args: [state.timerId],
									  });
							return [newState, nextCmd];
						}
						default: {
							return architecture.assertExhaustiveSwitch(state, [
								state,
								reduxLoop.Cmd.none,
							]);
						}
					}
				}
				default: {
					return architecture.assertExhaustiveSwitch(action, [
						state,
						reduxLoop.Cmd.none,
					]);
				}
			}
		} //  end case "INITIALIZATION"
		case "SCHEDULE NEXT POLL": {
			const actionIfCmdSucceeds: (timerId: TimerId) => Action = (
				timerId: TimerId
			): Action => {
				return {
					type: "STORE POLL TIMER ID",
					timerId,
				};
			};
			return [
				state,
				reduxLoop.Cmd.run(scheduleNextPoll, {
					successActionCreator: actionIfCmdSucceeds,
					args: [
						action.dispatch,
						action.config,
						action.session,
						action.devices,
					],
				}),
			];
		}
		case "STORE POLL TIMER ID": {
			const newState: Model =
				state.initialization === "success"
					? {
							...state,
							timerId: Maybe.just(action.timerId),
					  }
					: state;
			return [newState, reduxLoop.Cmd.none];
		}

		case "NAVIGATION VIEW ACTION": {
			const [newNavigationState, newNavigationAction]: [
				navigationView.Model,
				reduxLoop.CmdType
			] = navigationView.reducer(state.navigation, action.viewAction);

			const newState: Model = {
				...state,
				navigation: newNavigationState,
			};
			const newCmd: reduxLoop.CmdType = reduxLoop.Cmd.map(
				newNavigationAction,
				(navigationAction: navigationView.Action): Action => {
					return {
						type: "NAVIGATION VIEW ACTION",
						viewAction: navigationAction,
					};
				}
			);
			return [newState, newCmd];
		}

		case "CHANGE_ACTIVE_POLLING": {
			if (
				state.initialization !== "success" &&
				state.initialization !== "reloading"
			) {
				return [state, reduxLoop.Cmd.none];
			}
			const newState: Model = { ...state, activePolling: action.status };

			const scheduleNextPollAction: () => Action = (): Action => {
				return {
					type: "SCHEDULE NEXT POLL",
					config: action.config,
					session: action.session,
					dispatch: action.dispatch,
					devices: action.devices,
				};
			};
			const noOp: () => Action = (): Action => {
				return {
					type: "NoOp",
				};
			};
			const nextCmd: reduxLoop.RunCmd<Action> = reduxLoop.Cmd.run(
				cancelNextPoll,
				{
					successActionCreator:
						action.status === true ? scheduleNextPollAction : noOp,
					args: [state.timerId],
				}
			);
			return [newState, nextCmd];
		}

		case "NoOp": {
			return [state, reduxLoop.Cmd.none];
		}

		default: {
			return architecture.assertExhaustiveSwitch(action, [
				state,
				reduxLoop.Cmd.none,
			]);
		}
	}
}

function configureApplication(): Promise<Config> {
	return configPersistence.load();
}

function loadDevices(
	config: Config,
	session: SessionToken
): Promise<DeviceList> {
	return Promise.all([
		scissorPersistence.getScissors(
			config.DEVICE_MANAGEMENT.SCISSORS.REST_API.SCISSOR_LIST,
			session
		),
		boatPersistence.getBoats(
			config.DEVICE_MANAGEMENT.BOATS.REST_API.BOAT_LIST,
			session
		),
		princessYachtPersistence.getPrincessYachts(
			config.DEVICE_MANAGEMENT.PRINCESS_YACHTS.REST_API.PRINCESS_YACHT_LIST,
			session
		),
	]).then(
		([scissors, boats, yachts]: [
			Array<Scissor>,
			Array<Boat>,
			Array<PrincessYacht>
		]) => [...scissors, ...boats, ...yachts]
	);
}

// POLLING HELPERS

function scheduleNextPoll(
	dispatch: architecture.Dispatch<Action>,
	config: Config,
	session: SessionToken,
	devices: DeviceList
): TimerId {
	return window.setTimeout((): void => {
		dispatch({
			type: "INITIALIZATION",
			step: "RELOAD DEVICES",
			status: "START",
			config: config,
			session: session,
			dispatch: dispatch,
			devices: devices,
		});
	}, config.APP_CONSTANTS.POLLING_INTERVAL);
}

function cancelNextPoll(timerId: Maybe<TimerId>): void {
	timerId.caseOf({
		just: (id: TimerId): void => {
			clearTimeout(id);
		},

		nothing: (): void => {},
	});
}

function onRefreshData(
	dispatch: architecture.Dispatch<Action>,
	timerId: Maybe<TimerId>,
	config: Config,
	session: SessionToken,
	devices: DeviceList
): void {
	cancelNextPoll(timerId);

	dispatch({
		type: "INITIALIZATION",
		step: "RELOAD DEVICES",
		status: "START",
		config: config,
		session: session,
		dispatch: dispatch,
		devices: devices,
	});
}

// VIEW

interface StateProps {
	state: Model;
}

interface HandlerProps {
	dispatch: architecture.Dispatch<Action>;
}

type ViewProps = StateProps & HandlerProps;

function View({ state, dispatch }: ViewProps): JSX.Element {
	const navigationViewDispatcher: architecture.Dispatch<navigationView.Action> =
		architecture.mapDispatch(
			dispatch,
			(action: navigationView.Action): Action => ({
				type: "NAVIGATION VIEW ACTION",
				viewAction: action,
			})
		);
	const isSmallScreen: boolean = window.screen.width < 768;
	if (
		state.initialization === "success" ||
		state.initialization === "reloading"
	) {
		return (
			<>
				<navigationView.View
					devices={state.devices}
					config={state.configuration}
					session={state.session}
					activePolling={state.activePolling}
					dispatch={navigationViewDispatcher}
					state={state.navigation}
					enableScreenModeSwitch={isSmallScreen}
					refreshData={(): void => {
						onRefreshData(
							dispatch,
							state.timerId,
							state.configuration,
							state.session,
							state.devices
						);
					}}
					togglePolling={(): void => {
						dispatch({
							type: "CHANGE_ACTIVE_POLLING",
							status: !state.activePolling,
							config: state.configuration,
							session: state.session,
							dispatch: dispatch,
							devices: state.devices,
						});
					}}
					initialization={state.initialization}
				/>
				{state.devices.length === 0 ? (
					<div id="error-view">
						<ErrorAlert
							title="Error: No devices were returned from the API"
							body="No devices have been detected. You can open the browser dev tools to check for errors"
						/>
					</div>
				) : null}
			</>
		);
	} else if (state.initialization === "failed") {
		if (state.error.type === "authn-error") {
			dispatch({ type: "INITIALIZATION", step: "START", dispatch: dispatch });
			return (
				<div id="error-view">
					<CircularProgress style={styles.loadingSvg} />
				</div>
			);
		} else {
			return (
				<div id="error-view">
					<ErrorAlert
						title={`Initialization Error: ${state.error.type}`}
						body={state.error.reason}
					/>
				</div>
			);
		}
	} else {
		dispatch({ type: "INITIALIZATION", step: "START", dispatch: dispatch });

		return (
			<div id="loading-view" style={styles.loadingScreen}>
				<CircularProgress style={styles.loadingSvg} />
			</div>
		);
	}
}

// REDUX BOILERPLATE

function mapStateToProps(state: Model): StateProps {
	return { state: state };
}

function mapDispatchToProps(
	dispatch: architecture.Dispatch<Action>
): HandlerProps {
	return {
		dispatch: dispatch,
	};
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const ConnectedView = reactRedux.connect(
	mapStateToProps,
	mapDispatchToProps
)(View);
