import { Overrides } from "@material-ui/core/styles/overrides";

export { overrides };

const overrides: Overrides = {
	MuiCssBaseline: {
		"@global": {
			html: {
				overflow: "hidden",
			},
			body: {
				overflow: "hidden",
			},
		},
	},
};
