import React from "react";
import { fontFamily } from "@src/presentation/styles/typography";
import { custom } from "@src/presentation/styles/palette";

export {
	loader,
	navigationButtonsWrapper,
	quickRangesList,
	quickRangesItem,
	selectedQuickRange,
	container,
	contentWrapper,
	content,
	list,
	emptyList,
	primaryText,
	listIcon,
	paginationBar,
	status,
	activeAlarmStatus,
	clearAlarmStatus,
	timeInfo,
	alarmDescription,
	highlightedText,
	appBar,
};

// Component
const wrapperPadding = 10;

// Navigation appBar
const navHeight = 48;

const navigationButtonsWrapper: React.CSSProperties = {
	display: "flex",
};

// Loader
const loader: React.CSSProperties = {
	height: "100%",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};

// Alarms list
const container: React.CSSProperties = {
	position: "relative",
	height: "100vh",
	display: "flex",
	flexDirection: "column",
};

const contentWrapper: React.CSSProperties = {
	top: navHeight,
	flexGrow: 1,
	display: "flex",
};

const content: React.CSSProperties = {
	flexGrow: 1,
	display: "flex",
	flexDirection: "column",
};

const list: React.CSSProperties = {
	padding: wrapperPadding,
	overflow: "auto",
	height: "100%",
};

const emptyList: React.CSSProperties = {
	fontFamily,
	alignItems: "center",
	textAlign: "center",
	lineHeight: "30px",
	alignSelf: "center",
	width: "100%",
};

// AlarmListItem

const status: React.CSSProperties = {
	color: custom.lightGray,
	borderRadius: 10,
	padding: "5px 10px",
	display: "inline-block",
	lineHeight: 1.1,
	verticalAlign: "text-bottom",
	fontSize: 10,
	marginRight: 8,
	width: 55,
	boxSizing: "border-box",
	textAlign: "center",
};

const activeAlarmStatus: React.CSSProperties = {
	...status,
	backgroundColor: custom.alarmRed,
};

const clearAlarmStatus: React.CSSProperties = {
	...status,
	backgroundColor: custom.alarmGray,
};

const primaryText: React.CSSProperties = {
	lineHeight: "25px",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
};

const alarmDescription: React.CSSProperties = {
	maxWidth: "60%",
};

const timeInfo: React.CSSProperties = {
	textAlign: "right",
	lineHeight: "22px",
	fontSize: 14,
};

const listIcon: React.CSSProperties = {
	top: "50%",
	margin: 0,
	padding: 12,
};

const highlightedText: React.CSSProperties = {
	color: custom.darkBlue,
};

// Pagination
const paginationBar: React.CSSProperties = {
	background: custom.lightGray,
	width: "100%",
	height: navHeight,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	boxShadow: "-2px -1px 5px -2px #ccc",
};

// QuickRanges

const quickRangesList: React.CSSProperties = {
	listStyle: "none",
	paddingLeft: 10,
	display: "flex",
	flexWrap: "wrap",
};

const quickRangesItem: React.CSSProperties = {
	width: "50%",
	cursor: "pointer",
	padding: "6px 6px 6px 0",
	fontSize: 15,
	whiteSpace: "nowrap",
	flexShrink: 0,
	boxSizing: "border-box",
	minWidth: "135px",
};

const selectedQuickRange: React.CSSProperties = {
	color: custom.darkBlue,
	fontWeight: 700,
};

const appBar: React.CSSProperties = {
	position: "relative",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
};
