export {
	wrapperStyle,
	subview,
	sideview,
	animatedSlideInOut,
	inflexible,
	canAutoGrow,
	canAutoShrink,
};

const wrapperStyle: React.CSSProperties = {
	display: "flex",
	flexDirection: "row",
	position: "fixed",
	left: 0,
	top: 0,
	right: 0,
	bottom: 0,
};

const subview: React.CSSProperties = {
	display: "inline-block",
	overflowX: "hidden",
};

const sideview: React.CSSProperties = {
	boxShadow:
		"rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px",
	zIndex: 1300,
};

const animatedSlideInOut: React.CSSProperties = {
	transition: "width 700ms cubic-bezier(.23,1,.23,1) 0ms",
};

const inflexible: React.CSSProperties = {
	flex: "0 0 auto",
};

const canAutoGrow: React.CSSProperties = {
	flexGrow: 1,
};

const canAutoShrink: React.CSSProperties = {
	flexShrink: 1,
};
