// 3rd party
import axios from "axios";
import * as axiosTypes from "axios";
import { Either } from "tsmonad";
import * as yaml from "js-yaml";
import JsonousDecoder, * as jsonous from "jsonous";
import * as miimetiqRest from "./services/miimetiq-rest";

// Domain
import { Config } from "../domain/config";

// helpers
import * as decoding from "./services/decoding";

export { load };

type decodingErr = string;

function decodePath<T extends string | boolean | number>(
	path: Array<string>,
	valueDecoder: JsonousDecoder<T>
): JsonousDecoder<T> {
	return jsonous
		.at([...path], valueDecoder)
		.orElse((error: string) =>
			jsonous.fail(`Cannot decode property ${path.join(".")}. ${error}`)
		);
}

const nonEmptyStringDecoder: JsonousDecoder<string> = jsonous.string.andThen(
	(value: string): JsonousDecoder<string> => {
		if (value.length > 0) {
			return jsonous.succeed(value);
		} else {
			const errorMsg = `Cannot be an empty string`;
			return jsonous.fail(errorMsg);
		}
	}
);

// prettier-ignore
function decodeConfig(config: unknown): Either<string, Config> {
	const decoder: JsonousDecoder<Config> =
		decodePath(["IAM", "LOGIN", "URL"], nonEmptyStringDecoder).andThen((loginUrl: string) =>
		decodePath(["IAM", "LOGOUT", "URL"], nonEmptyStringDecoder).andThen((logoutUrl: string) =>
		// tslint:disable:max-line-length
		decodePath(["IAM", "SESSION", "INFO", "URL"], nonEmptyStringDecoder).andThen((sessionInfoUrl: string) =>
		decodePath(["IAM", "SESSION", "COOKIE_NAME"], nonEmptyStringDecoder).andThen((sessionCookieName: string) =>
		decodePath(["USER_MANAGEMENT", "URL"], nonEmptyStringDecoder).andThen((userManagementUrl: string) =>
		decodePath(["DEVICE_MANAGEMENT", "ALL", "REST_API", "DEVICE_LIST"], nonEmptyStringDecoder).andThen((deviceList: string) =>

		decodePath(["DEVICE_MANAGEMENT", "SCISSORS", "REST_API", "SCISSOR_LIST"], nonEmptyStringDecoder).andThen((scissorsList: string) =>
		decodePath(["DEVICE_MANAGEMENT", "SCISSORS", "REST_API", "SEND_POWER_ACTUATOR"], nonEmptyStringDecoder).andThen((scissorPowerActuator: string) =>

		decodePath(["DEVICE_MANAGEMENT", "BOATS", "REST_API", "BOAT_LIST"], nonEmptyStringDecoder).andThen((boatsList: string) =>
		decodePath(["DEVICE_MANAGEMENT", "BOATS", "REST_API", "SEND_POWER_ACTUATOR"], nonEmptyStringDecoder).andThen((boatPowerActuator: string) =>
		decodePath(["DEVICE_MANAGEMENT", "BOATS", "REST_API", "SEND_LOCK_ACTUATOR"], nonEmptyStringDecoder).andThen((boatLockActuator: string) =>

		decodePath(["DEVICE_MANAGEMENT", "PRINCESS_YACHTS", "REST_API", "PRINCESS_YACHT_LIST"], nonEmptyStringDecoder).andThen((yachtList: string) =>
		decodePath(["DEVICE_MANAGEMENT", "PRINCESS_YACHTS", "REST_API", "SEND_POWER_ACTUATOR"], nonEmptyStringDecoder).andThen((yachtPowerActuator: string) =>
		decodePath(["DEVICE_MANAGEMENT", "PRINCESS_YACHTS", "REST_API", "SEND_LOCK_ACTUATOR"], nonEmptyStringDecoder).andThen((yachtLockActuator: string) =>

		decodePath(["HISTORICS_VIEW", "SCISSORS", "URL"], nonEmptyStringDecoder).andThen((scissorHistoricUrl: string) =>
		decodePath(["HISTORICS_VIEW", "BOATS", "URL"], nonEmptyStringDecoder).andThen((boatHistoricUrl: string) =>
		decodePath(["HISTORICS_VIEW", "PRINCESS_YACHTS", "URL"], nonEmptyStringDecoder).andThen((yachtHistoricUrl: string) =>

		decodePath(["ALARMS", "REST_API", "URL"], nonEmptyStringDecoder).andThen((alarmsUrl: string) =>

		decodePath(["FEATURE_TOGGLES", "ALARMS_PANEL"], jsonous.boolean).andThen((enableAlarmPanel: boolean) =>
		decodePath(["FEATURE_TOGGLES", "GEOFENCE", "SCISSORS"], jsonous.boolean).andThen((enableGeofenceForScissors: boolean) =>
		decodePath(["FEATURE_TOGGLES", "GEOFENCE", "BOATS"], jsonous.boolean).andThen((enableGeofenceForBoats: boolean) =>
		decodePath(["FEATURE_TOGGLES", "GEOFENCE", "PRINCESS_YACHTS"], jsonous.boolean).andThen((enableGeofenceForYachts: boolean) =>

		decodePath(["APP_CONSTANTS", "POLLING_INTERVAL"], jsonous.number).andThen((pollingInterval: number) =>
		// tslint:enable:max-line-length
		jsonous.succeed<Config>(
			{
				IAM: {
					LOGIN: {
						URL: loginUrl,
					},
					LOGOUT: {
						URL: logoutUrl,
					},
					SESSION: {
						INFO: {
							URL: sessionInfoUrl,
						},
						COOKIE_NAME: sessionCookieName,
					},
				},
				USER_MANAGEMENT: {
					URL: userManagementUrl,
				},
				DEVICE_MANAGEMENT: {
					ALL: {
						REST_API: {
							DEVICE_LIST: deviceList,
						},
					},
					SCISSORS: {
						REST_API: {
							SCISSOR_LIST: scissorsList,
							SEND_POWER_ACTUATOR: scissorPowerActuator,
						},
					},
					BOATS: {
						REST_API: {
							BOAT_LIST: boatsList,
							SEND_POWER_ACTUATOR: boatPowerActuator,
							SEND_LOCK_ACTUATOR: boatLockActuator,
						},
					},
					PRINCESS_YACHTS: {
						REST_API: {
							PRINCESS_YACHT_LIST: yachtList,
							SEND_POWER_ACTUATOR: yachtPowerActuator,
							SEND_LOCK_ACTUATOR: yachtLockActuator,
						},
					},
				},
				HISTORICS_VIEW: {
					SCISSORS: {
						URL: scissorHistoricUrl,
					},
					BOATS: {
						URL: boatHistoricUrl,
					},
					PRINCESS_YACHTS: {
						URL: yachtHistoricUrl,
					}
				},
				ALARMS: {
					REST_API: {
						URL: alarmsUrl,
					},
				},
				FEATURE_TOGGLES: {
					ALARMS_PANEL: enableAlarmPanel,
					GEOFENCE: {
						SCISSORS: enableGeofenceForScissors,
						BOATS: enableGeofenceForBoats,
						PRINCESS_YACHTS: enableGeofenceForYachts
					}
				},
				APP_CONSTANTS: {
					POLLING_INTERVAL: pollingInterval,
				}
			}
		))))))))))))))))))))))));
	return decoding.applyJsonousDecoder(
		decoder,
		{
			failure: (error: string): string => `Cannot validate configuration. ${error}`,
			success: (result: Config): Config => result
		},
		config
	);
}

function load(): Promise<Config> {
	return axios
		.get("/config.yaml")
		.catch(miimetiqRest.parseAxiosError)
		.then((res: axiosTypes.AxiosResponse<string>): Promise<unknown> => {
			try {
				const doc = yaml.load(res.data);
				return Promise.resolve(doc);
			} catch (err) {
				return Promise.reject(err);
			}
		})
		.then(decodeConfig)
		.then(
			(result: Either<decodingErr, Config>): Promise<Config> =>
				result.caseOf({
					left: (error: decodingErr): Promise<Config> => Promise.reject(error),
					right: (config: Config): Promise<Config> => Promise.resolve(config),
				})
		);
}
