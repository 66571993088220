export {
	Scissor,
	Geofence,
	Sensor,
	AlarmSensor,
	SensorValue,
	PowerStatus,
	Power,
	Actuator,
	Angle,
	WindSpeedAtTop,
	CurrentPosition,
	EngineTime,
	OilTemp,
	DistanceFromObstacle,
	WindSpeedForecast,
	AmbientTemp,
	TyrePressure,
	WindDirectionForecast,
	FuelLevel,
	StopTime,
	TotalFuelUsed,
	AlarmDistanceFromObstacle,
	AlarmAngle,
	AlarmOilTemp,
	AlarmWindSpeedAtTop,
	AlarmEngineTime,
	AlarmCurrentPosition,
	AlarmWindSpeedForecast,
	AlarmTyrePressure,
	AlarmFuelLevel,
	updateSensor,
	isAlarmActive,
	hasActiveAlarms,
};

interface Scissor {
	kind: "Scissor";
	id: string;
	name: string;
	geofence: Geofence;
	Power: Power;
	PowerStatus: PowerStatus;
	Angle: Angle;
	WindSpeedAtTop: WindSpeedAtTop;
	CurrentPosition: CurrentPosition;
	EngineTime: EngineTime;
	OilTemp: OilTemp;
	DistanceFromObstacle: DistanceFromObstacle;
	WindSpeedForecast: WindSpeedForecast;
	AmbientTemp: AmbientTemp;
	TyrePressure: TyrePressure;
	WindDirectionForecast: WindDirectionForecast;
	FuelLevel: FuelLevel;
	StopTime: StopTime;
	TotalFuelUsed: TotalFuelUsed;
	AlarmDistanceFromObstacle: AlarmDistanceFromObstacle;
	AlarmAngle: AlarmAngle;
	AlarmOilTemp: AlarmOilTemp;
	AlarmWindSpeedAtTop: AlarmWindSpeedAtTop;
	AlarmEngineTime: AlarmEngineTime;
	AlarmCurrentPosition: AlarmCurrentPosition;
	AlarmWindSpeedForecast: AlarmWindSpeedForecast;
	AlarmTyrePressure: AlarmTyrePressure;
	AlarmFuelLevel: AlarmFuelLevel;
}

type GeoPoint = [number, number];

interface Geofence {
	center: GeoPoint;
	outerRadius: number;
	innerRadius: number;
}

function updateSensor(scissor: Scissor, sensor: Sensor): Scissor {
	switch (sensor.name) {
		case "Power Status": {
			return {
				...scissor,
				PowerStatus: sensor,
			};
		}

		case "Angle": {
			return {
				...scissor,
				Angle: sensor,
			};
		}

		case "Wind Speed At Top": {
			return {
				...scissor,
				WindSpeedAtTop: sensor,
			};
		}

		case "Current Position": {
			return {
				...scissor,
				CurrentPosition: sensor,
			};
		}

		case "Engine Time": {
			return {
				...scissor,
				EngineTime: sensor,
			};
		}

		case "Oil Temp": {
			return {
				...scissor,
				OilTemp: sensor,
			};
		}

		case "Distance From Obstacle": {
			return {
				...scissor,
				DistanceFromObstacle: sensor,
			};
		}

		case "Wind Speed Forecast": {
			return {
				...scissor,
				WindSpeedForecast: sensor,
			};
		}

		case "Ambient Temp": {
			return {
				...scissor,
				AmbientTemp: sensor,
			};
		}

		case "Tyre Pressure": {
			return {
				...scissor,
				TyrePressure: sensor,
			};
		}

		case "Wind Direction Forecast": {
			return {
				...scissor,
				WindDirectionForecast: sensor,
			};
		}

		case "Fuel Level": {
			return {
				...scissor,
				FuelLevel: sensor,
			};
		}

		case "Stop Time": {
			return {
				...scissor,
				StopTime: sensor,
			};
		}

		case "Total Fuel Used": {
			return {
				...scissor,
				TotalFuelUsed: sensor,
			};
		}

		case "AlarmDistanceFromObstacle": {
			return {
				...scissor,
				AlarmDistanceFromObstacle: sensor,
			};
		}

		case "AlarmAngle": {
			return {
				...scissor,
				AlarmAngle: sensor,
			};
		}

		case "AlarmOilTemp": {
			return {
				...scissor,
				AlarmOilTemp: sensor,
			};
		}

		case "AlarmWindSpeedAtTop": {
			return {
				...scissor,
				AlarmWindSpeedAtTop: sensor,
			};
		}

		case "AlarmEngineTime": {
			return {
				...scissor,
				AlarmEngineTime: sensor,
			};
		}

		case "AlarmCurrentPosition": {
			return {
				...scissor,
				AlarmCurrentPosition: sensor,
			};
		}

		case "AlarmWindSpeedForecast": {
			return {
				...scissor,
				AlarmWindSpeedForecast: sensor,
			};
		}

		case "AlarmTyrePressure": {
			return {
				...scissor,
				AlarmTyrePressure: sensor,
			};
		}

		case "AlarmFuelLevel": {
			return {
				...scissor,
				AlarmFuelLevel: sensor,
			};
		}
	}
}

function isAlarmActive(alarm: AlarmSensor): boolean {
	return alarm.value !== null;
}

function hasActiveAlarms(scissor: Scissor): boolean {
	// Note that currently this function is not including AlarmCurrentPosition
	const alarms: Array<AlarmSensor> = [
		scissor.AlarmTyrePressure,
		scissor.AlarmDistanceFromObstacle,
		scissor.AlarmAngle,
		scissor.AlarmOilTemp,
		scissor.AlarmEngineTime,
		scissor.AlarmFuelLevel,
		scissor.AlarmWindSpeedAtTop,
		scissor.AlarmWindSpeedForecast,
	];
	return alarms.some(isAlarmActive);
}

type Sensor =
	| AlarmSensor
	| PowerStatus
	| Angle
	| WindSpeedAtTop
	| CurrentPosition
	| EngineTime
	| OilTemp
	| DistanceFromObstacle
	| WindSpeedForecast
	| AmbientTemp
	| TyrePressure
	| WindDirectionForecast
	| FuelLevel
	| StopTime
	| TotalFuelUsed;

type AlarmSensor =
	| AlarmDistanceFromObstacle
	| AlarmAngle
	| AlarmOilTemp
	| AlarmWindSpeedAtTop
	| AlarmEngineTime
	| AlarmCurrentPosition
	| AlarmWindSpeedForecast
	| AlarmTyrePressure
	| AlarmFuelLevel;

type Actuator = Power;

type SensorValue = boolean | number | GeoPoint | null;

interface Power {
	name: "Power";
	value: boolean | null;
	trueLiteral: "ON";
	falseLiteral: "OFF";
}

interface PowerStatus {
	name: "Power Status";
	value: boolean | null;
}

interface Angle {
	name: "Angle";
	value: number | null;
	unit: "º";
	icon: "Angle";
}

interface AlarmAngle {
	value: number | null;
	name: "AlarmAngle";
	label: "Angle";
	unit: "º";
}

interface WindSpeedAtTop {
	name: "Wind Speed At Top";
	value: number | null;
	unit: "m/s";
	icon: "WindSpeedAtTop";
}

interface AlarmWindSpeedAtTop {
	name: "AlarmWindSpeedAtTop";
	label: "Wind Speed At Top";
	value: number | null;
	unit: "m/s";
}

interface CurrentPosition {
	name: "Current Position";
	value: GeoPoint;
	icon: "CurrentPosition";
}

interface AlarmCurrentPosition {
	name: "AlarmCurrentPosition";
	label: "Current Position";
	value: GeoPoint | null;
}

interface EngineTime {
	name: "Engine Time";
	value: number | null;
	unit: "hour";
	icon: "EngineTime";
}

interface AlarmEngineTime {
	name: "AlarmEngineTime";
	label: "Engine Time";
	value: number | null;
	unit: "hour";
}

interface OilTemp {
	name: "Oil Temp";
	value: number | null;
	unit: "ºC";
	icon: "OilTemp";
}

interface AlarmOilTemp {
	name: "AlarmOilTemp";
	label: "Oil Temp";
	value: number | null;
	unit: "ºC";
}

interface DistanceFromObstacle {
	name: "Distance From Obstacle";
	value: number | null;
	unit: "metres";
	icon: "DistanceFromObstacle";
}

interface AlarmDistanceFromObstacle {
	value: number | null;
	name: "AlarmDistanceFromObstacle";
	label: "Distance From Obstacle";
	unit: "metres";
}

interface WindSpeedForecast {
	name: "Wind Speed Forecast";
	value: number | null;
	unit: "m/s";
	icon: "WindSpeedForecast";
}

interface AlarmWindSpeedForecast {
	name: "AlarmWindSpeedForecast";
	label: "Wind Speed Forecast";
	value: number | null;
	unit: "m/s";
}

interface AmbientTemp {
	name: "Ambient Temp";
	value: number | null;
	unit: "ºC";
	icon: "AmbientTemp";
}

interface TyrePressure {
	name: "Tyre Pressure";
	value: number | null;
	unit: "psi";
	icon: "TyrePressure";
}

interface AlarmTyrePressure {
	name: "AlarmTyrePressure";
	label: "Tyre Pressure";
	value: number | null;
	unit: "psi";
}

interface WindDirectionForecast {
	name: "Wind Direction Forecast";
	value: number | null;
	icon: "WindDirectionForecast";
}

interface FuelLevel {
	name: "Fuel Level";
	value: number | null;
	unit: "%";
	icon: "FuelLevel";
}

interface AlarmFuelLevel {
	name: "AlarmFuelLevel";
	label: "Fuel Level";
	value: number | null;
	unit: "%";
}

interface StopTime {
	name: "Stop Time";
	value: number | null;
	unit: "hour";
	icon: "StopTime";
}

interface TotalFuelUsed {
	name: "Total Fuel Used";
	value: number | null;
	unit: "L";
	icon: "TotalFuelUsed";
}
