// 3rd party
import * as React from "react";
import * as reduxLoop from "redux-loop";
import { Maybe } from "tsmonad";
import { AppBar } from "@material-ui/core";
import { SvgLoader } from "react-svgmt";
import { Clear as ClearIcon } from "@material-ui/icons";

// Domain
import { Scissor } from "@src/domain/scissor";
import { Boat } from "@src/domain/boat";
import { PrincessYacht } from "@src/domain/princess-yacht";
import { Config } from "@src/domain/config";

import { SessionToken } from "@src/persistence/session";

// Toolkits
import * as architecture from "@src/presentation/toolkit/architecture";

// Styles
import * as styles from "./styles";

// Components
import * as detailsView from "@src/presentation/views/details";
import * as fleetView from "@src/presentation/views/fleet";
import * as alarmPanelView from "@src/presentation/views/alarm-panel";

import * as openAlarmsIcon from "./assets/open-alarms.svg";

export { View, Model, Action, reducer, init };

type Device = Scissor | Boat | PrincessYacht;
type DeviceList = Array<Device>;

// MODEL
interface Model {
	fleet: fleetView.Model;
	details: Maybe<DetailsState>;
	alarmPanel: Maybe<AlarmsPanelState>;
}

type DetailsState = {
	state: detailsView.Model;
	deviceName: string;
	sizeMode: "maximized" | "normal";
};

type AlarmsPanelState = {
	state: alarmPanelView.Model;
	sizeMode: "maximized" | "normal";
};

type Action =
	| {
			type: "DETAILS VIEW ACTION";
			viewAction: detailsView.Action;
	  }
	| {
			type: "FLEET VIEW ACTION";
			viewAction: fleetView.Action;
	  }
	| {
			type: "ALARM PANEL VIEW ACTION";
			viewAction: alarmPanelView.Action;
	  }
	| {
			type: "SHOW DETAILS VIEW";
			device: Device;
	  }
	| {
			type: "MAXIMIZE DETAILS VIEW";
	  }
	| {
			type: "NORMALIZE SCREEN DETAILS VIEW";
	  }
	| {
			type: "CLOSE DETAILS VIEW";
	  }
	| {
			type: "SHOW ALARMS VIEW";
	  }
	| {
			type: "MAXIMIZE ALARMS VIEW";
	  }
	| {
			type: "CLOSE ALARMS VIEW";
	  }
	| {
			type: "NORMALIZE SCREEN ALARMS VIEW";
	  }
	| {
			type: "TRIGGER FLEET MAP SIZE RECALCULATION";
	  };

const init: Model = {
	fleet: fleetView.init,
	details: Maybe.nothing<DetailsState>(),
	alarmPanel: Maybe.nothing<AlarmsPanelState>(),
};

// UPDATE

function reducer(state: Model, action: Action): [Model, reduxLoop.CmdType] {
	switch (action.type) {
		case "DETAILS VIEW ACTION": {
			// This Action is used to plug the reducer of the details view with this reducer.
			// This way, the detailsView can manage its own state with its own reducer, and nobody
			// else needs to know about it (encapsulation!)
			// Whenever the detailsView triggers an Cmd of Actions its own, we map it
			// to this "DETAILS VIEW ACTION" action (see step 4 --). And when the Cmd is finished,
			// We forward the action up to the reducer of detailsView.

			// 1 -- If an action is for this view but we don't have state for this view, we are fucked!
			// DV == DetailsView
			const DVState: DetailsState = state.details.valueOrThrow(
				new Error("RUNTIME ERROR! MISSING STATE FROM DETAILS VIEW")
			);

			// 2 -- Call reducer of the view, and get the new view state and a possible Cmd to execute
			const [DVNewState, DVNewCmd]: [detailsView.Model, reduxLoop.CmdType] =
				detailsView.reducer(DVState.state, action.viewAction);

			// 3 -- Update the state of this module, replacing the old state of the view with the new one
			const newState: Model = {
				...state,
				details: Maybe.just<DetailsState>({
					...DVState,
					state: DVNewState,
				}),
			};

			// 4 -- Map, (ie convert) the Cmd of the view to a Cmd of this module

			// A Cmd is a monad. It wraps an async operation and a pair of Action that need to be triggered
			// when the async op. finishes. One of the actions is for the case where the op succedes,
			// the other action is for when the op fails.

			// Why do we need to map the DVCmd to newCmd?
			// because DVCmd is of type CmdType<detailsView.Action>. This means that the actions it carries
			// are understood by the reducer in detailsView. But as that reducer is nested inside the
			// reducer in this module, we need to convert the Actions inside the Cmd to the type of
			// Actions that the reducer in this module can understand.
			// In the map, we give a function that takes an detailsView.Action and returns an Action
			// from this module. The Action that we return is the "DETAILS VIEW ACTION", which
			// is the one used to forward the DVActions to detailsView.reducer. This way, we close the
			// circle.
			const newCmd: reduxLoop.CmdType = reduxLoop.Cmd.map(
				DVNewCmd,
				(DVAction: detailsView.Action): Action => {
					return { type: "DETAILS VIEW ACTION", viewAction: DVAction };
				}
			);

			// 5 -- Return the new state and any new possible Cmd.
			return [newState, newCmd];
		}

		case "FLEET VIEW ACTION": {
			const [FVNewState, FVNewCmd]: [fleetView.Model, reduxLoop.CmdType] =
				fleetView.reducer(state.fleet, action.viewAction);
			const newState: Model = {
				...state,
				fleet: FVNewState,
			};
			const newCmd: reduxLoop.CmdType = reduxLoop.Cmd.map(
				FVNewCmd,
				(FVAction: fleetView.Action): Action => {
					return { type: "FLEET VIEW ACTION", viewAction: FVAction };
				}
			);
			return [newState, newCmd];
		}

		case "ALARM PANEL VIEW ACTION": {
			const APVState: AlarmsPanelState = state.alarmPanel.valueOrThrow(
				new Error("RUNTIME ERROR! MISSING STATE FROM ALARM PANEL VIEW")
			);
			const [APNewState, APVNewCmd]: [alarmPanelView.Model, reduxLoop.CmdType] =
				alarmPanelView.reducer(APVState.state, action.viewAction);
			const newState: Model = {
				...state,
				alarmPanel: Maybe.just<AlarmsPanelState>({
					...APVState,
					state: APNewState,
				}),
			};
			const newCmd: reduxLoop.CmdType = reduxLoop.Cmd.map(
				APVNewCmd,
				(APAction: alarmPanelView.Action): Action => {
					return { type: "ALARM PANEL VIEW ACTION", viewAction: APAction };
				}
			);
			return [newState, newCmd];
		}

		case "SHOW DETAILS VIEW": {
			const newState: Model = {
				...state,
				details: state.details.caseOf({
					just: (detailsState: DetailsState): Maybe<DetailsState> => {
						return Maybe.just({
							...detailsState,
							deviceName: action.device.name,
						});
					},
					nothing: (): Maybe<DetailsState> => {
						return Maybe.just<DetailsState>({
							state: detailsView.init,
							deviceName: action.device.name,
							sizeMode: "normal",
						});
					},
				}),
				alarmPanel: Maybe.nothing<AlarmsPanelState>(),
			};
			return [newState, reduxLoop.Cmd.none];
		}

		case "MAXIMIZE DETAILS VIEW": {
			const newState: Model = {
				...state,
				details: state.details.map(
					(detailsState: DetailsState): DetailsState => {
						return {
							...detailsState,
							sizeMode: "maximized",
						};
					}
				),
			};
			return [newState, reduxLoop.Cmd.none];
		}

		case "NORMALIZE SCREEN DETAILS VIEW": {
			const newState: Model = {
				...state,
				details: state.details.map(
					(detailsState: DetailsState): DetailsState => {
						return {
							...detailsState,
							sizeMode: "normal",
						};
					}
				),
			};
			return [newState, reduxLoop.Cmd.none];
		}

		case "CLOSE DETAILS VIEW": {
			const newState: Model = {
				...state,
				details: Maybe.nothing<DetailsState>(),
			};
			return [newState, reduxLoop.Cmd.none];
		}

		case "SHOW ALARMS VIEW": {
			const newState: Model = {
				...state,
				alarmPanel: state.alarmPanel.caseOf({
					just: (alarmsState: AlarmsPanelState): Maybe<AlarmsPanelState> => {
						return Maybe.just<AlarmsPanelState>({
							state: alarmsState.state,
							sizeMode: "normal",
						});
					},
					nothing: (): Maybe<AlarmsPanelState> => {
						return Maybe.just<AlarmsPanelState>({
							state: alarmPanelView.init,
							sizeMode: "normal",
						});
					},
				}),
				details: Maybe.nothing<DetailsState>(),
			};
			return [newState, reduxLoop.Cmd.none];
		}
		case "MAXIMIZE ALARMS VIEW": {
			const newState: Model = {
				...state,
				alarmPanel: state.alarmPanel.map(
					(alarmsState: AlarmsPanelState): AlarmsPanelState => {
						return {
							...alarmsState,
							sizeMode: "maximized",
						};
					}
				),
			};
			return [newState, reduxLoop.Cmd.none];
		}
		case "NORMALIZE SCREEN ALARMS VIEW": {
			const newState: Model = {
				...state,
				alarmPanel: state.alarmPanel.map(
					(alarmsState: AlarmsPanelState): AlarmsPanelState => {
						return {
							...alarmsState,
							sizeMode: "normal",
						};
					}
				),
			};
			return [newState, reduxLoop.Cmd.none];
		}
		case "CLOSE ALARMS VIEW": {
			const newState: Model = {
				...state,
				alarmPanel: Maybe.nothing<AlarmsPanelState>(),
			};
			return [newState, reduxLoop.Cmd.none];
		}

		case "TRIGGER FLEET MAP SIZE RECALCULATION": {
			return [
				{ ...state, fleet: fleetView.recalculateSize(state.fleet) },
				reduxLoop.Cmd.none,
			];
		}
	}
}

// HANDLERS

function onShowAlarms(dispatch: architecture.Dispatch<Action>): void {
	dispatch({ type: "SHOW ALARMS VIEW" });
}

function onCloseAlarms(dispatch: architecture.Dispatch<Action>): void {
	dispatch({ type: "CLOSE ALARMS VIEW" });
}

function onSideViewVisibilityTransitionEnd(
	dispatch: architecture.Dispatch<Action>,
	visibility: DetailsVisibility | AlarmPanelVisibility
): void {
	if (visibility !== "maximized") {
		dispatch({ type: "TRIGGER FLEET MAP SIZE RECALCULATION" });
	}
}

// VIEW

type DetailsVisibility = "hidden" | "normal" | "maximized";
type AlarmPanelVisibility = "hidden" | "normal" | "maximized";

interface StateProps {
	state: Model;
	config: Config;
	session: SessionToken;
	activePolling: boolean;
	devices: DeviceList;
	enableScreenModeSwitch: boolean;
	initialization: "success" | "reloading";
}

interface HandlerProps {
	dispatch: architecture.Dispatch<Action>;
	refreshData: () => void;
	togglePolling: () => void;
}

type ViewProps = StateProps & HandlerProps;

function View({
	state,
	dispatch,
	config,
	session,
	activePolling,
	devices,
	enableScreenModeSwitch,
	refreshData,
	initialization,
	togglePolling,
}: ViewProps): JSX.Element {
	const areDetailsShown: boolean = Maybe.isJust(state.details);
	const areDetailsMaximized: boolean = state.details.caseOf<boolean>({
		just: (detailsState: DetailsState): boolean =>
			detailsState.sizeMode === "maximized",
		nothing: (): boolean => false,
	});
	const areAlarmsShown: boolean = Maybe.isJust(state.alarmPanel);
	const areAlarmsMaximized: boolean = state.alarmPanel.caseOf<boolean>({
		just: (alarmsState: AlarmsPanelState): boolean =>
			alarmsState.sizeMode === "maximized",
		nothing: (): boolean => false,
	});
	const detailsVisibility: DetailsVisibility = areDetailsShown
		? enableScreenModeSwitch
			? "maximized"
			: areDetailsMaximized
			? "maximized"
			: "normal"
		: "hidden";
	const alarmsVisibility: AlarmPanelVisibility = areAlarmsShown
		? enableScreenModeSwitch
			? "maximized"
			: areAlarmsMaximized
			? "maximized"
			: "normal"
		: "hidden";
	// TODO remove this constants when alarms panel feature ready
	const alarmViewEnabled = config.FEATURE_TOGGLES.ALARMS_PANEL;
	const actionButtonEnabled = alarmViewEnabled;
	return (
		<div id="navigation-view" style={styles.wrapperStyle}>
			{DetailsView(
				config,
				session,
				onSideViewVisibilityTransitionEnd,
				dispatch,
				detailsVisibility,
				enableScreenModeSwitch,
				devices,
				state.details
			)}
			{FleetView(
				config,
				activePolling,
				actionButtonEnabled,
				dispatch,
				devices,
				areAlarmsShown,
				state.fleet,
				refreshData,
				togglePolling,
				initialization
			)}
			{alarmViewEnabled &&
				AlarmPanelView(
					dispatch,
					config,
					session,
					onSideViewVisibilityTransitionEnd,
					alarmsVisibility,
					enableScreenModeSwitch,
					state.alarmPanel
				)}
		</div>
	);
}

function AlarmPanelView(
	dispatch: architecture.Dispatch<Action>,
	config: Config,
	session: SessionToken,
	onVisibilityTransitionEnd: (
		dispatch: architecture.Dispatch<Action>,
		visibility: DetailsVisibility
	) => void,
	visibility: AlarmPanelVisibility,
	enableScreenModeSwitch: boolean,
	alarmsState: Maybe<AlarmsPanelState>
): JSX.Element {
	const alarmsDispatcher = architecture.mapDispatch<
		alarmPanelView.Action,
		Action
	>(
		dispatch,
		(action: alarmPanelView.Action): Action => ({
			type: "ALARM PANEL VIEW ACTION",
			viewAction: action,
		})
	);

	const viewWidth: string = ((): string => {
		switch (visibility) {
			case "hidden": {
				return "0%";
			}
			case "normal": {
				return "60%";
			}
			case "maximized": {
				return "100%";
			}
		}
	})();
	const containerStyle: React.CSSProperties = {
		...styles.subview,
		...styles.sideview,
		...styles.animatedSlideInOut,
		...styles.inflexible,
		width: viewWidth,
	};
	return (
		<div
			id="alarm-panel-view-container"
			style={containerStyle}
			onTransitionEnd={(e: React.TransitionEvent<HTMLDivElement>): void => {
				if (e.propertyName === "width") {
					onVisibilityTransitionEnd(dispatch, visibility);
				}
			}}
		>
			{alarmsState.caseOf<JSX.Element>({
				just: (state: AlarmsPanelState): JSX.Element => {
					return (
						<alarmPanelView.View
							maximized={visibility === "maximized"}
							state={state.state}
							appConfig={config}
							session={session}
							dispatch={alarmsDispatcher}
							enableScreenModeSwitch={enableScreenModeSwitch}
							onCloseClicked={(): void => {
								onCloseAlarms(dispatch);
							}}
							onMaximizeClicked={(): void => {
								dispatch({ type: "MAXIMIZE ALARMS VIEW" });
							}}
							onNormalScreenClicked={(): void => {
								dispatch({ type: "NORMALIZE SCREEN ALARMS VIEW" });
							}}
						/>
					);
				},
				nothing: (): JSX.Element => <span id="alarm-panel-view-placeholder" />,
			})}
		</div>
	);
}

function FleetView(
	config: Config,
	activePolling: boolean,
	actionButtonEnabled: boolean,
	dispatch: architecture.Dispatch<Action>,
	devices: DeviceList,
	areAlarmsShown: boolean,
	fleetState: fleetView.Model,
	refreshData: () => void,
	togglePolling: () => void,
	initialization: "success" | "reloading"
): JSX.Element {
	const containerStyle: React.CSSProperties = {
		...styles.subview,
		...styles.canAutoGrow,
		...styles.canAutoShrink,
	};

	const actionButtonIcon: JSX.Element = areAlarmsShown ? (
		<ClearIcon />
	) : (
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		<SvgLoader path={openAlarmsIcon} width="30" height="56" />
	);

	const fleetDispatch = architecture.mapDispatch<fleetView.Action, Action>(
		dispatch,
		(action: fleetView.Action): Action => ({
			type: "FLEET VIEW ACTION",
			viewAction: action,
		})
	);

	return (
		<div id="fleet-view-container" style={containerStyle}>
			<fleetView.View
				config={config}
				activePolling={activePolling}
				state={fleetState}
				devices={devices}
				dispatch={fleetDispatch}
				actionButtonEnabled={actionButtonEnabled}
				actionButtonIcon={actionButtonIcon}
				onActionButtonClick={(): void => {
					if (areAlarmsShown) {
						onCloseAlarms(dispatch);
					} else {
						onShowAlarms(dispatch);
					}
				}}
				onShowDetails={(device: Device): void => {
					dispatch({ type: "SHOW DETAILS VIEW", device });
				}}
				refreshData={refreshData}
				togglePolling={togglePolling}
				initialization={initialization}
			/>
		</div>
	);
}

function DetailsView(
	config: Config,
	session: SessionToken,
	onVisibilityTransitionEnd: (
		dispatch: architecture.Dispatch<Action>,
		visibility: DetailsVisibility
	) => void,
	dispatch: architecture.Dispatch<Action>,
	visibility: DetailsVisibility,
	enableScreenModeSwitch: boolean,
	devices: DeviceList,
	state: Maybe<DetailsState>
): JSX.Element {
	const stateAndDevice = state.bind(
		(detailsState: DetailsState): Maybe<[detailsView.Model, Device]> => {
			const deviceMatched: Device | undefined = devices.find(
				(possibleDevice: Device) =>
					possibleDevice.name === detailsState.deviceName
			);
			return deviceMatched === undefined
				? Maybe.nothing<[detailsView.Model, Device]>()
				: Maybe.just<[detailsView.Model, Device]>([
						detailsState.state,
						deviceMatched,
				  ]);
		}
	);

	const detailsDispatch = architecture.mapDispatch<detailsView.Action, Action>(
		dispatch,
		(action: detailsView.Action): Action => ({
			type: "DETAILS VIEW ACTION",
			viewAction: action,
		})
	);
	const DetailsInstance = stateAndDevice.caseOf<JSX.Element>({
		just: ([detailsState, detailedDevice]: [
			detailsView.Model,
			Device
		]): JSX.Element => {
			return (
				<detailsView.View
					config={config}
					session={session}
					state={detailsState}
					maximized={visibility === "maximized"}
					device={detailedDevice}
					dispatch={detailsDispatch}
					enableScreenModeSwitch={enableScreenModeSwitch}
					onCloseClicked={(): void => {
						dispatch({ type: "CLOSE DETAILS VIEW" });
					}}
					onMaximizeClicked={(): void => {
						dispatch({ type: "MAXIMIZE DETAILS VIEW" });
					}}
					onNormalScreenClicked={(): void => {
						dispatch({ type: "NORMALIZE SCREEN DETAILS VIEW" });
					}}
				/>
			);
		},
		nothing: (): JSX.Element => (
			<div id="details-view-placeholder">
				<AppBar />
			</div>
		),
	});
	const viewWidth: string = ((): string => {
		switch (visibility) {
			case "hidden": {
				return "0%";
			}
			case "maximized": {
				return "100%";
			}
			case "normal": {
				return "60%";
			}
		}
	})();
	const containerStyle: React.CSSProperties = {
		...styles.subview,
		...styles.sideview,
		...styles.animatedSlideInOut,
		...styles.inflexible,
		width: viewWidth,
	};
	return (
		<div
			id="details-view-container"
			style={containerStyle}
			onTransitionEnd={(e: React.TransitionEvent<HTMLDivElement>): void => {
				if (e.propertyName === "width") {
					onVisibilityTransitionEnd(dispatch, visibility);
				}
			}}
		>
			{DetailsInstance}
		</div>
	);
}
