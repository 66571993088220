// 3rd party
import * as React from "react";
import * as leaflet from "leaflet";
import * as reactLeaflet from "react-leaflet";
import * as reduxLoop from "redux-loop";
import { Maybe } from "tsmonad";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
	CircularProgress,
	Button,
	Fab,
	Switch,
	FormControlLabel,
	TextField,
} from "@material-ui/core";
import { Refresh as RefreshIcon } from "@material-ui/icons";

// Domain
import * as scissorDomain from "@src/domain/scissor";
import { Scissor } from "@src/domain/scissor";
import * as boatDomain from "@src/domain/boat";
import { Boat } from "@src/domain/boat";
import { PrincessYacht } from "@src/domain/princess-yacht";
import * as princessYachtDomain from "@src/domain/princess-yacht";
import { Config } from "@src/domain/config";

// Toolkits
import * as architecture from "@src/presentation/toolkit/architecture";
import * as toolkit from "./toolkit";

// Styles
import * as styles from "./styles";
import { GeofenceColors } from "@src/presentation/styles/palette";

// Components
import * as popupComponent from "./components/popup";
import * as markerComponent from "./components/marker";

export { Model, init, recalculateSize, Action, reducer, View };

type Device = Scissor | Boat | PrincessYacht;

// MODEL

interface Geofence {
	color: GeofenceColors;
	bounds: leaflet.LatLngBounds;
}

interface Model {
	locateFilter: Maybe<string>;
	deviceSelected: Maybe<Device>;
	geofence: Maybe<Geofence>;
	mapBoundaries: leaflet.LatLngBounds;
	initialized: boolean;
	invalidateSizeKey: string; // When this key changes, the map calls "invalidateSize"
}

const init: Model = {
	locateFilter: Maybe.nothing<string>(),
	deviceSelected: Maybe.nothing<Device>(),
	geofence: Maybe.nothing<Geofence>(),
	mapBoundaries: new leaflet.LatLngBounds([[51.5, -0.12]]),
	initialized: false,
	invalidateSizeKey: generateInvalidateSizeKey(),
};

function recalculateSize(state: Model): Model {
	// Force "invalidateSize" by changing "invalidateSizeKey"
	return {
		...state,
		invalidateSizeKey: generateInvalidateSizeKey(),
	};
}

function generateInvalidateSizeKey(): string {
	return Math.random().toString(36).substring(2, 15); // Random string
}

// UPDATE

type Action =
	| {
			type: "CHANGE LOCATE FILTER";
			locateFilter: string;
			matchingDevices: Array<Device>;
	  }
	| {
			type: "SELECT DEVICE";
			config: Config;
			deviceSelected: Device;
	  }
	| {
			type: "SHOW GEOFENCE";
			config: Config;
			device: Device;
	  }
	| {
			type: "CHANGE MAP BOUNDS";
			newBounds: leaflet.LatLngBounds;
	  }
	| {
			type: "INIT";
			newBounds: leaflet.LatLngBounds;
	  };

function reducer(state: Model, action: Action): [Model, reduxLoop.CmdType] {
	switch (action.type) {
		case "CHANGE LOCATE FILTER": {
			const locateFilter =
				action.locateFilter === ""
					? Maybe.nothing<string>()
					: Maybe.just<string>(action.locateFilter);

			const newState: Model = {
				...state,
				locateFilter: locateFilter,
				deviceSelected: Maybe.nothing<Device>(),
			};

			const nextCmd = reduxLoop.Cmd.action<Action>({
				type: "CHANGE MAP BOUNDS",
				newBounds: toolkit
					.getDeviceListBounds(action.matchingDevices)
					.valueOr(state.mapBoundaries),
			});

			return [newState, nextCmd];
		}

		case "SELECT DEVICE": {
			const selectedDevice: Maybe<Device> = Maybe.just<Device>(
				action.deviceSelected
			);

			const newState: Model = {
				...state,
				deviceSelected: selectedDevice,
			};

			const nextCmd = reduxLoop.Cmd.action<Action>({
				type: "SHOW GEOFENCE",
				config: action.config,
				device: action.deviceSelected,
			});

			return [newState, nextCmd];
		}

		case "SHOW GEOFENCE": {
			const shouldShowGeofence: boolean = (function (): boolean {
				switch (action.device.kind) {
					case "Scissor": {
						return action.config.FEATURE_TOGGLES.GEOFENCE.SCISSORS;
					}
					case "Boat": {
						return action.config.FEATURE_TOGGLES.GEOFENCE.BOATS;
					}
					case "PrincessYacht": {
						return action.config.FEATURE_TOGGLES.GEOFENCE.PRINCESS_YACHTS;
					}
				}
			})();

			const changeMapBoundsCmd = reduxLoop.Cmd.action<Action>({
				type: "CHANGE MAP BOUNDS",
				newBounds: toolkit.getDeviceWithGeofenceBounds(action.device),
			});

			if (shouldShowGeofence) {
				const [deviceLat, deviceLong]: leaflet.LatLngTuple =
					action.device.CurrentPosition.value;
				const deviceCurrentPosition = new leaflet.LatLng(deviceLat, deviceLong);

				const outerRadius = action.device.geofence.outerRadius;
				const outerBounds: leaflet.LatLngBounds =
					deviceCurrentPosition.toBounds(outerRadius);
				const isInsideOuterBounds: boolean = outerBounds.contains(
					deviceCurrentPosition
				);

				const innerRadius = action.device.geofence.innerRadius;
				const innerBounds: leaflet.LatLngBounds =
					deviceCurrentPosition.toBounds(innerRadius);
				const isInsideInnerBounds: boolean = innerBounds.contains(
					deviceCurrentPosition
				);

				const color = isInsideInnerBounds
					? GeofenceColors.green
					: isInsideOuterBounds
					? GeofenceColors.amber
					: GeofenceColors.red;

				const geofence: Maybe<Geofence> = Maybe.just<Geofence>({
					bounds: outerBounds,
					color: color,
				});

				return [{ ...state, geofence }, changeMapBoundsCmd];
			} else {
				return [{ ...state, geofence: Maybe.nothing() }, changeMapBoundsCmd];
			}
		}

		case "CHANGE MAP BOUNDS": {
			const newState: Model = {
				...state,
				mapBoundaries: action.newBounds,
			};
			return [newState, reduxLoop.Cmd.none];
		}

		case "INIT": {
			const newState: Model = {
				...state,
				mapBoundaries: action.newBounds,
				initialized: true,
			};
			return [newState, reduxLoop.Cmd.none];
		}
	}
}

// VIEW

// Event Handlers

function onInit(
	dispatch: architecture.Dispatch<Action>,
	devices: Array<Device>
): void {
	dispatch({
		type: "INIT",
		newBounds: toolkit.getDeviceListBounds(devices).valueOr(init.mapBoundaries),
	});
}

function onLocateFilterChange(
	dispatch: architecture.Dispatch<Action>,
	devices: Array<Device>,
	searchText: string
): void {
	const devicesShown: Array<Device> = toolkit.filterDevices(
		searchText,
		devices
	);

	dispatch({
		type: "CHANGE LOCATE FILTER",
		locateFilter: searchText,
		matchingDevices: devicesShown,
	});
}

function onDeviceSelected(
	dispatch: architecture.Dispatch<Action>,
	config: Config,
	device: Device
): void {
	dispatch({ type: "SELECT DEVICE", config, deviceSelected: device });
}

function hasActiveAlarms(device: Device): boolean {
	switch (device.kind) {
		case "Scissor": {
			return scissorDomain.hasActiveAlarms(device);
		}
		case "Boat": {
			return boatDomain.hasActiveAlarms(device);
		}
		case "PrincessYacht": {
			return princessYachtDomain.hasActiveAlarms(device);
		}
	}
}

interface MapAccessProps {
	invalidateSizeKey: string;
}

const MapAccess = ({ invalidateSizeKey }: MapAccessProps): JSX.Element => {
	const map = reactLeaflet.useMap();
	React.useEffect(() => {
		map.invalidateSize();
	}, [invalidateSizeKey]);
	return <></>;
};

interface StateProps {
	actionButtonEnabled: boolean;
	state: Model;
	devices: Array<Device>;
	actionButtonIcon: JSX.Element;
	config: Config;
	initialization: "success" | "reloading";
	activePolling: boolean;
}

interface HandlerProps {
	dispatch: architecture.Dispatch<Action>;
	onShowDetails: (device: Device) => void;
	onActionButtonClick: () => void;
	refreshData: () => void;
	togglePolling: () => void;
}

type ViewProps = StateProps & HandlerProps;

function View({
	actionButtonEnabled,
	state,
	devices,
	actionButtonIcon,
	config,
	activePolling,
	dispatch,
	onShowDetails,
	onActionButtonClick,
	refreshData,
	togglePolling,
	initialization,
}: ViewProps): JSX.Element {
	React.useEffect(() => {
		if (!state.initialized) {
			onInit(dispatch, devices);
		}
	}, []);

	const locateFilter = state.locateFilter.valueOr("");
	const matchingDevices = toolkit.filterDevices(locateFilter, devices);
	return (
		<div id="fleet-view" style={styles.wrapper}>
			<div id="fleet-view__action-button">
				{actionButtonEnabled && (
					<Fab
						style={styles.actionButton}
						onClick={(): void => {
							onActionButtonClick();
						}}
						color="primary"
					>
						{actionButtonIcon}
					</Fab>
				)}
			</div>
			<div id="top-inputs" style={styles.topInputs}>
				<div id="fleet-view__searchbox">
					<Autocomplete
						options={[...devices]}
						renderInput={(params): JSX.Element => (
							<TextField {...params} placeholder="Find device" margin="dense" />
						)}
						getOptionSelected={(vehicle): boolean => {
							return devices.map((device) => device.id).includes(vehicle.id);
						}}
						getOptionLabel={(vehicle): string => vehicle.name}
						onChange={(_, value: Device | null): void => {
							if (value !== null) {
								onLocateFilterChange(dispatch, devices, value.name);
							} else {
								onLocateFilterChange(dispatch, devices, "");
							}
						}}
						style={styles.searchbox}
						openOnFocus={true}
						fullWidth={true}
					/>
				</div>
				<div id="fleet-view__pollingActions" style={styles.pollingActions}>
					<Button
						disabled={initialization === "reloading" ? true : false}
						onClick={refreshData}
						style={styles.refreshButton}
					>
						{initialization === "reloading" ? (
							<CircularProgress size={20} />
						) : (
							<RefreshIcon />
						)}
					</Button>
					<div id="fleet-view__polling-toggle">
						<FormControlLabel
							control={
								<Switch
									checked={activePolling}
									onChange={togglePolling}
									color="primary"
									size="small"
								/>
							}
							label="Auto"
							style={styles.pollingLabel}
						></FormControlLabel>
					</div>
				</div>
			</div>
			<reactLeaflet.MapContainer
				style={styles.map}
				minZoom={window.screen.availHeight < 800 ? 0 : 2}
				worldCopyJump={true}
				bounds={state.mapBoundaries}
				whenCreated={(map: leaflet.Map): void => {
					map.fitBounds(state.mapBoundaries);
				}}
			>
				<reactLeaflet.TileLayer
					url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
					attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
				/>
				<MapAccess invalidateSizeKey={state.invalidateSizeKey} />
				{state.geofence.caseOf({
					just: (geofence: Geofence): JSX.Element => {
						return (
							<reactLeaflet.Rectangle
								bounds={geofence.bounds}
								color={geofence.color}
								interactive={false}
							/>
						);
					},
					nothing: (): null => null,
				})}
				{matchingDevices.map((device: Device) => {
					const popupShouldOpen: boolean = state.deviceSelected.caseOf({
						just: (selectedDevice: Device): boolean => {
							return selectedDevice.name === device.name;
						},
						nothing: (): boolean => false,
					});
					const alarmsActive: boolean = hasActiveAlarms(device);
					const isDisabled =
						device.PowerStatus.value === null
							? true
							: !device.PowerStatus.value;
					return (
						<markerComponent.Marker
							key={device.id}
							device={device}
							isAlert={alarmsActive}
							isDisabled={isDisabled}
							popupShouldOpen={popupShouldOpen}
							onClicked={(): void => {
								onDeviceSelected(dispatch, config, device);
							}}
						>
							{((): JSX.Element => {
								switch (device.kind) {
									case "Scissor": {
										return (
											<popupComponent.Scissor
												scissor={device}
												onShowDetails={onShowDetails}
											/>
										);
									}
									case "Boat": {
										return (
											<popupComponent.Boat
												boat={device}
												onShowDetails={onShowDetails}
											/>
										);
									}
									case "PrincessYacht": {
										return (
											<popupComponent.PrincessYacht
												yacht={device}
												onShowDetails={onShowDetails}
											/>
										);
									}
								}
							})()}
						</markerComponent.Marker>
					);
				})}
			</reactLeaflet.MapContainer>
		</div>
	);
}
