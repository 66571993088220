/*
This toolkit comprises a series of helpers to assist on implementing the architecture
of this application.

This application follows what is known as the Elm-Architecture (or Model-View-Update).
For more information about this architecture, see the following links:

- https://staltz.com/unidirectional-user-interface-architectures.html#elm
- https://github.com/jarvisaoieong/redux-architecture
*/

export { assertExhaustiveSwitch, mapDispatch, Dispatch };

function assertExhaustiveSwitch<A>(
	_switchedCaseValue: never,
	valueToReturn: A
): A {
	return valueToReturn;
}

interface Dispatch<Action> {
	(action: Action): Action;
}

/* NOTE: The typings of redux stable version are broken, and we cannot use redux.Dispatch
They have rewritten all the types in version 4.0.0 but this version has not been released yet
Until it is not released, we must use our own Dispatch type */

// function mapDispatch<A extends redux.Action, B extends redux.Action>(
// 	dispatch: redux.Dispatch<B>,
// 	mapper: <C extends A>(action: C) => B
// ): <D extends A>(action: D) => D {
// 	return <D extends A>(action: D): D => dispatch<B>(mapper<A>(action)) && action;
//
// }

function mapDispatch<A, B>(
	dispatch: Dispatch<B>,
	tagger: (action: A) => B
): Dispatch<A> {
	return (action: A): A => {
		dispatch(tagger(action));
		return action;
	};
}
