import * as React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Typography, Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	root: {
		zIndex: 0,
	},
});

interface ErrorAlertProps {
	title: string;
	body: string;
}

export const ErrorAlert = ({ title, body }: ErrorAlertProps): JSX.Element => {
	const classes = useStyles();
	return (
		<Backdrop open classes={{ root: classes.root }}>
			<Alert severity="error" style={{ margin: "0px 20px 0px 20px" }}>
				<AlertTitle>{title}</AlertTitle>
				<Typography variant="body1">{body}</Typography>
			</Alert>
		</Backdrop>
	);
};
