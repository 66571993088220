// 3rd party
import * as React from "react";
import { ReactSVG } from "react-svg";
import * as reduxLoop from "redux-loop";
import { Maybe } from "tsmonad";

// Domain
import {
	Scissor,
	AlarmSensor,
	SensorValue,
	Actuator,
} from "@src/domain/scissor";
import * as scissorDomain from "@src/domain/scissor";
import { Config } from "@src/domain/config";

// Persistence
import * as scissorPersistence from "@src/persistence/scissor";
import { SessionToken } from "@src/persistence/session";

// Toolkits
import * as architecture from "@src/presentation/toolkit/architecture";
import * as cardinalDirections from "@src/presentation/toolkit/cardinal-directions";

import * as scissorSvgPath from "./assets/scissor_lift.svg";

export { View, Model, init, reducer, Action };

// MODEL

interface Model {
	powerActuatorAttempted: Maybe<boolean>;
}

const init: Model = {
	powerActuatorAttempted: Maybe.nothing(),
};

// UPDATE
type Action =
	| {
			type: "SEND ACTUATOR ATTEMPT";
			scissor: Scissor;
			actuator: Actuator;
			config: Config;
			session: SessionToken;
	  }
	| {
			type: "SEND ACTUATOR FAILURE";
	  }
	| {
			type: "SEND ACTUATOR SUCCESS";
	  };

function reducer(state: Model, action: Action): [Model, reduxLoop.CmdType] {
	switch (action.type) {
		case "SEND ACTUATOR ATTEMPT": {
			const cmd = reduxLoop.Cmd.run(sendActuator, {
				args: [action.scissor, action.actuator, action.config, action.session],
				failActionCreator: (): Action => {
					return {
						type: "SEND ACTUATOR FAILURE",
					};
				},
				successActionCreator: (): Action => {
					return {
						type: "SEND ACTUATOR SUCCESS",
					};
				},
			});
			return [
				{
					...state,
					powerActuatorAttempted: Maybe.just<boolean>(
						action.actuator.value === true
					),
				},
				cmd,
			];
		}

		case "SEND ACTUATOR FAILURE": {
			return [
				{
					...state,
					powerActuatorAttempted: Maybe.nothing<boolean>(),
				},
				reduxLoop.Cmd.none,
			];
		}

		case "SEND ACTUATOR SUCCESS": {
			return [
				{
					...state,
					powerActuatorAttempted: Maybe.nothing<boolean>(),
				},
				reduxLoop.Cmd.none,
			];
		}
	}
}

interface SynopticProps {
	style: React.CSSProperties;
	scissor: Scissor;
	dispatch: architecture.Dispatch<Action>;
	state: Model;
	config: Config;
	session: SessionToken;
}

function View({
	scissor,
	style,
	dispatch,
	state,
	config,
	session,
}: SynopticProps): JSX.Element {
	return (
		<div
			id="scissor-synoptic-view"
			onClick={(e: React.MouseEvent<HTMLDivElement>): void => {
				onSvgClick(dispatch, scissor, config, session, e);
			}}
		>
			<ReactSVG
				style={style}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				src={scissorSvgPath}
				// This prop is related to issue: https://githubmemory.com/repo/tanem/react-svg/issues/1024
				renumerateIRIElements={false}
				beforeInjection={(svg: SVGElement): void => {
					manipulateSvg(state, scissor, svg);
				}}
			/>
		</div>
	);
}

function renderValue(value: SensorValue, unit: string): string {
	return `${value === null ? "--" : value} ${unit}`;
}

function processAlarm(alarm: AlarmSensor, alarmElement: Element): void {
	alarmElement.setAttribute(
		"opacity",
		`${scissorDomain.isAlarmActive(alarm) ? "1" : "0"}`
	);
}

function manipulateSvg(state: Model, scissor: Scissor, svg: SVGElement): void {
	const {
		name,
		PowerStatus,
		Angle,
		WindSpeedAtTop,
		EngineTime,
		OilTemp,
		DistanceFromObstacle,
		WindSpeedForecast,
		AmbientTemp,
		TyrePressure,
		WindDirectionForecast,
		FuelLevel,
		StopTime,
		TotalFuelUsed,
		AlarmDistanceFromObstacle,
		AlarmAngle,
		AlarmOilTemp,
		AlarmEngineTime,
		AlarmFuelLevel,
		AlarmTyrePressure,
		AlarmWindSpeedAtTop,
		AlarmWindSpeedForecast,
	}: Scissor = scissor;

	processPowerStatus(state.powerActuatorAttempted, PowerStatus, svg);

	svg.querySelectorAll("#name")[0].textContent = name;

	svg.querySelectorAll("#AngleValue .sensorvalue")[0].textContent = renderValue(
		Angle.value,
		Angle.unit
	);

	svg.querySelectorAll("#WindSpeedAtTopValue .sensorvalue")[0].textContent =
		renderValue(WindSpeedAtTop.value, WindSpeedAtTop.unit);

	svg.querySelectorAll("#EngineTimeValue .sensorvalue")[0].textContent =
		renderValue(EngineTime.value, EngineTime.unit);

	svg.querySelectorAll("#OilTempValue .sensorvalue")[0].textContent =
		renderValue(OilTemp.value, OilTemp.unit);

	svg.querySelectorAll(
		"#DistanceFromObstacleValue .sensorvalue"
	)[0].textContent = renderValue(
		DistanceFromObstacle.value,
		DistanceFromObstacle.unit
	);

	svg.querySelectorAll(
		"#WindForecastValue .sensorvalue"
	)[0].textContent = `${renderValue(
		WindSpeedForecast.value,
		WindSpeedForecast.unit
	)}
		${
			WindDirectionForecast.value !== null
				? cardinalDirections.degrees2cardinalPoint(WindDirectionForecast.value)
				: ""
		}`;

	if (WindDirectionForecast.value !== null) {
		// if there's no value or value is 0 arrow will point to north
		const windForecastIconCircle = svg.querySelectorAll(
			"#WindForecastIconCircle"
		)[0];
		const cx = windForecastIconCircle.getAttribute("cx");
		const cy = windForecastIconCircle.getAttribute("cy");
		svg
			.querySelectorAll("#WindForecastIcon")[0]
			.setAttribute(
				"transform",
				`rotate(${WindDirectionForecast.value}, ${cx}, ${cy})`
			);

		const alarmWindForecastIconCircle = svg.querySelectorAll(
			"#AlarmWindForecastIconCircle"
		)[0];
		const alarmCx = alarmWindForecastIconCircle.getAttribute("cx");
		const alarmCy = alarmWindForecastIconCircle.getAttribute("cy");
		svg
			.querySelectorAll("#AlarmWindForecastIcon")[0]
			.setAttribute(
				"transform",
				`rotate(${WindDirectionForecast.value}, ${alarmCx}, ${alarmCy})`
			);
	}

	svg.querySelectorAll("#AmbientTempValue .sensorvalue")[0].textContent =
		renderValue(AmbientTemp.value, AmbientTemp.unit);

	svg.querySelectorAll("#TyrePressureValue .sensorvalue")[0].textContent =
		renderValue(TyrePressure.value, TyrePressure.unit);

	svg.querySelectorAll("#FuelLevelValue .sensorvalue")[0].textContent =
		renderValue(FuelLevel.value, FuelLevel.unit);

	svg.querySelectorAll("#StopTimeValue .sensorvalue")[0].textContent =
		renderValue(StopTime.value, StopTime.unit);

	svg.querySelectorAll("#TotalFuelUsedValue .sensorvalue")[0].textContent =
		renderValue(TotalFuelUsed.value, TotalFuelUsed.unit);

	processAlarm(
		AlarmDistanceFromObstacle,
		svg.querySelectorAll("#AlarmDistanceFromObstacle")[0]
	);

	processAlarm(AlarmAngle, svg.querySelectorAll("#AlarmAngle")[0]);

	processAlarm(AlarmOilTemp, svg.querySelectorAll("#AlarmOilTemp")[0]);

	processAlarm(AlarmEngineTime, svg.querySelectorAll("#AlarmEngineTime")[0]);

	processAlarm(AlarmFuelLevel, svg.querySelectorAll("#AlarmFuelLevel")[0]);

	processAlarm(
		AlarmTyrePressure,
		svg.querySelectorAll("#AlarmTyrePressure")[0]
	);

	processAlarm(
		AlarmWindSpeedAtTop,
		svg.querySelectorAll("#AlarmWindSpeedAtTop")[0]
	);

	processAlarm(
		AlarmWindSpeedForecast,
		svg.querySelectorAll("#AlarmWindForecast")[0]
	);
}

function processPowerStatus(
	powerActuatorAttempted: Maybe<boolean>,
	powerStatus: scissorDomain.PowerStatus,
	svg: SVGElement
): void {
	const PowerStatusValue =
		powerStatus.value !== null ? powerStatus.value : false;

	// updating power-switch slider
	const powerSlider = svg.querySelectorAll("#PowerSlider")[0];
	powerActuatorAttempted.caseOf({
		just: (attemptedValue: boolean): void => {
			setPowerSlider(attemptedValue, powerSlider);
		},
		nothing: (): void => {
			setPowerSlider(PowerStatusValue, powerSlider);
		},
	});
	// updating power-switch light
	const powerLight = svg.querySelectorAll("#PowerLight")[0];
	powerLight.setAttribute("opacity", `${PowerStatusValue ? "1" : "0"}`);
	// updating scissor enable-disable
	const scissorLift = svg.querySelectorAll("#scissorLift")[0];
	scissorLift.setAttribute("opacity", `${PowerStatusValue ? "1" : "0.4"}`);
}

function setPowerSlider(value: boolean, powerSlider: Element): void {
	const isSwitchAlreadyOn = powerSlider.classList.contains("power-on");
	const needsToogle = value !== isSwitchAlreadyOn;
	if (needsToogle) {
		powerSlider.classList.toggle("power-on");
	}
}

function onSvgClick(
	dispatch: architecture.Dispatch<Action>,
	scissor: Scissor,
	config: Config,
	session: SessionToken,
	e: React.MouseEvent<HTMLDivElement>
): void {
	if (!(e.target instanceof Node)) {
		return;
	}

	const powerSlider = document.querySelectorAll("#PowerSlider")[0];
	const isPowerSlider = powerSlider.contains(e.target);
	if (isPowerSlider) {
		// When a scissor has never been used PowerStatus value is null. In that case
		// we want newValue to be true. Otherwise, just the oposite of its current value
		const newValue =
			scissor.PowerStatus.value === null ? true : !scissor.PowerStatus.value;

		dispatch({
			type: "SEND ACTUATOR ATTEMPT",
			scissor: scissor,
			config,
			session,
			actuator: {
				...scissor.Power,
				value: newValue,
			},
		});
	}
}

function sendActuator(
	scissor: Scissor,
	actuator: Actuator,
	config: Config,
	session: SessionToken
): Promise<void> {
	const configUrl =
		config.DEVICE_MANAGEMENT.SCISSORS.REST_API.SEND_POWER_ACTUATOR;
	const url = configUrl.replace(/{DEVICE_ID}/, scissor.id);
	return scissorPersistence.sendActuator(url, session, actuator);
}
