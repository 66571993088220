import { Palette } from "@material-ui/core/styles/createPalette";

export { palette, GeofenceColors, custom };

const palette: Pick<Palette, "primary" | "secondary" | "warning"> = {
	primary: {
		dark: "hsl(210, 58%, 48%)",
		main: "hsl(210, 58%, 58%)",
		light: "hsl(210, 58%, 68%)",
		contrastText: "#fff",
	},
	secondary: {
		dark: "#FFFFFF",
		main: "#FFFFFF",
		light: "#FFFFFF",
		contrastText: "#fff",
	},
	warning: {
		dark: "hsl(18, 73%, 43%)",
		main: "hsl(18, 73%, 53%)",
		light: "hsl(18, 73%, 63%)",
		contrastText: "#fff",
	},
};

enum GeofenceColors {
	green = "#5FAD41",
	amber = "#E09F3E",
	red = "#9E2A2B",
}

const custom = {
	darkBlue: "#1565C0",
	lightGray: "#fff",
	alarmRed: "#ab2919",
	gray: "#616161",
	lightBlue: "#E3F2FD",
	alarmGray: "rgba(50, 50, 50, 0.5)",
};
