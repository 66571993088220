export { typography, fontFamily };

const fontFamily = "Exo";

const typography = {
	fontFamily: fontFamily,
	h1: {
		fontSize: "1.7rem",
		lineHeight: "2rem",
		fontFamily,
	},
	h2: {
		fontSize: "1.5rem",
		lineHeight: "2rem",
		fontFamily,
	},
	h3: {
		fontSize: "1.125rem",
		lineHeight: "1.6rem",
		fontFamily,
	},
	h4: {
		fontSize: "1.125rem",
		lineHeight: "1.6rem",
		fontFamily,
	},
	h5: {
		fontSize: "1.125rem",
		lineHeight: "1.6rem",
		fontFamily,
	},
	h6: {
		fontSize: "1.125rem",
		lineHeight: "1.6rem",
		fontFamily,
	},
	subtitle1: {
		fontSize: "1.2rem",
		lineHeight: "1.5rem",
		fontFamily,
	},
	subtitle2: {
		fontSize: "1.125rem",
		lineHeight: "1.6rem",
		fontFamily,
	},
	body1: {
		fontSize: "1rem",
		lineHeight: "1.5",
		fontFamily,
	},
	body2: {
		fontSize: "0.825rem",
		lineHeight: "1.5",
		fontFamily,
	},
};
