import axios from "axios";
import * as axiosTypes from "axios";
import { Either, Maybe } from "tsmonad";
import JsonousDecoder, * as jsonous from "jsonous";
import * as maybeasy from "maybeasy";
import * as miimetiqUI from "miimetiq-ui";

import * as decoding from "./decoding";

import * as errorDomain from "@src/domain/error";

import { Config } from "@src/domain/config";

export {
	AssetJSON,
	SessionToken,
	getAssets,
	sendRpc,
	decodeNonnullableReader,
	decodeNullableReader,
	decodeNullableWriter,
	lonlatDecoder,
	RestConfig,
	ensureValidSession,
	parseAxiosError,
};

interface AssetJSON {
	_id: string;
	_etag: string;
	name: string;
	raw: {};
}

interface ResourceResponse {
	_items: Array<{}>;
}

// This is an opaque type. More explanation will come
interface SessionToken {
	token: string;
}

function ensureValidSession(config: Config): Promise<SessionToken> {
	const miimetiqUIConfig: miimetiqUI.sessionManagement.Config = {
		loginUrl: config.IAM.LOGIN.URL,
		sessionInfoUrl: config.IAM.SESSION.INFO.URL,
		sessionCookieName: config.IAM.SESSION.COOKIE_NAME,
		userManagementUrl: config.USER_MANAGEMENT.URL,
	};
	return miimetiqUI.sessionManagement
		.ensureValidSession(miimetiqUIConfig)
		.then(
			(token: string): Promise<SessionToken> =>
				Promise.resolve({ token: token })
		);
}

interface RestConfig {
	includeFields: Array<string>;
}

interface Projection {
	[field: string]: 0 | 1;
}

interface RestParams {
	projection: Projection;
}

function safeProjectionFields(fields: Array<string>): Array<string> {
	return fields.indexOf("name") > -1 ? fields : [...fields, "name"];
}

function getRestParams(maybeRestConfig: Maybe<RestConfig>): RestParams | {} {
	return maybeRestConfig.caseOf({
		just: (restConfig: RestConfig): RestParams => ({
			projection: safeProjectionFields(restConfig.includeFields).reduce(
				(projectionSoFar: Projection, field: string): Projection => ({
					...projectionSoFar,
					[field]: 1,
				}),
				{}
			),
		}),
		nothing: (): {} => ({}),
	});
}

function getAssets(
	url: string,
	sessionToken: SessionToken,
	restConfig?: RestConfig
): Promise<Array<AssetJSON>> {
	const maybeRestConfig: Maybe<RestConfig> = restConfig
		? Maybe.just(restConfig)
		: Maybe.nothing();
	return axios
		.get(url, {
			headers: {
				"Access-Token": sessionToken.token,
				"Cache-Control": "no-cache",
			},
			params: getRestParams(maybeRestConfig),
		})
		.catch(parseAxiosError)
		.then(extractItemsFromResponse)
		.then(decodeAssets)
		.then(filterDecodeErrors);
}

interface UnavailableRpcError {
	_issues: string[];
}

const unavailableRpcErrorDecoder: JsonousDecoder<UnavailableRpcError> = jsonous
	.field("_issues", jsonous.array(jsonous.string))
	.andThen((errors) => jsonous.succeed({ _issues: errors }));

function decodeRpcError(
	axiosErrorResponseData: unknown
): Either<string, UnavailableRpcError> {
	return decoding.applyJsonousDecoder(
		unavailableRpcErrorDecoder,
		{
			failure: (error: string): string =>
				`Cannot decode axios error message: ${error}`,
			success: (result: UnavailableRpcError): UnavailableRpcError => result,
		},
		axiosErrorResponseData
	);
}

function sendRpc(
	url: string,
	sessionToken: SessionToken,
	payload: {}
): Promise<{}> {
	return axios
		.post(url, payload, {
			headers: {
				"Access-Token": sessionToken.token,
				"Cache-Control": "no-cache",
			},
		})
		.catch((error: axiosTypes.AxiosError<UnavailableRpcError>) => {
			const eitherDecodedError = decodeRpcError(error.response?.data);
			const message = eitherDecodedError.caseOf({
				right: (foundError) => {
					return foundError._issues.join(" - ");
				},
				left: (string) => {
					return error.message ? error.message : string;
				},
			});
			return Promise.reject(message);
		})
		.then((response: axiosTypes.AxiosResponse<{}>) => {
			return Promise.resolve(response.data);
		});
}

function extractItemsFromResponse(
	response: axiosTypes.AxiosResponse<ResourceResponse>
): Array<{}> {
	return response.data._items;
}

function decodeAssets(assets: Array<{}>): Array<Either<string, AssetJSON>> {
	return assets.map(decodeAsset);
}

function filterDecodeErrors(
	eitherAssetsOrError: Array<Either<string, AssetJSON>>
): Array<AssetJSON> {
	return eitherAssetsOrError
		.filter((eitherAssetOrError: Either<string, AssetJSON>) =>
			eitherAssetOrError.caseOf({
				right: (): boolean => true,
				left: (error: string): boolean => {
					console.log(`Ignoring asset. Reason: ${error}`);

					return false;
				},
			})
		)
		.map((eitherAssetOrError: Either<never, AssetJSON>): AssetJSON => {
			return eitherAssetOrError.caseOf({
				right: (asset: AssetJSON): AssetJSON => asset,

				left: (error: never): never => {
					throw new Error(`UNEXPECTED ERROR: ${error}`);
				},
			});
		});
}

function decodeAsset(asset: {}): Either<string, AssetJSON> {
	const assetDecoder: JsonousDecoder<AssetJSON> = jsonous
		.field("_id", jsonous.string)
		.andThen((_id: string) =>
			jsonous.field("_etag", jsonous.string).andThen((_etag: string) =>
				jsonous.field("name", jsonous.string).andThen((name: string) =>
					jsonous.succeed<AssetJSON>({
						_id,
						_etag,
						name,
						raw: asset,
					})
				)
			)
		);
	return decoding.applyJsonousDecoder(
		assetDecoder,
		{
			failure: (error: string): string => `Cannot decode asset: ${error}`,
			success: (result: AssetJSON): AssetJSON => result,
		},
		asset
	);
}

function decodeNullableReader<T>(
	valueDecoder: JsonousDecoder<T>,
	path: Array<string>
): JsonousDecoder<T | null> {
	return jsonous
		.at([...path, "value"], jsonous.nullable(valueDecoder))
		.andThen(
			(maybeValue: maybeasy.Maybe<T>): JsonousDecoder<T | null> =>
				jsonous.succeed<T | null>(
					maybeValue.cata({
						Just: (value: T): T => value,
						Nothing: (): null => null,
					})
				)
		)
		.orElse((err: string) =>
			jsonous.fail(`Cannot decode reader ${path}: ${err}`)
		);
}

const decodeNullableWriter = decodeNullableReader;

function decodeNonnullableReader<T>(
	valueDecoder: JsonousDecoder<T>,
	path: Array<string>
): JsonousDecoder<T> {
	return jsonous
		.at([...path, "value"], valueDecoder)
		.orElse((err: string) =>
			jsonous.fail(`Cannot decode reader ${path}: ${err}`)
		);
}

const lonlatDecoder = jsonous
	.field("0", jsonous.number)
	.andThen((lon: number) =>
		jsonous
			.field("1", jsonous.number)
			.andThen((lat: number) => jsonous.succeed<[number, number]>([lat, lon]))
	);

function parseAxiosError(
	error: axiosTypes.AxiosError
): Promise<axiosTypes.AxiosResponse> {
	const categorizedError = parseResourceError(error);
	return categorizedError.caseOf({
		right: () => Promise.reject("Undetermined error"),
		left: (e) => Promise.reject(e),
	});
}

function parseResourceError(
	axiosError: axiosTypes.AxiosError
): Either<errorDomain.MIIMETIQError, never> {
	if (axiosError.response === undefined) {
		const error: errorDomain.NetworkError = {
			type: "network-error",
			reason: axiosError.message,
		};
		return Either.left(error);
	}
	switch (axiosError.response.status) {
		case 500: {
			const msgDecoder: JsonousDecoder<string> = jsonous.at(
				["_error", "message"],
				jsonous.string
			);
			const errorMsg: string = msgDecoder
				.decodeAny(axiosError.response.data)
				.getOrElseValue(
					"Could not read any specific reason from the API. Probable reason of error: " +
						"the API has run into an exceptional error and could not respond properly"
				);
			const error: errorDomain.APIFailure = {
				type: "api-failure",
				reason: errorMsg,
				statusCode: 500,
			};
			return Either.left(error);
		}
		case 502: {
			const errorMsg: string =
				"The HTTP proxy server was not able to communicate with the API service. " +
				"This is probably a cache issue and most of the times it is fixed automatically after some minutes";
			const error: errorDomain.ProxyCacheInconsistency = {
				type: "proxy-cache-inconsistency",
				statusCode: 502,
				reason: errorMsg,
			};
			return Either.left(error);
		}
		case 504: {
			const errorMsg: string =
				"The requested service has not responded in time. " +
				"This may mean that the service is stopped or that it is taking too long to finish. " +
				"In any case, please notify your system administrator so that she can identify the " +
				"issue and correct any data inconsistency that might have resulted.";
			const error: errorDomain.GatewayTimeoutError = {
				type: "gateway-timeout",
				statusCode: 504,
				reason: errorMsg,
			};
			return Either.left(error);
		}
		case 400: {
			const msgDecoder: JsonousDecoder<string> = jsonous.at(
				["_error", "message"],
				jsonous.string
			);
			const errorMsg: string = msgDecoder
				.decodeAny(axiosError.response.data)
				.getOrElseValue(
					"Could not read any specific reason from the API. Probable reason of error: the request has been incorrect"
				);
			const error: errorDomain.IncorrectRequestError = {
				type: "incorrect-request-error",
				reason: errorMsg,
				statusCode: 400,
			};
			return Either.left(error);
		}
		case 401: {
			const msgDecoder: JsonousDecoder<string> = jsonous.field(
				"message",
				jsonous.string
			);
			const errorMsg: string = msgDecoder
				.decodeAny(axiosError.response.data)
				.getOrElseValue(
					"Could not read any specific reason from the API. Probable reason of error: missing authentication"
				);
			const error: errorDomain.AuthnError = {
				type: "authn-error",
				statusCode: 401,
				reason: errorMsg,
			};
			return Either.left(error);
		}
		case 402: {
			const msgDecoder: JsonousDecoder<string> = jsonous.field(
				"error",
				jsonous.string
			);
			const errorMsg: string = msgDecoder
				.decodeAny(axiosError.response.data)
				.getOrElseValue(
					"Could not read any specific reason from the API. Probable reason of error: License missing or expired"
				);
			const error: errorDomain.LicenseError = {
				type: "license-error",
				statusCode: 402,
				reason: errorMsg,
			};
			return Either.left(error);
		}
		case 403: {
			const msgDecoder: JsonousDecoder<string> = jsonous.at(
				["_error", "message"],
				jsonous.string
			);
			const errorMsg: string = msgDecoder
				.decodeAny(axiosError.response.data)
				.getOrElseValue(
					"Could not read any specific reason from the API. " +
						"Probable reason of error: not authorized to perform the requested action"
				);
			const error: errorDomain.AuthzError = {
				type: "authz-error",
				statusCode: 403,
				reason: errorMsg,
			};
			return Either.left(error);
		}
		case 404: {
			const msgDecoder: JsonousDecoder<string> = jsonous.at(
				["_error", "message"],
				jsonous.string
			);
			const errorMsg: string = msgDecoder
				.decodeAny(axiosError.response.data)
				.getOrElseValue(
					"Could not read any specific reason from the API. Probable reason of error: " +
						"resource does not exist in the API"
				);
			const error: errorDomain.ExistanceError = {
				type: "existance-error",
				reason: errorMsg,
				statusCode: 404,
				unexistingResource:
					axiosError.config.url !== undefined
						? axiosError.config.url
						: "unknown",
			};
			return Either.left(error);
		}
		case 412: {
			const errorMsg: string =
				"The instance has changed before the modify/delete operation was attempted. " +
				"Refresh to acknowledge the new data and try again";
			const error: errorDomain.ConcurrencyError = {
				type: "concurrency-error",
				statusCode: 412,
				reason: errorMsg,
			};
			return Either.left(error);
		}
		default: {
			const error: errorDomain.UnknownError = {
				type: "unknown-error",
				statusCode: axiosError.response.status,
				reason: axiosError.message,
			};
			return Either.left(error);
		}
	}
}
