// 3rd party
import * as React from "react";
import * as dayPicker from "react-day-picker";
import DayPicker from "react-day-picker";
import { Popover, Button, TextField, IconButton } from "@material-ui/core";
import { CalendarToday as CalendarTodayIcon } from "@material-ui/icons";

import { Maybe } from "tsmonad";

import "react-day-picker/lib/style.css";
import * as styles from "./styles";

export { View, TimeFilter };

interface TimeFilter {
	from: string;
	to: string;
}

interface StateProps {
	timeFilter: TimeFilter;
	fromErrorText?: string;
	toErrorText?: string;
	toggleButtonText: string;
	popoverTarget: Maybe<Element>;
	calendarFromOpened: boolean;
	calendarToOpened: boolean;
	disableApplyButton: boolean;
	rightContainerContent: JSX.Element;
}

interface HandlerProps {
	onOpen: (event: React.MouseEvent<HTMLElement>) => void;
	onClose: () => void;
	onNewTimeFilter: (timeFilter: TimeFilter) => void;
	onFromCalendarToggleClick: () => void;
	onToCalendarToggleClick: () => void;
	onApplyButtonClick: () => void;
	stringToDate: (stringDate: string) => Maybe<Date>;
	dateToString: (date: Date) => string;
}

type ViewProps = StateProps & HandlerProps;

function View({
	timeFilter,
	fromErrorText,
	toErrorText,
	toggleButtonText,
	popoverTarget,
	disableApplyButton,
	calendarFromOpened,
	calendarToOpened,
	rightContainerContent,
	onOpen,
	onClose,
	onNewTimeFilter,
	onFromCalendarToggleClick,
	onToCalendarToggleClick,
	onApplyButtonClick,
	stringToDate,
	dateToString,
}: ViewProps): JSX.Element {
	const today = new Date();
	const afterToday = { after: today };
	const disabledDaysFrom = stringToDate(timeFilter.to).caseOf({
		just: (date: Date): dayPicker.Modifier[] => [afterToday, { after: date }],
		nothing: (): dayPicker.Modifier[] => [afterToday],
	});
	const disabledDaysTo = stringToDate(timeFilter.from).caseOf({
		just: (date: Date): dayPicker.Modifier[] => [afterToday, { before: date }],
		nothing: (): dayPicker.Modifier[] => [afterToday],
	});
	const popover = popoverTarget.caseOf({
		just: (target: Element): JSX.Element => {
			return (
				<Popover
					open={true}
					anchorEl={target}
					anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
					onClose={(): void => {
						onClose();
					}}
					style={styles.popover}
				>
					<div
						style={
							window.screen.width < 768
								? styles.filterPopoverInnerMobile
								: styles.filterPopoverInner
						}
					>
						<div style={styles.calendarsWrapper}>
							<div style={styles.calendarForm}>
								<div>From</div>
								<div>
									<TextField
										style={styles.calendarInput}
										placeholder="yyyy-mm-dd hh:mm"
										value={timeFilter.from}
										onChange={(event): void => {
											onNewTimeFilter({
												from: event.target.value,
												to: timeFilter.to,
											});
										}}
										error={fromErrorText !== undefined}
										helperText={
											fromErrorText !== undefined ? fromErrorText : null
										}
									/>
									<IconButton
										style={styles.calendarButton}
										onClick={(): void => {
											onFromCalendarToggleClick();
										}}
									>
										<CalendarTodayIcon />
									</IconButton>
									<div style={calendarFromOpened ? {} : { display: "none" }}>
										{stringToDate(timeFilter.from).caseOf({
											just: (fromAsDate: Date): JSX.Element => (
												<DayPicker
													disabledDays={disabledDaysFrom}
													selectedDays={fromAsDate}
													month={fromAsDate}
													onDayClick={(
														newDate: Date,
														{ disabled }: dayPicker.DayModifiers
													): void => {
														if (disabled === undefined || disabled === false) {
															onNewTimeFilter({
																from: dateToString(newDate),
																to: timeFilter.to,
															});
														}
													}}
												/>
											),
											nothing: (): JSX.Element => (
												<DayPicker
													disabledDays={disabledDaysFrom}
													onDayClick={(
														newFromDate: Date,
														{ disabled }: dayPicker.DayModifiers
													): void => {
														if (disabled === undefined || disabled === false) {
															onNewTimeFilter({
																from: dateToString(newFromDate),
																to: timeFilter.to,
															});
														}
													}}
												/>
											),
										})}
									</div>
								</div>
							</div>
							<div style={styles.calendarForm}>
								<div>To</div>
								<div>
									<TextField
										style={styles.calendarInput}
										placeholder="yyyy-mm-dd hh:mm"
										value={timeFilter.to}
										onChange={(event): void => {
											onNewTimeFilter({
												to: event.target.value,
												from: timeFilter.from,
											});
										}}
										error={toErrorText !== undefined}
										helperText={toErrorText !== undefined ? toErrorText : null}
									/>
									<IconButton
										style={styles.calendarButton}
										onClick={(): void => {
											onToCalendarToggleClick();
										}}
									>
										<CalendarTodayIcon />
									</IconButton>
									<div style={calendarToOpened ? {} : { display: "none" }}>
										{stringToDate(timeFilter.to).caseOf({
											just: (toAsDate: Date): JSX.Element => (
												<DayPicker
													disabledDays={disabledDaysTo}
													selectedDays={toAsDate}
													month={toAsDate}
													onDayClick={(
														newDate: Date,
														{ disabled }: dayPicker.DayModifiers
													): void => {
														if (disabled === undefined || disabled === false) {
															onNewTimeFilter({
																to: dateToString(newDate),
																from: timeFilter.from,
															});
														}
													}}
												/>
											),
											nothing: (): JSX.Element => (
												<DayPicker
													disabledDays={disabledDaysTo}
													onDayClick={(
														newToDate: Date,
														{ disabled }: dayPicker.DayModifiers
													): void => {
														if (disabled === undefined || disabled === false) {
															onNewTimeFilter({
																to: dateToString(newToDate),
																from: timeFilter.from,
															});
														}
													}}
												/>
											),
										})}
									</div>
								</div>
							</div>
						</div>
						<Button
							style={{ marginLeft: 10 }}
							disabled={disableApplyButton}
							onClick={(): void => {
								onApplyButtonClick();
							}}
							variant="contained"
						>
							Apply
						</Button>
						<div>{rightContainerContent}</div>
					</div>
				</Popover>
			);
		},
		nothing: (): JSX.Element => <span />,
	});

	const wrapper: JSX.Element = (
		<div style={styles.timeFilterWrapper}>
			<Button
				style={styles.navigationButton}
				onClick={(event: React.MouseEvent<HTMLElement>): void => {
					onOpen(event);
				}}
			>
				{toggleButtonText}
			</Button>
			<div id="alarm-panel-view__time-filter-popover">{popover}</div>
		</div>
	);
	return wrapper;
}
