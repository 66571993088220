// 3rd party
import * as React from "react";
import { SvgLoader, SvgProxy } from "react-svgmt";
import * as reduxLoop from "redux-loop";
import { Maybe } from "tsmonad";

// Domain
import {
	PrincessYacht,
	Actuator,
	SensorValue,
} from "@src/domain/princess-yacht";
import * as princessYachtDomain from "@src/domain/princess-yacht";
import { Config } from "@src/domain/config";

// Persistence
import * as princessYachtPersistence from "@src/persistence/princess-yacht";
import { SessionToken } from "@src/persistence/session";

// Toolkit
import * as architecture from "@src/presentation/toolkit/architecture";

import * as princessYachtSvgPath from "./assets/princess-yacht.svg";

export { View, Model, init, reducer, Action };

// MODEL

interface Model {
	powerActuatorAttempted: Maybe<boolean>;
	lockActuatorAttempted: Maybe<boolean>;
}

const init: Model = {
	powerActuatorAttempted: Maybe.nothing(),
	lockActuatorAttempted: Maybe.nothing(),
};

// UPDATE
type Action =
	| {
			type: "SEND ACTUATOR ATTEMPT";
			yacht: PrincessYacht;
			actuator: Actuator;
			config: Config;
			session: SessionToken;
	  }
	| {
			type: "SEND ACTUATOR FAILURE";
			actuator: Actuator;
	  }
	| {
			type: "SEND ACTUATOR SUCCESS";
			actuator: Actuator;
	  };

function reducer(state: Model, action: Action): [Model, reduxLoop.CmdType] {
	switch (action.type) {
		case "SEND ACTUATOR ATTEMPT": {
			const cmd = reduxLoop.Cmd.run(sendActuator, {
				args: [action.yacht, action.actuator, action.config, action.session],
				failActionCreator: (): Action => {
					return {
						type: "SEND ACTUATOR FAILURE",
						actuator: action.actuator,
					};
				},
				successActionCreator: (): Action => {
					return {
						type: "SEND ACTUATOR SUCCESS",
						actuator: action.actuator,
					};
				},
			});
			const targetValue: boolean =
				action.actuator.value === null ? false : action.actuator.value;

			const newState =
				action.actuator.name === "Power"
					? {
							...state,
							powerActuatorAttempted: Maybe.just<boolean>(targetValue),
					  }
					: {
							...state,
							lockActuatorAttempted: Maybe.just<boolean>(targetValue),
					  };

			return [newState, cmd];
		}

		case "SEND ACTUATOR FAILURE": {
			const newState =
				action.actuator.name === "Power"
					? { ...state, powerActuatorAttempted: Maybe.nothing<boolean>() }
					: { ...state, lockActuatorAttempted: Maybe.nothing<boolean>() };

			return [newState, reduxLoop.Cmd.none];
		}

		case "SEND ACTUATOR SUCCESS": {
			const newState =
				action.actuator.name === "Power"
					? { ...state, powerActuatorAttempted: Maybe.nothing<boolean>() }
					: { ...state, lockActuatorAttempted: Maybe.nothing<boolean>() };

			return [newState, reduxLoop.Cmd.none];
		}
	}
}

interface PrincessYachtSynopticProps {
	yacht: PrincessYacht;
	dispatch: architecture.Dispatch<Action>;
	state: Model;
	config: Config;
	session: SessionToken;
}

const sliderOffStyles = "transition: all .2s ease-in-out .1s; cursor: pointer;";

const sliderOnStyles =
	"transition: all .2s ease-in-out .1s; cursor: pointer; transform: translateX(-28px);";

const outerSvgStyles: React.CSSProperties = {
	width: "100%",
	display: "block",
	margin: "auto",
	padding: 50,
	boxSizing: "border-box",
	height: "100%",
};

function renderValue(value: SensorValue, unit: string): string {
	return `${value === null ? "--" : value} ${unit}`;
}

function animate(alarmId: string): JSX.Element {
	return (
		<animate
			xlinkHref={alarmId}
			attributeName={"visibility"}
			values={"hidden;visible"}
			dur={"1.5s"}
			repeatCount={"indefinite"}
		/>
	);
}

function View({
	yacht,
	dispatch,
	config,
	session,
	state,
}: PrincessYachtSynopticProps): JSX.Element {
	const {
		name,
		PowerStatus,
		LockStatus,
		BatteriesDomestic,
		Shoreline1LowHighVoltage,
		Generator1LowHighVoltage,
		FreshWaterLevel,
		GreyWaterLevel,
		BlackWaterLevel,
		FuelLevel,
		OilPressure,
		EngineRPM,
		EngineRunningHours,
		AlarmExhaustHighTemp,
	}: PrincessYacht = yacht;
	const PowerStatusValue =
		PowerStatus.value !== null ? PowerStatus.value : false;
	const powerOn = state.powerActuatorAttempted.caseOf({
		just: (attemptedValue: boolean): boolean => attemptedValue,
		nothing: (): boolean => PowerStatusValue,
	});

	const LockStatusValue = LockStatus.value !== null ? LockStatus.value : false;
	const lockOn = state.lockActuatorAttempted.caseOf({
		just: (attemptedValue: boolean): boolean => attemptedValue,
		nothing: (): boolean => LockStatusValue,
	});

	return (
		<svg id="princess_yacht-synoptic-view" style={outerSvgStyles}>
			{animate("#AlarmExhaustHighTemp")}
			<filter id="disable">
				<feColorMatrix type="saturate" values="0" />
			</filter>
			{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
			<SvgLoader path={princessYachtSvgPath} width="100%" height="100%">
				<SvgProxy selector="#Name text">{name}</SvgProxy>
				<SvgProxy selector="#BatteriesDomesticValue text">
					{renderValue(BatteriesDomestic.value, BatteriesDomestic.unit)}
				</SvgProxy>
				<SvgProxy selector="#Shoreline1LowHighVoltageValue text">
					{renderValue(
						Shoreline1LowHighVoltage.value,
						Shoreline1LowHighVoltage.unit
					)}
				</SvgProxy>
				<SvgProxy selector="#Generator1LowHighVoltageValue text">
					{renderValue(
						Generator1LowHighVoltage.value,
						Generator1LowHighVoltage.unit
					)}
				</SvgProxy>
				<SvgProxy selector="#FreshWaterLevelValue text">
					{renderValue(FreshWaterLevel.value, FreshWaterLevel.unit)}
				</SvgProxy>
				<SvgProxy selector="#GreyWaterLevelValue text">
					{renderValue(GreyWaterLevel.value, GreyWaterLevel.unit)}
				</SvgProxy>
				<SvgProxy selector="#BlackWaterLevelValue text">
					{renderValue(BlackWaterLevel.value, BlackWaterLevel.unit)}
				</SvgProxy>
				<SvgProxy selector="#FuelLevelValue text">
					{renderValue(FuelLevel.value, FuelLevel.unit)}
				</SvgProxy>
				<SvgProxy selector="#OilPressureValue text">
					{renderValue(OilPressure.value, OilPressure.unit)}
				</SvgProxy>
				<SvgProxy selector="#EngineRPMValue text">
					{renderValue(EngineRPM.value, EngineRPM.unit)}
				</SvgProxy>
				<SvgProxy selector="#EngineRunningHoursValue text">
					{renderValue(EngineRunningHours.value, EngineRunningHours.unit)}
				</SvgProxy>
				{/* alarm blinks start here */}
				<SvgProxy
					selector="#AlarmExhaustHighTemp"
					opacity={
						princessYachtDomain.isAlarmActive(AlarmExhaustHighTemp) ? "1" : "0"
					}
				/>
				{/* power slider switch on-off */}
				<SvgProxy
					selector="#PowerSlider"
					style={powerOn ? sliderOnStyles : sliderOffStyles}
					onClick={onActuatorClick(config, session, dispatch, yacht, {
						...yacht.Power,
						value:
							yacht.PowerStatus.value === null
								? true
								: !yacht.PowerStatus.value,
					})}
				/>
				{/* updating power-switch light */}
				<SvgProxy
					selector="#PowerLight"
					opacity={PowerStatusValue ? "1" : "0"}
				/>
				{/* updating yacht saturation and opacity */}
				<SvgProxy
					selector="#princess-yacht"
					opacity={PowerStatusValue ? "1" : "0.4"}
				/>
				<SvgProxy
					selector="#princess-yacht"
					filter={PowerStatusValue ? "" : "url(#disable)"}
				/>

				{/* lock slider switch on-off */}
				<SvgProxy
					selector="#LockSlider"
					style={lockOn ? sliderOnStyles : sliderOffStyles}
					onClick={onActuatorClick(config, session, dispatch, yacht, {
						...yacht.Lock,
						value:
							yacht.LockStatus.value === null ? true : !yacht.LockStatus.value,
					})}
				/>
				{/* updating lock-switch light */}
				<SvgProxy selector="#LockLight" opacity={LockStatusValue ? "1" : "0"} />
			</SvgLoader>
		</svg>
	);
}

function onActuatorClick(
	config: Config,
	session: SessionToken,
	dispatch: architecture.Dispatch<Action>,
	yacht: PrincessYacht,
	actuator: Actuator
): () => void {
	return (): void => {
		dispatch({
			type: "SEND ACTUATOR ATTEMPT",
			yacht,
			config,
			session,
			actuator,
		});
	};
}

function sendActuator(
	yacht: PrincessYacht,
	actuator: Actuator,
	config: Config,
	session: SessionToken
): Promise<void> {
	const baseUrl = config.DEVICE_MANAGEMENT.PRINCESS_YACHTS.REST_API;
	const configUrl =
		actuator.name === "Power"
			? baseUrl.SEND_POWER_ACTUATOR
			: baseUrl.SEND_LOCK_ACTUATOR;
	const url = configUrl.replace(/{DEVICE_ID}/, yacht.id);
	return princessYachtPersistence.sendActuator(url, session, actuator);
}
