export { detailsView, appBar, container, scissorSynoptic };

const appBarHeight = 48;

const detailsView: React.CSSProperties = {
	height: "100%",
};

const appBar: React.CSSProperties = {
	position: "relative",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
};

const container: React.CSSProperties = {
	height: `calc(100% - ${appBarHeight}px)`,
	overflow: "hidden",
	position: "relative",
};

const scissorSynoptic: React.CSSProperties = {
	width: "100%",
	height: `calc(100vh - ${appBarHeight + 8}px)`,
	minHeight: 600,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
};
