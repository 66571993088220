import { Scissor, Actuator } from "../domain/scissor";
import { Either } from "tsmonad";
import * as jsonous from "jsonous";

import * as miimetiqRest from "./services/miimetiq-rest";
import * as decoding from "./services/decoding";

export { getScissors, sendActuator };

function getScissors(
	url: string,
	sessionToken: miimetiqRest.SessionToken
): Promise<Array<Scissor>> {
	return miimetiqRest
		.getAssets(url, sessionToken)
		.then(decodeScissors)
		.then(filterDecodeErrors);
}

function decodeScissors(
	assets: Array<miimetiqRest.AssetJSON>
): Array<Either<string, Scissor>> {
	return assets.map(decodeScissor);
}

function filterDecodeErrors(
	eitherScissorsOrErrors: Array<Either<string, Scissor>>
): Array<Scissor> {
	return eitherScissorsOrErrors
		.filter((eitherScissorOrError: Either<string, Scissor>) =>
			eitherScissorOrError.caseOf({
				right: (): boolean => true,
				left: (error: string): boolean => {
					console.log(`Ignoring Scissor. Reason: ${error}`);

					return false;
				},
			})
		)
		.map((eitherScissorOrError: Either<never, Scissor>): Scissor => {
			return eitherScissorOrError.caseOf({
				right: (asset: Scissor): Scissor => asset,

				left: (error: never): never => {
					throw new Error(`UNEXPECTED ERROR: ${error}`);
				},
			});
		});
}

// prettier-ignore
function decodeScissor(asset: miimetiqRest.AssetJSON): Either<string, Scissor> {
	// tslint:disable:max-line-length
	const decoder =
		jsonous.field("lon_lat", miimetiqRest.lonlatDecoder).andThen((geofence: [number, number]) =>
		miimetiqRest.decodeNullableReader(jsonous.boolean, [ 'ScissorLift', 'PowerStatus' ]).andThen((PowerStatus: boolean | null) =>
		miimetiqRest.decodeNullableWriter(jsonous.boolean, [ 'ScissorLift', 'Power' ]).andThen((Power: boolean | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'Angle' ]).andThen((Angle: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'WindSpeedAtTop' ]).andThen((WindSpeedAtTop: number | null) =>
		miimetiqRest.decodeNonnullableReader(miimetiqRest.lonlatDecoder, [ 'ScissorLift', 'CurrentPosition' ]).andThen((CurrentPosition: [number, number]) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'EngineTime' ]).andThen((EngineTime: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'OilTemp' ]).andThen((OilTemp: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'DistanceFromObstacle' ]).andThen((DistanceFromObstacle: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'WindSpeedForecast']).andThen((WindSpeedForecast: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'AmbientTemp'  ]).andThen((AmbientTemp: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'TyrePressure'  ]).andThen((TyrePressure: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'WindDirectionForecast' ]).andThen((WindDirectionForecast: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'FuelLevel' ]).andThen((FuelLevel: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'StopTime' ]).andThen((StopTime: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'TotalFuelUsed' ]).andThen((TotalFuelUsed: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'alarms', 'DistanceFromObstacle' ]).andThen((AlarmDistanceFromObstacle: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'alarms', 'Angle' ]).andThen((AlarmAngle: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'alarms', 'OilTemp' ]).andThen((AlarmOilTemp: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'alarms', 'WindSpeedAtTop' ]).andThen((AlarmWindSpeedAtTop: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'alarms', 'EngineTime' ]).andThen((AlarmEngineTime: number | null) =>
		miimetiqRest.decodeNullableReader(miimetiqRest.lonlatDecoder, [ 'ScissorLift', 'alarms', 'CurrentPosition' ]).andThen((AlarmCurrentPosition: [number, number] | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'alarms', 'WindSpeedForecast' ]).andThen((AlarmWindSpeedForecast: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'alarms', 'TyrePressure' ]).andThen((AlarmTyrePressure: number | null) =>
		miimetiqRest.decodeNullableReader(jsonous.number, [ 'ScissorLift', 'alarms', 'FuelLevel' ]).andThen((AlarmFuelLevel: number | null) =>
		jsonous.succeed<Scissor>({
			kind: "Scissor",
			id: asset._id,
			name: asset.name,
			geofence: {
				center: geofence,
				outerRadius: 10000,
				innerRadius: 8000
			},
			Power: {
				name: "Power",
				value: Power,
				trueLiteral: "ON",
				falseLiteral: "OFF",
			},
			PowerStatus: {
				name: "Power Status",
				value: PowerStatus,
			},
			Angle: {
				name: "Angle",
				value: Angle,
				unit: "º",
				icon: "Angle"
			},
			WindSpeedAtTop: {
				name: "Wind Speed At Top",
				value: WindSpeedAtTop,
				unit: "m/s",
				icon: "WindSpeedAtTop"
			},
			CurrentPosition: {
				name: "Current Position",
				value: CurrentPosition,
				icon: "CurrentPosition"
			},
			EngineTime: {
				name: "Engine Time",
				value: EngineTime,
				unit: "hour",
				icon: "EngineTime"
			},
			OilTemp: {
				name: "Oil Temp",
				value: OilTemp,
				unit: "ºC",
				icon: "OilTemp"
			},
			DistanceFromObstacle: {
				name: "Distance From Obstacle",
				value: DistanceFromObstacle,
				unit: "metres",
				icon: "DistanceFromObstacle"
			},
			WindSpeedForecast: {
				name: "Wind Speed Forecast",
				value: WindSpeedForecast,
				unit: "m/s",
				icon: "WindSpeedForecast"
			},
			AmbientTemp: {
				name: "Ambient Temp",
				value: AmbientTemp,
				unit: "ºC",
				icon: "AmbientTemp"
			},
			TyrePressure: {
				name: "Tyre Pressure",
				value: TyrePressure,
				unit: "psi",
				icon: "TyrePressure"
			},
			WindDirectionForecast: {
				name: "Wind Direction Forecast",
				value: WindDirectionForecast,
				icon: "WindDirectionForecast"
			},
			FuelLevel: {
				name: "Fuel Level",
				value: FuelLevel,
				unit: "%",
				icon: "FuelLevel"
			},
			StopTime: {
				name: "Stop Time",
				value: StopTime,
				unit: "hour",
				icon: "StopTime"
			},
			TotalFuelUsed: {
				name: "Total Fuel Used",
				value: TotalFuelUsed,
				unit: "L",
				icon: "TotalFuelUsed"
			},
			AlarmDistanceFromObstacle: {
				name: "AlarmDistanceFromObstacle",
				label: "Distance From Obstacle",
				unit: 'metres',
				value: AlarmDistanceFromObstacle,
			},
			AlarmAngle: {
				name: "AlarmAngle",
				label: "Angle",
				value: AlarmAngle,
				unit: "º"
			},
			AlarmOilTemp: {
				name: "AlarmOilTemp",
				label: "Oil Temp",
				value: AlarmOilTemp,
				unit: "ºC"
			},
			AlarmWindSpeedAtTop: {
				name: "AlarmWindSpeedAtTop",
				value: AlarmWindSpeedAtTop,
				label: "Wind Speed At Top",
				unit: "m/s"
			},
			AlarmEngineTime: {
				name: "AlarmEngineTime",
				value: AlarmEngineTime,
				unit: "hour",
				label: "Engine Time"
			},
			AlarmCurrentPosition: {
				name: "AlarmCurrentPosition",
				value: AlarmCurrentPosition,
				label: "Current Position"
			},
			AlarmWindSpeedForecast: {
				name: "AlarmWindSpeedForecast",
				label: "Wind Speed Forecast",
				value: AlarmWindSpeedForecast,
				unit: "m/s"
			},
			AlarmTyrePressure: {
				name: "AlarmTyrePressure",
				label: "Tyre Pressure",
				value: AlarmTyrePressure,
				unit: 'psi'
			},
			AlarmFuelLevel: {
				name: "AlarmFuelLevel",
				label: "Fuel Level",
				value: AlarmFuelLevel,
				unit: "%"
			}
		}))))))))))))))))))))))))));
	return decoding.applyJsonousDecoder(
		decoder,
		{
			success: (scissor: Scissor): Scissor => scissor,
			failure: (error: string): string => `Cannot decode scissor ${asset.name}: ${error}`
		},
		asset.raw
	);
}

function sendActuator(
	url: string,
	sessionToken: miimetiqRest.SessionToken,
	actuator: Actuator
): Promise<void> {
	return miimetiqRest
		.sendRpc(url, sessionToken, { value: actuator.value })
		.then((): Promise<void> => {
			return Promise.resolve();
		});
}
