// 3rd party
import * as leaflet from "leaflet";
import { Maybe } from "tsmonad";

// Domain
import { Scissor } from "@src/domain/scissor";
import { Boat } from "@src/domain/boat";
import { PrincessYacht } from "@src/domain/princess-yacht";

export {
	filterDevices,
	areBoundsEqual,
	getDeviceWithGeofenceBounds,
	getDeviceBounds,
	getDeviceListBounds,
};

type Device = Scissor | Boat | PrincessYacht;

function filterDevices(
	nameFilter: string,
	devices: Array<Device>
): Array<Device> {
	return devices.filter((device: Device): boolean =>
		device.name.toLowerCase().includes(nameFilter.toLowerCase())
	);
}

function getDeviceBounds(device: Device): leaflet.LatLngBounds {
	// returns the bounds corresponding to the device, applying a padding of 500m per side (1000/2)
	// and a vertical shift to try to position the popup under the search box
	const [lat, long]: [number, number] = device.CurrentPosition.value;
	const verticalShift =
		window.screen.availHeight < 800 ? (lat > 0 ? 1 + 6e-5 : 1 - 6e-5) : 1;
	return new leaflet.LatLng(lat * verticalShift, long).toBounds(1000);
}

function getDeviceWithGeofenceBounds(device: Device): leaflet.LatLngBounds {
	// To recalculate the zoom and position of the map we'll aggregate the
	// LatLngBounds of the device and its geofence and apply a pad to the group
	const outerRadius = device.geofence.outerRadius;
	const geofenceCenter = new leaflet.LatLng(
		device.geofence.center[0],
		device.geofence.center[1]
	);
	const geofenceBounds: leaflet.LatLngBounds =
		geofenceCenter.toBounds(outerRadius);
	const deviceBounds = getDeviceBounds(device);
	return mergeBoundsAndPad([deviceBounds, geofenceBounds]);
}

function getDeviceListBounds(
	devices: Array<Device>
): Maybe<leaflet.LatLngBounds> {
	if (devices.length > 0) {
		const boundsWithPadding = mergeBoundsAndPad(devices.map(getDeviceBounds));
		return Maybe.just<leaflet.LatLngBounds>(boundsWithPadding);
	} else {
		return Maybe.nothing<leaflet.LatLngBounds>();
	}
}

function mergeBoundsAndPad(
	bounds: Array<leaflet.LatLngBounds>
): leaflet.LatLngBounds {
	const mergedBounds = bounds.reduce(
		(
			accumulated: leaflet.LatLngBounds,
			next: leaflet.LatLngBounds
		): leaflet.LatLngBounds => accumulated.extend(next)
	);
	const boundsSize = mergedBounds
		.getNorthWest()
		.distanceTo(mergedBounds.getSouthEast());
	const minimumPad = 0.02;
	const padding = Math.max(6000 / boundsSize, minimumPad);
	return mergedBounds.pad(padding);
}

function areBoundsEqual(
	firstBounds: leaflet.LatLngBounds,
	secondBounds: leaflet.LatLngBounds,
	maxMargin?: number
): boolean {
	return (
		firstBounds.getSouthWest().equals(secondBounds.getSouthWest(), maxMargin) &&
		firstBounds.getNorthEast().equals(secondBounds.getNorthEast(), maxMargin)
	);
}
